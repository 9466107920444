

function setDocumentInfo(info){
    if(typeof info !== "object"){
        return;
    }
    let {title,description,keywords} = info;
    if(title){
        document.getElementsByTagName("title")[0].innerText = `${title} | 优阁 | UIGREAT`;
    }
    if(description){
        const oMeta = document.createElement('meta');
        oMeta.name = 'description';
        oMeta.content = description;
        let head =  document.getElementsByTagName('head')[0];
        let firstChild = head.firstChild;
        head.insertBefore(oMeta,firstChild);
    }
    if(keywords){
        const oMeta = document.createElement('meta');
        oMeta.name = 'keywords';
        oMeta.content = "像素范儿,像素,像素范," +  keywords ;
        let head =  document.getElementsByTagName('head')[0];
        let firstChild = head.firstChild;
        head.insertBefore(oMeta,firstChild);
    }

}


let utilFunc = {
setDocumentInfo
}
export  default  utilFunc