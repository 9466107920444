/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal } from 'antd'
import "./weiXinPayQRCodeModal.scss"

import courseReq from "../../request/courseReq";



class WeiXinPayQRCodeModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            ...props,
            show:true,
            payInfo:{

            }
        }
    }


    static  getDerivedStateFromProps(nextProps,prevState){

        return nextProps;
    }


    render() {
        let { show,payInfo} = this.state;
        payInfo  = payInfo || {};
        return (
                <Modal className={`comp-weixin-pay-qrcode-modal`} visible={show} centered footer={null} bodyStyle={{
                    background: "rgba(0,0,0,0)",
                    padding: 0
                }} style={{width:"80%",maxWidth:500}} onCancel={() => {
                   if(this.props.onCancel){
                       this.props.onCancel();
                   }
                }} maskClosable={false} destroyOnClose={true}>
                    <div className={'info-body'}>
                        <div className={`title`}>请打开微信扫一扫，扫码支付</div>
                        <div className={`image-box`}>
                            <img className={'qr-code'} hidden={!payInfo.codeUrl} src={`/api_v1/common/qr/image?string=${encodeURIComponent(payInfo.codeUrl)}`} alt={''}/>
                        </div>

                        <div className={'tip'}>请在15分钟内完成支付</div>

                    </div>

                </Modal>

        )
    }
}


export default WeiXinPayQRCodeModal;