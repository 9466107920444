import React from "react";
import Nav from "../../components/nav/Nav";
import "./shot.scss";
import {Avatar, Button, Icon, message, Affix, BackTop, Anchor, Modal, Input} from "antd";
import IconFont from "../../components/iconfont/icon-font";
import WorkComment from "../../components/work-comment/WorkComment";
import shotReq from "../../request/shotReq";
import {connect} from "react-redux";
import LoginRegisterModal from "../../layouts/login-register-modal/LoginRegisterModal";
import actions from "../../redux/actions";
import CommentTextarea from "../../components/comment-textarea/CommentTextarea";
import ListFooter from "../listFooter/ListFooter";
import {Link as RouterLink} from 'react-router-dom'
import ContentNoFound from "../../components/contentNoFound/ContentNoFound";
import courseReq from "../../request/courseReq";
import utilFunc from "../../utils/util";
import commentReq from "../../request/commentReq";

const {Link} = Anchor;
const {Search} = Input;
const loadingStatus = {
    init: "init",
    firstLoading: "first-loading",
    loadingMore: "loading-more",
    firstError: "first-error",
    moreError: "more-error"
};
const CommentPerPage = 20;

class Shot extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);

    }

    state = {
        loadingCommentListStatus: loadingStatus.init,
        comments: [],
        shotId: null,
        shot: {},
        noFound: false,
        commentLoadingStatus: "none", // 'loading','no-more','error'
        commentInputValue: null,
        submitCommentLoading: false,
        myCourses: [],
        myCoursesCount:0,
        selectCourseAtIndex:null,
        linkedCourses:[]
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            isLogin: nextProps.isLogin,
            loginUserInfo: nextProps.loginUserInfo
        }
    }

    /* life circle method */

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState(
                {
                    shotId: this.props.match.params.id
                },
                () => {
                    this.getDetail();

                }
            );
        } else {
            this.setState({
                noFound: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.isLogin && !prevProps.isLogin) {

            this.checkIfShotCollected();
            this.checkIfShotLiked()
            this.checkIfCommentLike(this.state.comments);
        }
    }


    getDetail = () => {
        shotReq
            .getShotDetail(this.state.shotId)
            .then(shot => {
                utilFunc.setDocumentInfo({
                    title:shot && shot.title ? shot.title:"优阁网作品详情",
                    keywords:shot && shot.tags ? shot.tags :null
                })
                this.setState({
                    shot
                }, () => {
                    this.checkIfShotLiked();
                    this.checkIfShotCollected();
                    this.getCommentList();
                })
            })
            .catch(error => {
                if (error && error.code === 7702) {
                    return this.setState({
                        noFound: true
                    })
                }
                message.error(`发生错误，请稍后刷新`);
            });
    };
    commentPage = 0;
    getCommentList = () => {

        if (this.state.loadingCommentListStatus === loadingStatus.firstLoading || this.state.loadingCommentListStatus === loadingStatus.loadingMore) {
            return;
        }

        if (this.commentPage < 1) {
            this.setState({
                loadingCommentListStatus: loadingStatus.firstLoading,
                comments: []
            });
        } else {

            this.setState({
                loadingCommentListStatus: loadingStatus.loadingMore
            });
        }
        shotReq
            .getComments(this.state.shotId, CommentPerPage, this.commentPage)
            .then(res => {
                let moreComments = res.rows;
                let count = res.count;
                let noMore = false;
                if (moreComments.length < CommentPerPage) {
                    noMore = true;
                }
                let comments = this.state.comments;
                if (this.commentPage < 1) {
                    comments = [];
                }
                this.checkIfCommentLike(moreComments);
                this.commentPage = this.commentPage + 1;
                this.setState({

                    comments: comments.concat(moreComments),
                    count,
                    noMoreComment: noMore,
                    loadingCommentListStatus: loadingStatus.init,
                });
            })
            .catch(error => {
                if (this.commentPage < 1) {
                    this.setState({
                        loadingCommentListStatus: loadingStatus.firstError
                    });
                } else {
                    this.setState({
                        loadingCommentListStatus: loadingStatus.moreError
                    });
                }
            });
    };

    checkIfCommentLike = targetComments => {
        if (this.state.isLogin) {
            if (!targetComments || !targetComments.length) {
                return;
            }
            let ids = [];
            if (targetComments && targetComments.length) {
                for (let pc of targetComments) {
                    ids.push(pc.id);
                    if (pc.replayTargetInfo && pc.replayTargetInfo.length) {
                        for (let cc of pc.replayTargetInfo) {
                            ids.push(cc.id);
                        }
                    }
                }
            }

            shotReq.checkIfLikeComments(ids).then(res => {
                let comments = this.state.comments;
                if (comments && comments.length) {
                    for (let pc of comments) {
                        if (res.hasOwnProperty(pc.id)) {
                            pc.isLike = res[pc.id];
                        }
                        if (pc.replayTargetInfo && pc.replayTargetInfo.length) {
                            let newArray = []
                            for (let cc of pc.replayTargetInfo) {
                                if (res.hasOwnProperty(cc.id)) {
                                    cc.isLike = res[cc.id];
                                }
                                newArray.push({...cc});
                            }
                            pc.replayTargetInfo = newArray
                        }
                    }
                    this.setState({
                        comments:[].concat(comments)
                    });
                }
            });
        }
    };

    childCommentDelete = (commentId, rowIndex, parentRowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments || [];
        let comment = comments[parentRowIndex] || {};
        let childComment = comment.replayTargetInfo
            ? comment.replayTargetInfo[rowIndex] || {}
            : {};
        if (
            childComment.id != commentId ||
            !childComment.userInfo ||
            !this.state.loginUserInfo ||
            childComment.userInfo.id != this.state.loginUserInfo.id
        ) {
            return;
        }
        shotReq
            .deleteComment(commentId)
            .then(res => {
                let comments = this.state.comments || [];
                let comment = comments[parentRowIndex] || {};
                comment.replayTargetInfo.splice(rowIndex, 1);
                let na = [].concat(comment.replayTargetInfo);
                comment.replayTargetInfo = na;
                let newComment = {...comment};
                comments[parentRowIndex] = newComment;
                this.setState({
                    comments:[].concat(comments)
                });
                message.success(`评论已删除`);
            })
            .catch(error => {
                message.error(`删除失败`);
            });
    };
    parentCommentDelete = (commentId, rowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments || [];

        let comment = comments[rowIndex] || {};
        if (
            comment.id != commentId ||
            !comment.userInfo ||
            !this.state.loginUserInfo ||
            comment.userInfo.id != this.state.loginUserInfo.id
        ) {
            return;
        }
        shotReq
            .deleteComment(commentId)
            .then(res => {
                let comments = this.state.comments || [];
                comments.splice(rowIndex, 1);
                this.setState({
                    comments:[].concat(comments)
                });
                message.success(`评论已删除`);
            })
            .catch(error => {
                message.error(`删除失败`);
            });
    };

    parentCommentLike = (commentId, rowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments;
        let comment = comments[rowIndex];
        if (comment.isLike) {
            shotReq.unlikeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[rowIndex];
                comment.isLike = false;
                comment.likeNum = parseInt(comment.likeNum)
                    ? parseInt(comment.likeNum) - 1
                    : 0;
                let newComment = {}
                Object.assign(newComment,comment);
                comments[rowIndex] = newComment
                this.setState({
                    comments:[].concat(comments)
                });
            });
        } else {
            shotReq.likeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[rowIndex];
                comment.isLike = true;
                comment.likeNum = parseInt(comment.likeNum) + 1;
                let newComment = {}
                Object.assign(newComment,comment);
                comments[rowIndex] = newComment
                this.setState({
                    comments:[].concat(comments)
                });
            });
        }
    };

    childCommentLike = (commentId, rowIndex, parentRowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments;
        let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
        if (comment.isLike) {
            shotReq.unlikeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
                comment.isLike = false;
                comment.likeNum = parseInt(comment.likeNum)
                    ? parseInt(comment.likeNum) - 1
                    : 0;
                let newComment = {}
                Object.assign(newComment,comment);
                comments[parentRowIndex].replayTargetInfo[rowIndex] = newComment
                comments[parentRowIndex].replayTargetInfo = [].concat(comments[parentRowIndex].replayTargetInfo)
                this.setState({
                    comments:[].concat(comments)
                });
            });
        } else {
            shotReq.likeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
                comment.isLike = true;
                comment.likeNum = parseInt(comment.likeNum) + 1;
                let newComment = {}
                Object.assign(newComment,comment);
                comments[parentRowIndex].replayTargetInfo[rowIndex] = newComment
                comments[parentRowIndex].replayTargetInfo = [].concat(comments[parentRowIndex].replayTargetInfo)
                this.setState({
                    comments:[].concat(comments)
                });

            });
        }
    };

    onPostReplay = (content, comment, key, target) => {
        shotReq
            .postComment({
                shotId: this.state.shot.id,
                content: `@${comment.userInfo.nickname ||
                comment.userInfo.username} ${content}`,
                replyTargetId: comment.rootTargetId ? comment.rootTargetId : comment.id
            })
            .then(res => {

                message.success(`已成功回复`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                let c = this.state.comments[key];
                c.replayTargetInfo = c.replayTargetInfo || [];
                c.replayTargetInfo = [res].concat(c.replayTargetInfo);
                target.clearContent();
                target.showCommentTextarea(false);
                this.setState({
                    comments
                });

            })
            .catch(error => {
                if(error && error.code === 7706){
                    return message.error(`此作品已关闭评论功能`)
                }
                message.error(`回复失败，请稍后重试`);
            });
    };

    onSelfPostReplay = (content, comment, key, target) => {
        shotReq
            .postComment({
                shotId: this.state.shot.id,
                content: `${content}`,
                replyTargetId: comment.rootTargetId ? comment.rootTargetId : comment.id
            })
            .then(res => {

                message.success(`已成功回复`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                let c = this.state.comments[key];
                c.replayTargetInfo = c.replayTargetInfo || [];
                c.replayTargetInfo = [res].concat(c.replayTargetInfo);
                target.clearContent();
                target.showCommentTextarea(false);
                this.setState({
                    comments
                });

            })
            .catch(error => {
                if(error && error.code === 7706){
                    return message.error(`此作品已关闭评论功能`)
                }
                message.error(`回复失败，请稍后重试`);
            });
    };

    pushComment = (value,target) => {
        shotReq
            .postComment({
                shotId: this.state.shotId,
                content: value
            })
            .then(res => {

                message.success(`评论发布成功`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                comments = [res].concat(comments);
                this.setState({
                    comments
                });
                target.clearContent();
            })
            .catch(error => {
                if(error && error.code === 7706){
                    return message.error(`此作品已关闭评论功能`)
                }
                message.error(`评论失败，请稍后重试`);
            });
    };

    checkIfShotCollected = () => {
        let shotId = this.state.shotId;
        if (this.state.isLogin) {
            shotReq
                .checkIfCollectShot(shotId)
                .then(res => {
                  
                    let shot = this.state.shot || {};
                    shot.hasCollected = !!res.hasCollected;
                    this.setState({
                        shot
                    });
                })
                .catch(error => {
                });
        }
    }

    handlerCollectShot = () => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        if (!this.state.shot) {
            return;
        }
        shotReq
            .handlerCollectShot(
                this.state.shot.id,
                !this.state.shot.hasCollected
            )
            .then(res => {
                let shot = this.state.shot || {};
                shot.hasCollected = !shot.hasCollected;
                this.setState({
                    shot
                });
                if (shot.hasCollected) {
                    message.success("收藏成功");
                } else {
                    // message.success("已取消收藏");
                }
            })
            .catch(error => {
                message.error("操作失败");
            });
    };

    checkIfShotLiked() {
        if (this.state.isLogin) {
            shotReq
                .checkIfLikeShot(this.state.shotId)
                .then(res => {

                    let shot = this.state.shot || {};
                    shot.hasLiked = !!res.hasLike;
                    this.setState({
                        shot
                    });
                })
                .catch(error => {
                });
        }
    }

    handlerLikeShot = () => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        if (!this.state.shot) {
            return;
        }
        shotReq
            .handlerLikeShot(
                this.state.shotId,
                !this.state.shot.hasLiked
            )
            .then(res => {
                let shot = this.state.shot || {};
                shot.hasLiked = !shot.hasLiked;
                this.setState({
                    shot
                });
                if (shot.hasLiked) {
                    message.success("已赞");
                } else {
                    message.success("已取消赞");
                }
            })
            .catch(error => {
                message.error("操作失败");
            });
    };

    // scrollTimer = null;
    // scrollToCommentArea = ()=>{
    //   document.documentElement.scrollTop = document.getElementById(`shot-detail-comment-list-top`).offsetTop;
    // }

    handlerRecommend = () => {
        if (this.state.shot) {
            let isRecommend = !this.state.shot.isRecommend
            shotReq.handlerRecommendShot(this.state.shot.id, isRecommend)
                .then(res => {
                    let shot = this.state.shot;
                    shot.isRecommend = isRecommend;
                    this.setState({
                        shot
                    })
                })
                .catch(error => {
                    message.error(`操作失败`)
                })
        }

    }
    showCourseModal = () => {
        this.setState({
            showCourseModal: true
        }, () => {
            this.getMyCourses()
        })
    }

    getMyCourses = ()=> {
        this.setState({
            myCourses:[],
            myCoursesCount: 0,
            linkedCourses:[]
        })
        courseReq.getMyCourseList(this.state.courseModalSearchKeyword,30, 0)
            .then(res => {
                shotReq.getShotLinkedCourse(this.state.shot.id)
                    .then( linkRes =>{

                        let linkedCourses = linkRes.rows || [];
                        let myCourses = res.rows || [];
                        for(let myC of myCourses){
                            for(let i = 0 ;i < linkedCourses.length;i++){
                                let lC = linkedCourses[i];
                                if(lC.id === myC.id){
                                    myC.checked = true;
                                }
                            }
                        }
                        this.setState({
                            myCourses: res.rows,
                            myCoursesCount: res.count,
                            linkedCourses:linkRes.rows
                        })
                    })
                    .catch(error =>{
                        this.setState({
                            myCourses: res.rows,
                            myCoursesCount: res.count,
                            linkedCourses:[]
                        })
                        message.error(`获取作品已关联的课程，发生错误`)
                    })


            })
            .catch(error => {
                message.error(`获取课程发生错误`)
            })
    }

    confirmAddShotToCourse = ()=>{
        this.setState({
            submittingAddToCourse:true
        })

        let {myCourses,linkedCourses} = this.state;
        let removeCourse = [];
        let addCourse = [];

        for(let myc of myCourses){
            if(myc.checked){
                let index = linkedCourses.findIndex(item => item.id === myc.id);
                if(index < 0){
                    addCourse.push(myc.id);
                }
            }else {
                let index = linkedCourses.findIndex(item => item.id === myc.id);
                if(index >= 0){
                    removeCourse.push(myc.id);
                }
            }
        }

        if(!removeCourse.length && !addCourse.length){
            this.setState({
                submittingAddToCourse:false
            })
            return message.warn(`没有任何修改`)
        }


        let session = [];
        if(removeCourse.length){
            session.push(new Promise(resolve => {
                courseReq.removeCoursesLinkedShot(this.state.shot.id,removeCourse)
                    .then(res =>{
                        message.success(`已将作品从${removeCourse.length}个课程中移除收录`)
                        resolve();
                    })
                    .catch(error =>{
                        message.error(`将作品从${removeCourse.length}个课程中移除收录时，发生错误`)
                        resolve();
                    })
            }))

        }
        if(addCourse.length){
            session.push(new Promise(resolve => {
                courseReq.linkShotToCourses(this.state.shot.id,addCourse)
                    .then(res =>{
                        message.success(`已将作品收录到${addCourse.length}个课程`)
                        resolve();
                    })
                    .catch(error =>{
                        message.error(`将作品收录到${addCourse.length}个课程时，发生错误`)
                        resolve();
                    })
            }))
        }

        this.setState({
            showCourseModal:false,
            myCourses:[],
            linkedCourses:[],
            myCoursesCount:0,
            submittingAddToCourse:false,
            courseModalSearchKeyword:null

        })
    }

    onCancelCourseModal = ()=>{
        this.setState({
            showCourseModal:false,
            myCourses:[],linkedCourses:[],
            myCoursesCount:0,
            courseModalSearchKeyword:null
        })
    }
    handlerTopComment = (comment,rowIndex)=>{

        commentReq.handlerTop(comment.id,!comment.isTop,"shot")
            .then(res =>{
                let comments = this.state.comments || [];
                let findIndex = comments.findIndex(item => item.id === comment.id);
                if(findIndex >= 0){
                    if(comment.isTop){
                        message.success('已取消置顶')
                    }else {
                        message.success('已置顶')
                    }
                    this.commentPage = 0;
                    this.setState({
                        loadingCommentListStatus:loadingStatus.init,
                        comments:[]
                    },()=>{
                        this.getCommentList();
                    })
                }

            })
            .catch(e =>{
                message.error('操作失败，请重试')
            })

    };
    render() {

        let {
            shot, noFound, loadingCommentListStatus,
            noMoreComment, comments, loginUserInfo, isLogin, showCourseModal,
            myCourses, myCoursesCount,
            submittingAddToCourse
        } = this.state;
        myCourses = myCourses || [];
        myCoursesCount = myCoursesCount || 0;
        if (noFound) {


            return <div className="shot-page">
                <BackTop/>
                <Nav/>
                <LoginRegisterModal/>
                <div className={"content"}>
                    <ContentNoFound text={`作品不存在或已被删除`}/>;
                </div>
            </div>
        }

        comments = comments || [];
        let commentList = comments.map((item, index) => {
            return (
                <WorkComment
                    data={item}
                    childComments={item.replayTargetInfo}
                    key={item.id}
                    compKey={item.id}
                    rowIndex={index}
                    onSelfPostReplay={this.onSelfPostReplay}
                    onPostReplay={this.onPostReplay}
                    openReplayInput={this.commentInputOnShow}
                    loginUserId={
                        loginUserInfo && loginUserInfo.id ? loginUserInfo.id : null
                    }
                    handlerDelete={this.parentCommentDelete}
                    handlerChildDelete={this.childCommentDelete}
                    handlerLike={this.parentCommentLike}
                    handlerChildLike={this.childCommentLike}
                    showTopComment={this.props.canTopComment}
                    handlerTopComment={this.handlerTopComment}

                />
            );
        });

        let sideBar = null;
        let courseListModal = null;
        if (isLogin && loginUserInfo) {

            let isAdminOrTeacher = loginUserInfo.roles && (loginUserInfo.roles.teacher || loginUserInfo.roles.admin)
            let isAdmin = loginUserInfo.roles && loginUserInfo.roles.admin;
            let isAuthor = shot && shot.user && loginUserInfo.id === shot.user.id;
            let recommendBtn = isAdminOrTeacher ?
                <div className="side-control-btn-outter">
                    <div className="side-control-btn" onClick={this.handlerRecommend}>
                        <IconFont className={`icon`} type={"icontuijian21"}
                                  style={{color: shot && shot.isRecommend ? "gold" : "#666666", fontSize: "24px"}}/>
                    </div>
                    <div className="side-control-text">推荐</div>
                </div> : null;
            let addToCourseBtn = isAdminOrTeacher ?
                <div className="side-control-btn-outter">
                    <div className="side-control-btn" onClick={this.showCourseModal}>
                    <Icon className={`icon`} type="pushpin" theme="filled"/>
                    </div>
                    <div className="side-control-text">收录</div>
                </div> : null;
            let editBtn = isAuthor || isAdmin ? <RouterLink to={`/post/shot/${shot.id}`}>
                <div className="side-control-btn-outter">
                    <div className="side-control-btn" hidden={``}>
                        <Icon className={`icon`} type="edit"/>
                    </div>
                    <div className="side-control-text">编辑</div>
                </div>
            </RouterLink> : null;
            courseListModal = <Modal
                footer={null}
                title={null}
                visible={showCourseModal}
                className={`course-list-modal`}
                centered
                onCancel={this.onCancelCourseModal}
            >
                <h2>收录到课程</h2>
                <div className={`modal-content`}>

                    <Search className={`search-input`} value={this.state.courseModalSearchKeyword}  size={`large`} placeholder={`搜索课程名称`} onChange={(e)=>{
                        this.setState({
                            courseModalSearchKeyword:e.target.value
                        })
                    }} onSearch={this.getMyCourses}/>
                    <div className={`result-count`}>共{myCoursesCount}个课程</div>

                    <div className={`list`}>

                        {
                            myCourses.map((item, index) => {
                                return <div className={`list-item`} key={index} onClick={()=>{
                                    let myCourses = this.state.myCourses;
                                    myCourses[index].checked = !myCourses[index].checked;
                                    this.setState({
                                        myCourses
                                    })
                                }}>
                                    <IconFont className={`checkbox`} type={`icontrue`} style={{color:  item.checked ? "#3340ff":"#dfe8f6"}}/>
                                    <img className={`preview`}
                                         src={item.preview}/>
                                    <div className={`flex-r`}>
                                        <div className={`title`}>{item.title || ""}</div>
                                        <div className={`teacher`}>
                                            {
                                                function () {
                                                    if(item.teacherInfo){
                                                        return  item.teacherInfo.map((teacher,idx)=>{
                                                            return <Avatar src={teacher.avatar || ""} key={idx}/>
                                                        })
                                                    }else {
                                                        return null;
                                                    }
                                                }()
                                            }
                                        </div>
                                    </div>
                                </div>
                            })
                        }


                    </div>



                    <Button className={`confirm-btn`} type={`primary`}  onClick={this.confirmAddShotToCourse} loading={submittingAddToCourse}>确认收录</Button>
                </div>
            </Modal>

            sideBar = (
                <Affix className={`side-affix`} offsetTop={100}>
                    <div className="side-control-panel">
                        {recommendBtn}
                        {editBtn}
                        {addToCourseBtn}
                    </div>
                </Affix>
            )
        }


        return (
            <div className="shot-page">
                <BackTop/>
                <Nav/>
                <LoginRegisterModal/>
                {courseListModal}
                <div className={"content"}>
                    {sideBar}
                    <div className={"shot-container"}>
                        <Affix>
                            <div className={"control-bar"}>
                                <Avatar
                                    className={"avatar"}
                                    src={shot && shot.user ? shot.user.avatar : ""}
                                />
                                <div className="flex">
                                    <div className={"flex-m"}>
                                        <h1 className={"title"}>{shot.title || ""}</h1>
                                        <div className={"flex-m-b"}>
                                            by{" "}
                                            <span>
                                                {shot && shot.user ? shot.user.nickname || "" : ""}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={"flex-r"}>
                                        <Anchor affix={false}>
                                            <Link href="#shot-detail-comment-list-top" title={
                                                <div className="control-btn">
                                                    <Icon
                                                        type={"message"}
                                                        style={{color: "#9395b0", fontSize: 18}}
                                                    />
                                                </div>
                                            }>

                                            </Link>
                                        </Anchor>
                                        <div className={"control-btn"}>
                                            <Icon
                                                type={"like"}
                                                style={{color: shot && shot.hasLiked ? "red" : "#9395b0", fontSize: 18}}
                                                onClick={this.handlerLikeShot}
                                            />
                                        </div>
                                        <div className={"control-btn"} onClick={this.handlerCollectShot}>
                                            <IconFont
                                                type={"iconshoucang"}
                                                className={"collection-icon"}
                                                style={{color: shot && shot.hasCollected ? "gold" : "gray"}}
                                            />
                                            <div style={{marginLeft: 4}}>收藏</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Affix>
                        <div className={`rich-text`}
                             dangerouslySetInnerHTML={{__html: shot && shot.content ? shot.content : ""}}/>
                        {/*<img className="shot" src={shot.content} />*/}
                    </div>
                    <div className={`comment-list`} id={'shot-detail-comment-list-top'}
                         hidden={!shot || !Object.getOwnPropertyNames(shot).length}>
                        <div
                            className={"bks-work-textarea"}
                            hidden={!this.state.isLogin || !shot || !shot.enableComment}
                        >
                            <Avatar
                                className={"avatar"}
                                src={
                                    loginUserInfo && loginUserInfo.avatar
                                        ? loginUserInfo.avatar
                                        : ""
                                }
                            />
                            <CommentTextarea
                                show={true}
                                onSubmit={this.pushComment}

                            />
                        </div>
                        <div className={`login-remind`} hidden={this.state.isLogin || !shot || !shot.enableComment}>
                            <Button
                                type={`primary`}
                                onClick={window.appLogin}
                            >
                                登录后写评论
                            </Button>
                        </div>
                        {commentList}
                        <ListFooter
                            show={[loadingStatus.loadingMore, loadingStatus.moreError, loadingStatus.init].indexOf(loadingCommentListStatus) >= 0 && comments.length}
                            status={noMoreComment ? 'no-more' : loadingCommentListStatus === loadingStatus.loadingMore ? 'loading-more' : "loading-error"}
                            onLoadingMore={this.getCommentList}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo,
    canTopComment:state.account.canTopComment
});

const mapDispatchToProps = {
    handlerShowLoginRegisterModal: actions.accountShowLoginRegisterModal
};


export default connect(mapStateToProps, mapDispatchToProps)(Shot);
