import React from 'react';
import Layout from "../../layouts/layout/Layout"
import './tool.scss'
import ToolList from '../../components/toolList/ToolList';
import utilFunc from "../../utils/util";


class Tool extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
    };

    /* life circle method */
    static getDerivedStateFromProps(nextProps, prevState) {

        return nextProps;
    }

    componentDidMount() {
        utilFunc.setDocumentInfo({
            title:"工具 | 优阁网",
            description:"优阁工具",
            keywords:`优阁工具`
        })
    }




    render() {



        return (
            <Layout rootClassName="tool">
                <ToolList />
            </Layout>
        )
    }
}

export default Tool;
