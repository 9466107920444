import React from 'react';

import {Row, Col} from 'antd'
import {Link} from "react-router-dom";
import ShotListCard from "../shotListCard/ShotListCard";
import SkeletonShotListCard from "../SkeletonShotListCard/SkeletonShotListCard";

class ShotList extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    render() {

        let {shots, showSkeleton, skeletonCount} = this.state
        shots = shots || [];
        let shotListCard = [];
        if (showSkeleton) {
            skeletonCount = skeletonCount || 12;
            for (let i = 0; i < skeletonCount; i++) {
                shotListCard.push(
                    <Col xs={12} sm={12} md={8} lg={8} xl={6} key={i}>
                        <SkeletonShotListCard/>
                    </Col>
                )
            }
        } else {
            shotListCard = shots.map((item, index) => {

                return <Col xs={12} sm={12} md={8} lg={8} xl={6} key={index}>
                    <Link title={item.title} to={`/shot/${item.id}`}>
                        <ShotListCard rowIndex={index} data={item} showFixed={this.state.showShotFixed}
                                      bgColor={this.state.colors ? this.state.colors[item.preview] : null}
                                      onCancelFixed={this.props.shotOnCancelFixed}
                                      handlerRecommend={this.props.handlerShotRecommend}
                                      showRecommend={this.state.showRecommend}/>
                    </Link>

                </Col>
            });
        }


        return (
            <Row gutter={[{xs: 8, sm: 16}, {xs: 8, sm: 16}]}>
                {shotListCard}
            </Row>
        )
    }
}


export default ShotList;