import React from "react";
import { Link } from 'react-router-dom'
import { Icon } from "antd";
import AudioIcon from "../../../components/audioIcon/AudioIcon"
import IconFont from '../../../components/iconfont/icon-font';

class PodcastAudioList extends React.Component {

    // constructor(props) {
    //     super(props);

    // }

    static getDerivedStateFromProps(nextProps, prevState) {


        return {

        };
    }


    state = {
        visible: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    }
    render() {
        let { audioList, currentPlayAudio, tag } = this.props;
        audioList = audioList || []

        return <div className={`podcast-audio-list-container`}>
            <div className={`tag-title`} hidden={!tag}>与 <div className={`highlight-tag`}>{tag || ""}</div>相关的播客</div>
            <ul className={"podcast-audio-list"}>
                {
                    audioList.map((item, index) => {
                        return <li className={"play-item-panel"} key={item.id}
                        >
                            <div className="sub-props">
                                <div className="sub-props-left">
                                    {
                                        item.tags ? item.tags.map((t, idx) => {
                                            return <Link className={`text ${tag === t ? 'highlight-tag' : ''}`} to={tag !== t ? `/podcast#${t}` : ""} key={idx}>#{t}</Link>
                                        }) : null
                                    }
                                    <div className='text'>{item.tags ? " // " : ''}{item.createdAtParsed}</div>
                                    <div className='text'>{" // " + item.playNum + "次播放"}</div>
                                    {item.onlineCount > 0 ? <div className='text'>{"//"} &nbsp; <AudioIcon />{(item.onlineCount || 0) + "人正在收听"}</div> : null}
                                </div>
                            </div>
                            <div className="play-item">
                                <div className="title-props">
                                    <img className="podcast-icon xs-hidden" src="/icons/voice-podcast.png" alt="" />
                                    <Link className="play-title" to={'/podcast/' + item.id}>{item.title}</Link>
                                    <div className="play-duration xs-hidden">{item.duration_fm}</div>
                                    <div className="play-btn" onClick={
                                        this.props.onClickPlay
                                    } data-index={index}>
                                        {
                                            currentPlayAudio && currentPlayAudio.id === item.id && !this.props.paused ? <Icon type="pause-circle" theme="filled" /> : <Icon type="play-circle" theme="filled" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <Link className='desc' to={'/podcast/' + item.id}>{item.description}</Link>
                            {/* <div className="podcast-mini" onClick={() => {
                                if (this.props.onShowMiniCode && typeof this.props.onShowMiniCode === 'function') {
                                    this.props.onShowMiniCode(item);
                                }
                            }}>
                                <IconFont className="icon" type="iconxiaochengxu" />
                                <div className="podcast-mini-text">用小程序收听</div>
                            </div> */}
                        </li>
                    })
                }
            </ul>

            {this.props.children}
        </div>
    }
}

export default PodcastAudioList