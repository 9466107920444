import React from 'react';

import {Row,Col} from 'antd'
import {Link} from "react-router-dom";
import CourseListCard from "../courseListCard/CourseListCard";

class CourseList extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    render() {
        let courses = this.state.courses || [];
        let courseCards = courses.map((item, index) => {

            return <Col xs={12} sm={12} md={8} lg={8} xl={6} key={index}>
                <Link title={item.title} to={`/course/${item.id}`}>
                    <CourseListCard key={item.id}  data={item} bgColor={this.state.colors ? this.state.colors[item.preview]:null}/>
                </Link>

            </Col>
        });
        return (
            <Row gutter={[{xs: 8, sm: 16}, {xs: 8, sm: 16}]}>
            {courseCards}
        </Row>
        )
    }
}


export default CourseList;