import React from 'react';
import "./teacherHome.scss";
import Nav from "../../components/nav/Nav";
import { Tabs, message, Pagination } from "antd";
import accountReq from "../../request/accountReq";
import actions from "../../redux/actions";
import { connect } from "react-redux";
import ListEmpty from "../../components/listEmpty/ListEmpty";
import OvalLoading from "../../components/OvalLoading/OvalLoading";
import courseReq from "../../request/courseReq";
import CourseList from "../../components/courseList/CourseList";
import AllCourseList from "../../components/allCourseList/AllCourseList";

class TeacherHome extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {
        teacherInfo: {},
        courseTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    componentDidMount() {
        this.getTeacherInfo();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.teacherUsername !== this.props.match.params.teacherUsername) {
            this.setState({
                courseTabData: {
                    rows: [],
                    page: 1,
                    loaded: false,
                    perPage: 12,
                    total: 0
                },
            })
            this.getTeacherInfo(1);

        }

        if (this.props.isLogin && !prevProps.isLogin) {
            this.checkIfFollowTeacher();
        }
    }

    checkIfFollowTeacher = () => {
        let teacherInfo = this.state.teacherInfo || {};
        if (this.state.isLogin && teacherInfo && teacherInfo.id) {
            accountReq.checkIfFollowUser(teacherInfo.id)
                .then(res => {
                    let teacherInfo = this.state.teacherInfo || {};
                    teacherInfo.followed = res[teacherInfo.id];
                    this.setState({
                        teacherInfo
                    })

                })
                .catch(error => {

                })
        }
    }

    handlerFollow = () => {
        let user = this.state.teacherInfo;
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        if (user.followed) {
            accountReq.cancelFollowUser(user.id)
                .then(res => {
                    let user = this.state.teacherInfo
                    user.followed = false;
                    this.setState({
                        teacherInfo: user
                    })
                })
        } else {
            accountReq.followUser(user.id)
                .then(res => {
                    let user = this.state.teacherInfo
                    user.followed = true;
                    this.setState({
                        teacherInfo: user
                    })
                    message.success("订阅成功")
                })
        }
    }
                            

    courseTabDataOnChangePage = (current) => {
        this.getTeacherAllCourses(current); 
    }

    getTeacherInfo() {
        accountReq.getUserPublicInfoWithUsername(this.props.match.params.teacherUsername)
            .then(res => {
                this.setState({
                    teacherInfo: res
                }, () => {
                    this.getTeacherAllCourses(1);
                    this.checkIfFollowTeacher();
                })
            })
            .catch(error => {
                message.error(JSON.stringify(error))
            })
    }

    getTeacherAllCourses = (page) => {
        let delayLoading = setTimeout(()=>{
            this.setState({
                loading:true
            })
        },120)
        courseReq.getCourseList({
            teacherId:this.state.teacherInfo.id,
            perPage:this.state.courseTabData.perPage,
            page:page - 1
        })
            .then(res => {
                if(delayLoading){
                    clearTimeout(delayLoading)
                }
                let rows = res.rows;
                let count = res.count;
                let courseTabData = this.state.courseTabData;
                courseTabData.total = count;
                courseTabData.rows = rows || [];
                courseTabData.loaded = true
                this.setState({
                    courseTabData,
                    loading:false
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }

    render() {
        let { courseTabData, teacherInfo ,loading} = this.state;
        teacherInfo = teacherInfo || {};
        return (
            <div className="teacher-home">
                <Nav />
                <div className="account-panel">
                    <div className="account-top">
                        <div className="account-info">

                            <div className="avatar">
                                <img src={teacherInfo.avatar} alt="" />
                            </div>
                            <div className="name-panel">
                                <div className="nickname"><label>{teacherInfo.nickname || teacherInfo.username}</label>
                                </div>
                                <div className="label">老师</div>
                            </div>
                        </div>
                        <div className="account-safe" onClick={this.handlerFollow}>
                            <div className="account-txt">{teacherInfo.followed ? "已订阅" : "订阅"}</div>
                        </div>
                    </div>
                    <div className="account-content">
                        <div className={`header`} />
                        <div className={'list'}>

                            {/*<OvalLoading show={!courseTabData.loaded} />*/}
                            <AllCourseList courses={courseTabData.rows} showSkeleton={loading} />
                            {/*<CourseList courses={courseTabData.rows} />*/}
                            <ListEmpty text={'暂无上线课程'}
                                show={courseTabData.loaded && !courseTabData.rows.length} />
                            <Pagination defaultCurrent={1} total={courseTabData.total}
                                size={courseTabData.perPage}
                                onChange={this.courseTabDataOnChangePage}
                                className={`tab-list-pagination`} hideOnSinglePage={true}
                                hidden={courseTabData.loaded && !courseTabData.rows.length} />

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        isLogin: state.account.isLogin,
    });
};
const mapDispatchToProps = {
    handlerShowLoginRegisterModal: actions.accountShowLoginRegisterModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherHome);
