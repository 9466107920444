import React from 'react';
import {Route, BrowserRouter, Switch, useLocation} from 'react-router-dom'
import accountRequest from './request/accountReq'
import Index from './pages/index/Index'
import Logout from './pages/logout/Logout'
import Shots from './pages/shots/Shots'
import Shot from './pages/shot/Shot'
import ShotPost from './pages/shotPost/ShotPost'
import "./app.scss"
import actions from './redux/actions'
import  {Alert} from 'antd'
import {connect} from 'react-redux'
import Course from "./pages/course/Course";
import Account from './pages/account/Account';
import AppCourse from "./pages/appCourse/AppCourse";
import TeacherHome from "./pages/teacherHome/TeacherHome";
import Footer from './components/footer/Footer';
import Tool from './pages/Tool/Tool';
import LiveCourseList from './pages/liveCourseList/LiveCourseList';
import AllCourseList from './pages/allCourseList/AllCourseList';
import config from "./config.json";
import {withCookies} from 'react-cookie'
import CoursePlan from "./pages/coursePlan/CoursePlan";
import storage from "./storage/storage";
import indexReq from "./request/indexReq";
import Podcast from "./pages/podcast/Podcast"
import HealthSign from "./pages/healthSign/HealthSign";
import Promise from './pages/promise/Promise'
const needLoginPagePaths = ["^/post.*$", "^/account.*$", "^/post/shot.*$"];

function usePageViews() {
    let location = useLocation();
    React.useEffect(() => {

        // eslint-disable-next-line no-undef
        if(window._czc){
            // eslint-disable-next-line no-undef
            // console.log(`czc`,location.pathname)
            window._czc.push(['_trackPageview', location.pathname, "/"])
        }

    }, [location]);
}

function RouteMap() {


    usePageViews();
    return <Switch>

        <Route path={"/promise"} component={Promise}/>
        <Route path={"/health-sign"} component={HealthSign}/>
        <Route path={"/tool"} component={Tool}/>
        <Route path={"/course-plan"} component={CoursePlan}/>
        <Route path={'/teacher/:teacherUsername'} component={TeacherHome}/>
        <Route path={'/app/:appId'} component={AppCourse}/>
        <Route path={'/course/:courseId'} component={Course}/>
        <Route path={'/account'} component={Account}/>
        <Route path={"/logout"} component={Logout}/>
        <Route path={'/post/shot/create'} component={ShotPost}/>
        <Route path={'/post/shot/:shotId'} component={ShotPost}/>
        <Route path={'/livecourse'} component={LiveCourseList}/>
        <Route path={'/course'} component={AllCourseList}/>
        <Route path={'/shots'} component={Shots}/>
        <Route path={'/shot/:id'} component={Shot}/>
        <Route path={'/podcast/:audioId'} component={Podcast}/>
        <Route path={'/podcast'}  component={Podcast}/>

        <Route path={'/'} component={Index}/>
    </Switch>
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            isLogin: nextProps.isLogin,
            loginUserInfo: nextProps.loginUserInfo,
            unreadMessagesData: nextProps.unreadMessagesData
        };
    }

    componentDidMount() {
        this.checkLogin();

        this.observeWindowOnResize();
        let that = this;
        window.appLogin = function () {

            if (config.environment === "development") {
                return that.props.handlerShowLoginRegisterModal("login");
            } else {
                that.props.cookies.set(`redirectUrl`, window.location.href, {path: "/"});
                window.location.href = config.loginUrl
            }
        }
        window.deviceInfo = this.initDeviceInfo();
         window.registerWeixinShare = this.registerWeixinShare
        this.getBulletin()
        this.getWeixinShareDebugStatus()
    }

    getBulletin(){
        indexReq.getLatestBulletin()
            .then(res =>{
                if(res){
                    let shownIds = storage.getStorage(storage.keys.handleClosedBulletinIds);
                    if(!shownIds || !shownIds.indexOf(res.id) < 0 ){
                        this.setState({
                            systemBulletin:res
                        })
                    }
                }
            })
    }


    getWeixinShareDebugStatus = ()=>{
        indexReq.getWeixinShareDebugStatus()
            .then(res =>{
                this.setState({
                    weixinShareDebug:  res && res.shareDebug ? res.shareDebug === 'true':false
                })
            })
    }

    getMyUnreadMessage() {

        let unreadMessagesData = this.state.unreadMessagesData;
        unreadMessagesData.latestRefreshDate = new Date().getTime();
        this.setState({
            unreadMessagesData
        })
        this.props.setUnreadMessagesData(unreadMessagesData)
        accountRequest.getMyMessages(1, unreadMessagesData.page + 1, unreadMessagesData.perPage)
            .then(res => {
                if(!res || !res.rows){
                    return
                }
                let rows = res.rows;
                for (let item of rows) {
                    if (item.body) {
                        item.body = JSON.parse(item.body);
                    }
                }
                let count = res.count;
                let unreadMessagesData = this.state.unreadMessagesData;
                unreadMessagesData.rows = rows;
                unreadMessagesData.total = count;
                unreadMessagesData.noMore = rows.length < unreadMessagesData.perPage
                this.setState({
                    unreadMessagesData
                })

                this.props.setUnreadMessagesData(unreadMessagesData)
            })
    }


    observeWindowOnResize() {
        const h = window.innerHeight;
        const w = window.innerWidth;
        this.props.recordWindowSize({
            width: w,
            height: h
        })
        window.onresize = () => {
            const h = window.innerHeight;
            const w = window.innerWidth;
            this.props.recordWindowSize({
                width: w,
                height: h
            })
        }
    }


    checkLogin() {
        accountRequest.getAuthUserInfo()
            .then((info) => {
                if(info && info.avatar){
                    info.avatar = info.avatar + "?imageView2/2/w/200"
                }
                this.props.addLoginUserInfo(info);
                this.props.flagLogin(true);
                // 定时获取用户的未读消息
                setInterval(() => {
                    this.getMyUnreadMessage()
                }, 60 * 3 * 1000);
                // 获取用户未读消息
                this.getMyUnreadMessage();
                this.canLoginUserTopComment();
                if (new RegExp("^/post/shot.*$").test(window.location.pathname) && (!info.userPower || !info.userPower.canPostShot)) {
                    window.location.href = '/';
                }

            })
            .catch((error) => {
                if (error.code === 1100) {
                    this.props.removeLoginUserInfo();
                    this.props.flagLogin(false);
                    for (let pathReg of needLoginPagePaths) {
                        if (new RegExp(pathReg).test(window.location.pathname)) {
                            window.location.href = '/';
                            break;
                        }
                    }
                }
            })
    }

    // 检查登录用户是否有权操作评论置顶
    canLoginUserTopComment(){
        accountRequest.canLoginUserTopComment()
            .then(res =>{
                if(this.props.flagCanTopComment){
                    this.props.flagCanTopComment(res && res.power)
                }
            })
    }

    initDeviceInfo() {
        let browser = {
            versions: (function () {
                let u = navigator.userAgent;
                return {
                    //移动终端浏览器版本信息
                    trident: u.indexOf("Trident") > -1, //IE内核
                    presto: u.indexOf("Presto") > -1, //opera内核
                    webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
                    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
                    iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
                    iPad: u.indexOf("iPad") > -1, //是否iPad
                    webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部
                };
            })(),
            language: (
                navigator.browserLanguage || navigator.language
            ).toLowerCase()
        };
        browser.isMobile = browser.versions.mobile;
        if (browser.versions.mobile) {
            browser.isMobile = true;
            //判断是否是移动设备打开。browser代码在下面
            let ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //在微信中打开
                browser.isTencent = true;
                // return false;
            }
            if (ua.match(/QQ/i) == "qq") {
                //在QQ空间打开
                browser.isTencent = true;
            }
            if (browser.versions.ios) {
                //是否在IOS浏览器打开
                browser.isIos = true;
            }
            if (browser.versions.android) {
                //是否在安卓浏览器打开
                browser.isAndroid = true;
            }
        } else {
            //否则就是PC浏览器打开
            browser.isPc = true;
        }
        return browser;
    }

    alertOnClose = ()=>{
        let shownIds = storage.getStorage(storage.keys.handleClosedBulletinIds) || []
        shownIds.push(this.state.systemBulletin.id);
        storage.setStorage(storage.keys.handleClosedBulletinIds,shownIds);
        this.setState({
            systemBulletin:null
        })
    }


    registerWeixinShare =  (options)=> {
        let url =  window.location.href
        // console.log(` registerWeixinShare options`,options)
        // eslint-disable-next-line no-use-before-define,no-undef
        if(!window.wx){
            console.log(`fail to loading WXSDK`)
            return
        }
        let that = this;
        indexReq.getWeixinShareSign(url)
            .then(data =>{
                if(this.state.weixinShareDebug){
                    console.log(`weixin-share-options`,options);
                    console.log(`wexin-share-sign`,data);
                }else {
                    console.log(`Can not debug weixin-share`)
                }

                window.wx.config({

                    debug: !!that.state.weixinShareDebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。

                    appId: data.appId , // 必填，公众号的唯一标识

                    timestamp: data.timestamp, // 必填，生成签名的时间戳

                    nonceStr: data.noncestr, // 必填，生成签名的随机串

                    signature: data.signature,// 必填，签名，见附录1

                    jsApiList: ["updateTimelineShareData","updateAppMessageShareData"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2

                });

                window.wx.ready(function () {
                    // 在这里调用 API
                    window.wx.updateTimelineShareData({  //例如分享到朋友圈的API
                        title: options.title || "", // 分享标题
                        link: url, // 分享链接
                        imgUrl: options.shareIcon || "", // 分享图标
                        success: function () {
                            // 用户确认分享后执行的回调函数
                        },
                        cancel: function () {
                            // 用户取消分享后执行的回调函数
                        }
                    });

                    window.wx.updateAppMessageShareData({
                        title: options.title || "", // 分享标题
                        desc: options.description || "", // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: options.shareIcon || "", // 分享图标
                        success: function () {
                            // 用户确认分享后执行的回调函数
                        },
                        cancel: function () {
                            // 用户取消分享后执行的回调函数
                        }
                    });
                });
            })
            .catch(error =>{
                if(this.state.weixinShareDebug){
                    console.log(`weixin-share-options`,options);
                    console.log(` weixin-share:error`,error);
                }else {
                    console.log(`Can not debug weixin-share:error`)
                }
            })
    }



    render() {
        let {systemBulletin} = this.state;
        let alert = null;
        if(systemBulletin && systemBulletin.description){
            alert =   <Alert
                message={systemBulletin.description}
                banner
                closable
                onClose={this.alertOnClose}
            />
        }
        return (
            <BrowserRouter basename={"/"}>
                <div>
                    {alert}
                </div>
                <RouteMap/>
                <Footer/>
            </BrowserRouter>

        );

    }

}


const mapDispatchToProps = {
    flagLogin: actions.accountFlagLoginStatus,
    addLoginUserInfo: actions.accountAddLoginUserInfo,
    removeLoginUserInfo: actions.accountRemoveLoginUserInfo,
    recordWindowSize: actions.systemSetWindowSize,
    handlerShowLoginRegisterModal: actions.accountShowLoginRegisterModal,
    setUnreadMessagesData: actions.accountSetUnreadMessagesData,
    flagCanTopComment:actions.accountCanHandlerTopComment,
}

const mapStateToProps = (state, ownProps) => ({
    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo,
    unreadMessagesData: state.account.unreadMessagesData,
    windowSize:state.system.windowSize
});


export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App));
