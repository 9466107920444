
import React from 'react'
import './coursePlan.scss'
import Layout from "../../layouts/layout/Layout";
import GanttChart from "../../components/GanttChart/GanttChart";
import courseReq from "../../request/courseReq";
import { message, Icon } from "antd";
import storage from "../../storage/storage";

const STORAGE_CACHED_KEY_COURSE_PLAN_PAGE = 'STORAGE_CACHED_KEY_COURSE_PLAN_PAGE'
class CoursePlan extends React.Component {
    constructor(props) {
        super(props);

    }
    state = {
        bjClassList: [], // 北京
        sjzClassList: [], // 石家庄
        szClassList: [], // 深圳
        jnClassList: [], // 济南


    }
    componentDidMount() {
        let cachedState = storage.getCached(STORAGE_CACHED_KEY_COURSE_PLAN_PAGE);
        if (cachedState) {
            this.setState({
                ...cachedState
            })
        } else {
            // 北京
            this.getCoursePlan(1)
            // 石家庄
            this.getCoursePlan(2)
            // 深圳
            this.getCoursePlan(3)
            // 济南
            this.getCoursePlan(6)
        }


    }

    getCoursePlan(schoolId) {
        courseReq.getCoursePlan(schoolId)
            .then(res => {
                for (let cls of res || []) {
                    cls.name = cls.classname || ""
                    cls.startAt = cls.starttime;
                    cls.endAt = cls.endtime
                    cls.school_id = schoolId;
                }
                if (schoolId === 1) {

                    this.setState({
                        bjClassList: res
                    })
                } else if (schoolId === 2) {
                    this.setState({
                        sjzClassList:  res
                    })
                } else if (schoolId === 3) {
                    this.setState({
                        szClassList:  res
                    })
                } else if (schoolId === 6) {
                    this.setState({
                        jnClassList:  res
                    })
                }

            })
            .catch(error => {
                message.error(`发生错误，请稍后重试`)
            })

    }

    componentWillUnmount() {
        storage.setCached(STORAGE_CACHED_KEY_COURSE_PLAN_PAGE, this.state)
    }

    render() {
        let { bjClassList, sjzClassList, szClassList, jnClassList } = this.state;
        return (
            <Layout rootClassName="course-plan-panel">
                <div className={`course-plan`} hidden={!bjClassList || !bjClassList.length}>
                    <GanttChart title={"北京线下课排期"} rows={bjClassList} />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <a href="http://design.uigreat.com/" target="_blank" className="apply-link" style={{ color: "rgb(107, 60, 240)" }}>
                            运营班 <Icon type="swap-right" />
                        </a>
                        <a href="http://pxfer.uigreat.com/" target="_blank" className="apply-link">
                            UI班 <Icon type="swap-right" />
                        </a>
                    </div>
                </div>
                <div className={`course-plan`} hidden={!sjzClassList || !sjzClassList.length}><GanttChart title={"石家庄线下课排期"} rows={sjzClassList} />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <a href="https://www.pxfer.com/" target="_blank" className="apply-link">
                            UI班 <Icon type="swap-right" />
                        </a>
                    </div>
                </div>
                <div className={`course-plan`} hidden={!szClassList || !szClassList.length}><GanttChart title={"深圳线下课排期"} rows={szClassList} />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <a href="http://sz.uigreat.com/" target="_blank" className="apply-link">
                            UI班 <Icon type="swap-right" />
                        </a>
                    </div>
                </div>
                <div className={`course-plan`} hidden={!jnClassList || !jnClassList.length}><GanttChart title={"济南线下课排期"} rows={jnClassList} />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <a href="http://jn.uigreat.com/" target="_blank" className="apply-link">
                            UI班 <Icon type="swap-right" />
                        </a></div>
                </div>
            </Layout>)
    }

}

export default CoursePlan