import React from 'react'
import './podcast.scss'
import Layout from "../../layouts/layout/Layout";
import { Icon, Slider, Avatar, Row, Col, Pagination, Modal } from "antd";

import audioReq from "../../request/audioReq";
import dateformat from 'dateformat'
import IconFont from '../../components/iconfont/icon-font';
import { Link } from "react-router-dom";
import AudioList from "./AudioList/AudioList"
import AudioDetail from "./AudioDetail/AudioDetail";
import { connect } from "react-redux";

const PerPage = 15

class Podcast extends React.Component {

    constructor(props) {
        super(props);
        this.audioPlayerRef = React.createRef()

    }

    state = {
        detail: {},
        currentPlayAudio: null,
        currentPlayDuration: 0,
        paused: false,//play,pause
        showDetail: false,
        currentPathName: "",
        searchTag: null,
        listPage: 0,
        audioList: [],
        noMore: false,
        listCount: 0
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            windowSize: nextProps.windowSize
        }
    }


    checkPathChecked(match) {

    };


    componentDidMount() {

        this.getSeriesDetail();
        this.refreshAudioOnlineCounts();

    }

    firstDidLoad = false

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (!this.firstDidLoad || prevProps.match.url !== this.props.match.url || prevProps.location.hash !== this.props.location.hash) {

            if ((this.props.match.path === '/podcast' || this.props.match.path === '/podcast/') && (!this.firstDidLoad || prevProps.location.hash !== this.props.location.hash || prevProps.match.url !== this.props.match.url)) {
                this.firstDidLoad = true
                this.setState({
                    showDetail: false,
                    audioId: null,
                    searchTag: this.props.location.hash ? decodeURIComponent(this.props.location.hash.replace("#", "")) : null,
                    audioList: [],
                    listPage: 0,
                    noMore: false
                }, () => {


                    if (this.state.searchTag) {
                        this.searchAudioWithTag();
                    } else {
                        this.getAudioList()
                    }
                    if (this.state.detail && window.registerWeixinShare) {

                        window.registerWeixinShare({
                            title: this.state.detail.title,
                            description: this.state.detail.description,
                            shareIcon: this.state.detail ? this.state.detail.preview ? this.state.detail.preview.indexOf('//') === 0 ? `https:` +  this.state.detail.preview :this.state.detail.preview:"":"",
                        })
                    }
                })
            }
            if (this.props.match.path === '/podcast/:audioId') {
                this.firstDidLoad = true
                this.setState({
                    showDetail: true,
                    audioId: this.props.match.params.audioId,
                })
            }
        }
    }

    refreshAudioOnlineCounts = () => {

        this.refreshOnlineTimer = setInterval(() => {
            let { audioList } = this.state;
            audioList = audioList || [];
            let ids = audioList.map(item => item.id);
            if (!ids.length) {
                return
            }
            audioReq.queryAudioGroupOnlineCount(ids)
                .then(res => {
                    let audioList = this.state.audioList || [];
                    for (let audio of audioList) {
                        audio.onlineCount = res[audio.id] || res[audio.id] === 0 ? res[audio.id] : audio.onlineCount

                    }
                    this.setState({
                        audioList
                    })

                })
        }, 15000)

    };

    componentWillUnmount() {
        if (this.refreshOnlineTimer) {
            clearInterval(this.refreshOnlineTimer);
        }
    }


    getSeriesDetail = () => {

        audioReq.getAudioSeriesDetail(1)
            .then(res => {


                if (res.tags) {
                    res.tags = res.tags.split(",");
                }

                if (res.otherSources) {
                    try {
                        res.otherSources = JSON.parse(res.otherSources);
                    } catch (e) {
                        res.otherSources = null;
                    }


                }
                this.setState({
                    detail: res,
                }, () => {

                })
            })
    }

    searchAudioWithTag() {
        let { searchTag, listPage } = this.state;
        if (this.state.searchTag && !this.state.noMore) {
            if (listPage === 0) {
                this.setState({
                    audioList: [],
                })
            }

            audioReq.searchAudioWithTag(searchTag, listPage, PerPage)
                .then(res => {

                    let rows = res && res.rows ? res.rows : []
                    if (rows && rows.length) {
                        rows.forEach(item => {
                            item = this.formatAudio(item);
                        })
                    }

                    this.setState({
                        audioList: rows,
                        listCount: res && res.count ? res.count : 0
                    }, () => {
                        if (this.state.listPage === 0) {
                            window.scrollTo(0, 0);
                        }
                        if (this.state.detail && window.registerWeixinShare) {

                            window.registerWeixinShare({
                                title: this.state.detail.title,
                                description: this.state.detail.description,
                                shareIcon: this.state.detail ? this.state.detail.preview ? this.state.detail.preview.indexOf('//') === 0 ? `https:` +  this.state.detail.preview :this.state.detail.preview:"":"",
                            })
                        }
                    })
                })
                .catch(error => {

                })
        }
    }

    getAudioList() {
        let { searchTag, listPage } = this.state;
        if (!searchTag) {
            if (listPage === 0) {
                this.setState({
                    audioList: [],
                })
            }
            audioReq.getAudioListOfSeriesId(1, PerPage, listPage)
                .then(res => {
                    let rows = res.rows || []
                    if (rows && rows.length) {
                        rows.forEach(item => {
                            item = this.formatAudio(item);
                        })
                    }
                    this.setState({
                        audioList: rows,
                        listCount: res && res.count ? res.count : 0
                    }, () => {
                        if (this.state.listPage === 0) {
                            window.scrollTo(0, 0);
                        }
                        if (this.state.detail && window.registerWeixinShare) {

                            window.registerWeixinShare({
                                title: this.state.detail.title,
                                description: this.state.detail.description,
                                shareIcon: this.state.detail ? this.state.detail.preview ? this.state.detail.preview.indexOf('//') === 0 ? `https:` +  this.state.detail.preview :this.state.detail.preview:"":"",
                            })
                        }

                    })
                })
                .catch(error => {

                })
        }

    }

    formatAudio(audio) {
        if (audio && audio.duration) {
            audio.duration_fm = this.formatDuration(audio.duration);
        }

        if (audio && audio.tags && typeof audio.tags === "string") {
            audio.tags = audio.tags.split(',')
        }
        if (audio && audio.createdAt) {
            audio.createdAtParsed = dateformat(new Date(audio.createdAt), 'yyyy-mm-dd')
        }
        return audio
    }

    formatDuration(duration) {
        if (!duration) {
            return "00:00";
        }
        if (duration < 10) {
            return `00:0${duration}`
        } else if (duration < 60) {
            return `00:${duration}`
        } else if (duration / 60 < 10) {
            let second = duration % 60;
            let minute = parseInt(duration / 60);
            return `0${minute}:${second < 10 ? "0" + second : second}`
        } else {
            let second = duration % 60;
            let minute = parseInt(duration / 60);
            return `${minute}:${second < 10 ? "0" + second : second}`
        }
    }

    playAudio = (e) => {
        let index = e.currentTarget.dataset.index;
        let audio = this.state.audioList[index];


        let player = this.audioPlayerRef.current;
        if (player) {

            if (this.state.currentPlayAudio && this.state.currentPlayAudio.id === audio.id) {

                this.playOrPause()
            } else {
                audio.rowIndex = index;
                this.setState({
                    currentPlayAudio: audio,
                    currentPlayDuration: 0,
                }, () => {
                    this.getOnline()
                })
                player.pause()
                player.src = audio.sourceUrl;
                player.play();
                audioReq.incrementPlayNum(audio.id)
                    .then().catch()
                this.resumePlayTimer()


            }


        }
    }

    stopPlayer = () => {
        let player = this.audioPlayerRef.current;
        if (player) {
            player.pause();
            player.src = ""
        }
        this.setState({
            currentPlayAudio: null,
            currentPlayDuration: 0,
        })
        if (this.onlineTimer) {
            clearInterval(this.onlineTimer)
        }
        if (this.playTimer) {
            clearInterval(this.playTimer);
        }
    }

    playTimer = null;

    resumePlayTimer = () => {
        if (this.playTimer) {
            clearInterval(this.playTimer);
        }
        this.playTimer = setInterval(() => {
            let player = this.audioPlayerRef.current;
            if (player) {
                this.setState({
                    currentPlayDuration: parseInt(player.currentTime + ""),
                    paused: player.paused
                })

            }
        }, 500)
    }

    clearPlayTimer = () => {
        if (this.playTimer) {
            clearInterval(this.playTimer);
        }
    }
    seekPlayerTime = (value) => {
        // console.log(`seekPlayerTime`, value)
        let player = this.audioPlayerRef.current;
        if (player && this.state.currentPlayAudio && this.state.currentPlayAudio.duration) {
            player.pause();
            player.currentTime = parseInt(this.state.currentPlayDuration)
            player.play()
            this.setState({
                seeking: false
            })
            this.resumePlayTimer()
        }

    }

    playOrPause = () => {
        let player = this.audioPlayerRef.current;
        if (player) {
            if (player.paused) {
                if (this.state.currentPlayAudio) {
                    player.play()
                    this.setState({
                        paused: false
                    })
                }
            } else {
                player.pause()
                this.setState({
                    paused: true
                })
            }
        }
    }

    playLast = () => {
        if (!this.state.currentPlayAudio || !this.state.detail || !this.state.audioList || !this.state.audioList.length || this.state.audioList.length == 1) {
            return
        }
        let currentIndex = this.state.audioList.findIndex(item => item.id === this.state.currentPlayAudio.id);

        if (currentIndex === 0) {
            currentIndex = this.state.audioList.length - 1;
        } else {
            currentIndex = currentIndex - 1
        }

        let audio = this.state.audioList[currentIndex];

        let player = this.audioPlayerRef.current;
        if (player) {

            if (this.state.currentPlayAudio && this.state.currentPlayAudio.id === audio.id) {

                player.pause()
            } else {
                audio.rowIndex = currentIndex;
                this.setState({
                    currentPlayAudio: audio,
                    currentPlayDuration: 0,
                }, () => {
                    this.getOnline()
                })
                player.pause()
                player.src = audio.sourceUrl;
                player.play();
                audioReq.incrementPlayNum(audio.id)
                    .then().catch()
                this.resumePlayTimer()
            }


        }
    }

    playerOnEnded = () => {
        this.playNext();
    }
    playNext = () => {
        if (!this.state.currentPlayAudio || !this.state.detail || !this.state.audioList || !this.state.audioList.length || this.state.audioList.length == 1) {
            return
        }
        let currentIndex = this.state.audioList.findIndex(item => item.id === this.state.currentPlayAudio.id);

        if (currentIndex === this.state.audioList.length - 1) {
            currentIndex = 0
        } else {
            currentIndex = currentIndex + 1
        }

        let audio = this.state.audioList[currentIndex];

        let player = this.audioPlayerRef.current;
        if (player) {

            if (this.state.currentPlayAudio && this.state.currentPlayAudio.id === audio.id) {
                if (player.paused) {

                } else {

                }
                player.pause()
            } else {
                audio.rowIndex = currentIndex;
                this.setState({
                    currentPlayAudio: audio,
                    currentPlayDuration: 0,
                }, () => {
                    this.getOnline()
                })
                player.pause()
                player.src = audio.sourceUrl;
                player.play();
                audioReq.incrementPlayNum(audio.id)
                    .then().catch()
                this.resumePlayTimer()

            }


        }
    }

    getOnline = () => {


        if (this.onlineTimer) {
            clearInterval(this.onlineTimer)
        }
        let request = () => {
            if (!this.state.currentPlayAudio) {
                return
            }
            audioReq.getOnlineCount(this.state.currentPlayAudio.id)
                .then(res => {
                    let currentPlayAudio = this.state.currentPlayAudio || {};
                    currentPlayAudio.onlineCount = res
                    this.setState({
                        currentPlayAudio
                    })
                })
                .catch(e => {
                })
        }
        this.onlineTimer = setInterval(request, 10000);
        request()

    }

    audioDetailOnPlay = detail => {
        // playAudio = (e) => {
        //     let index = e.currentTarget.dataset.index;
        //     let audio = this.state.detail.audios[index];
        let audioId = this.props.match.params.audioId;
        let findIndex = this.state.audioList.findIndex(item => item.id == audioId);
        if (findIndex >= 0) {
            this.playAudio({
                currentTarget: {
                    dataset: {
                        index: findIndex
                    }
                }
            })
        } else {
            detail = this.formatAudio(detail)
            this.setState({
                audioList: [detail],
            }, () => {
                this.playAudio({
                    currentTarget: {
                        dataset: {
                            index: 0
                        }
                    }
                })
            })
        }
    }

    onShowMiniCode = (audio) => {
        this.miniCodeUrl = audio && audio.wxQRCode ? audio.wxQRCode : null;
        if (this.miniCodeUrl) {
            this.setState({
                showMiniCodeModal: true
            })
        }
    }

    render() {
        let { windowSize, detail, currentPlayAudio, currentPlayDuration, showDetail, audioId, audioList, searchTag, listCount } = this.state;
        detail = detail || {}
        //  audioList = audioList || [];
        let that = this;
        let silderProps = {
            strokeColor: 'white',
            max: currentPlayAudio && currentPlayAudio.duration ? currentPlayAudio.duration : 100,
            onChange: value => {
                // console.log(`onChange`, value)
                this.clearPlayTimer()
                that.setState({
                    seeking: true,
                    currentPlayDuration: value
                })
            },
            onAfterChange: that.seekPlayerTime,
            tipFormatter: (value) => {
                // console.log(`value`,value)
                return that.formatDuration(parseInt(value));
            }

        }
        if (!this.state.seeking) {
            silderProps.value = currentPlayDuration || 0
        } else {
            delete silderProps.value
        }
        return (
            <Layout rootClassName="podcast-panel">

                <Row gutter={[{ xs: 0, sm: 0, md: 16, lg: 16 }, 16]} type="flex">
                    {/*播客信息Panel*/}
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}
                        order={(showDetail || searchTag) && windowSize && windowSize.width < 992 ? 2 : 1}>
                        <div className="podcast-info">
                            <div className='content-body'>
                                <Link to="/podcast">
                                    <img className="cover" src={detail && detail.preview ? detail.preview : ""} alt="" />
                                </Link>
                                <div className="cover-title">{detail && detail.title ? detail.title : ""}</div>
                                <p className='desc'>{detail.description || ""}</p>
                                <div className='inside-other-sources'>
                                    <a hidden={!detail || !detail.otherSources || !detail.otherSources.wangyiMusic}
                                        href={detail && detail.otherSources && detail.otherSources.wangyiMusic ? detail.otherSources.wangyiMusic : ""}
                                        target='_blank' rel="noopener noreferrer">
                                        <img src='/icons/wang-yi-music.png' alt='' />
                                    </a>
                                    <a hidden={!detail || !detail.otherSources || !detail.otherSources.ximalayaFM}
                                        href={detail && detail.otherSources && detail.otherSources.ximalayaFM ? detail.otherSources.ximalayaFM : ""}
                                        target='_blank' rel="noopener noreferrer">
                                        <img src='/icons/xi-ma-la-ya.png' alt='' />
                                    </a>
                                    <a hidden={!detail || !detail.otherSources || !detail.otherSources.bilibili}
                                        href={detail && detail.otherSources && detail.otherSources.bilibili ? detail.otherSources.bilibili : ""}
                                        target='_blank' rel="noopener noreferrer">
                                        <img src='/icons/bilibili.png' alt='' />
                                    </a>
                                    <a hidden={!detail || !detail.otherSources || !detail.otherSources.iTunesPodcasts}
                                        href={detail && detail.otherSources && detail.otherSources.iTunesPodcasts ? detail.otherSources.iTunesPodcasts : ""}
                                        target='_blank' rel="noopener noreferrer">
                                        <img src='/icons/itunes-podcast.png' alt='' />
                                    </a>
                                    <a href={`https://uigreat.com/podcast_rss.xml`} target='_blank'
                                        rel="noopener noreferrer">
                                        <img src='/icons/rss.png' alt='' />
                                    </a>

                                </div>
                                <div className='outside-other-sources'>
                                    <a className='item'
                                        hidden={!detail || !detail.otherSources || !detail.otherSources.googlePodcasts}
                                        href={detail && detail.otherSources && detail.otherSources.googlePodcasts ? detail.otherSources.googlePodcasts : ""}
                                        target='_blank' rel="noopener noreferrer">

                                        <img className='icon' src='/icons/google-podcast.png' alt='' />
                                        <div>Google Podcasts</div>
                                        <img className='arrow' src="/icons/arrow.png" alt='' />
                                    </a>
                                    <a className='item'
                                        hidden={!detail || !detail.otherSources || !detail.otherSources.overcast}
                                        href={detail && detail.otherSources && detail.otherSources.overcast ? detail.otherSources.overcast : ""}
                                        target='_blank' rel="noopener noreferrer">
                                        <img className='icon' src='/icons/overcast.png' alt='' />
                                        <div>Overcast</div>
                                        <img className='arrow' src="/icons/arrow.png" alt='' />
                                    </a>
                                    <a className='item'
                                        hidden={!detail || !detail.otherSources || !detail.otherSources.poctetCasts}
                                        href={detail && detail.otherSources && detail.otherSources.poctetCasts ? detail.otherSources.poctetCasts : ""}
                                        target='_blank' rel="noopener noreferrer">
                                        <img className='icon' src='/icons/poctetcasts.png' alt='' />
                                        <div>Poctet Casts</div>
                                        <img className='arrow' src="/icons/arrow.png" alt='' />
                                    </a>
                                    <a className='item'
                                        hidden={!detail || !detail.otherSources || !detail.otherSources.spotify}
                                        href={detail && detail.otherSources && detail.otherSources.spotify ? detail.otherSources.spotify : ""}
                                        target='_blank' rel="noopener noreferrer">
                                        <img className='icon' src='/icons/spotify.png' alt='' />
                                        <div>Spotify</div>
                                        <img className='arrow' src="/icons/arrow.png" alt='' />
                                    </a>

                                </div>

                            </div>
                            {/* 所有者 */}
                            <div className='authors'>

                                {
                                    detail.teacherInfo ? detail.teacherInfo.map((item, index) => {
                                        return <div className='item' key={index}>
                                            <Avatar className='avatar' src={item.avatar} />
                                            <div className="flex-r">
                                                <div className='name'>{item.nickname || item.username || ""}</div>
                                                <div className="wechat"
                                                    hidden={!item.userSocialAccount || !item.userSocialAccount.wechat}>WeChat：{item.userSocialAccount && item.userSocialAccount.wechat ? item.userSocialAccount.wechat : ""}</div>
                                            </div>
                                        </div>
                                    }) : null
                                }

                            </div>
                        </div>
                    </Col>

                    <Col className="flex-r" xs={24} sm={24} md={24} lg={16} xl={16}
                        order={(showDetail || searchTag) && windowSize && windowSize.width < 992 ? 1 : 2}>
                        {/*列表/详情页*/}
                        {showDetail ?
                            (
                                <AudioDetail seriesDetail={detail} onClickPlay={this.audioDetailOnPlay}
                                    audioId={audioId}
                                    currentPlayAudio={currentPlayAudio} paused={this.state.paused}
                                    onShowMiniCode={this.onShowMiniCode} />
                            ) : (
                                <AudioList tag={searchTag} audioList={audioList} currentPlayAudio={currentPlayAudio}
                                    onClickPlay={this.playAudio} paused={this.state.paused}
                                    onShowMiniCode={this.onShowMiniCode}>
                                    <Pagination defaultCurrent={1} total={listCount}
                                        pageSize={PerPage} onChange={page => {
                                            this.setState({
                                                listPage: page - 1
                                            }, () => {
                                                if (this.state.searchTag) {
                                                    this.searchAudioWithTag()
                                                } else {
                                                    this.getAudioList()
                                                }
                                            })
                                        }}
                                        hideOnSinglePage={true}
                                    />
                                </AudioList>
                            )}

                        {/*播放器组件*/}
                        <div className="play-panel" hidden={!currentPlayAudio}>
                            <div className="play-wrapper">
                                <div className='flex-t'>
                                    <div className="play-icon">
                                        <IconFont className="icon" type="iconxiayishou1" onClick={this.playLast} />
                                        <IconFont className="icon" type={this.state.paused ? "iconbofang" : "iconpause"}
                                            onClick={this.playOrPause} />
                                        <IconFont className="icon" type="iconxiayishou" onClick={this.playNext} />
                                    </div>
                                    <Link
                                        className="play-title"
                                        to={currentPlayAudio ? `/podcast/${currentPlayAudio.id}` : ""}>{currentPlayAudio && currentPlayAudio.title ? currentPlayAudio.title : ""}</Link>
                                    {/* <div
                                        className="play-time"  hidden={!currentPlayAudio}>{currentPlayAudio ? currentPlayAudio.onlineCount || 0 :0 }人在听</div> */}
                                    <div
                                        className="play-time">{this.formatDuration(currentPlayDuration)}<span
                                            style={{ color: "#AFAFAF" }}>/ {currentPlayAudio && currentPlayAudio.duration_fm ? currentPlayAudio.duration_fm : "00:00"}</span>
                                    </div>
                                </div>

                                <div className="play-progress">

                                    <div className="play-bar">
                                        <Slider  {...silderProps} />
                                    </div>
                                </div>
                                <div className="play-close">
                                    <Icon type="close-circle" style={{ fontSize: 18, color: "rgba(255,255,255,0.5)" }}
                                        onClick={this.stopPlayer} />
                                </div>

                            </div>
                        </div>


                    </Col>

                </Row>


                <audio hidden={true} ref={this.audioPlayerRef} onEnded={this.playerOnEnded} />
                <Modal
                    className="podcast-modal"
                    centered={true}
                    visible={this.state.showMiniCodeModal}
                    onCancel={() => {
                        this.setState({
                            showMiniCodeModal: false
                        })
                        this.miniCodeUrl = null
                    }}
                    destroyOnClose={true}
                    title={null}
                    footer={null}
                >
                    <img className={`qr-code`} src={this.miniCodeUrl || ""} alt="" />
                    <div className={`qr-text`}>
                        小程序可以锁屏播放，体验更好
                    </div>
                </Modal>
            </Layout>)
    }

}

const mapStateToProps = (state, ownProps) => ({
    windowSize: state.system.windowSize
});

export default connect(mapStateToProps, {})(Podcast);





