import React from 'react';
import "./healthSign.scss";

import { Tabs, message, Input, Button, Radio } from "antd";

import { connect } from 'react-redux'
import api from '../../request/indexReq'
import dateformat from 'dateformat'
import IconFont from "../../components/iconfont/icon-font";
const { TabPane } = Tabs;

class HealthSign extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
        this.nicknameInputRef = React.createRef();
    }

    state = {
        cid: null,
        isHealth: null,
        temperature: null,
        remark: null,
        inBeijing: null,
        memberInfo: null,
        enable: true,
        showEnd: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    componentDidMount() {
        let now = new Date();
        let nowTS = now.getTime();
        let today900 = new Date(dateformat(now, 'yyyy/mm/dd 08:00')).getTime();
        let today1200 = new Date(dateformat(now, 'yyyy/mm/dd 12:00')).getTime();
        if (nowTS >= today900 && nowTS < today1200) {
            this.setState({
                enable: true
            })
        }
    }

    getMemberInfo() {
        if (this.state.cid && this.state.cid.length === 18) {
            api.getMemberInfo(this.state.cid)
                .then(res => {
                    this.setState({
                        memberInfo: res && res.length ? res[0] : null
                    })
                })
        }
    }

    submitSign = () => {
        let { cid, temperature, remark, isHealth, inBeijing, memberInfo } = this.state;
        if (!cid) {
            return message.warn(`填写你的身份证号`)
        }
        if (this.state.cid.length !== 18) {
            return message.warn(`请填写18位身份证号码`)
        }
        if (!memberInfo) {
            message.warn(`通过身份证未能查找到你的学员身份`)
            return message.warn(`请填写入学时的身份证号码`)
        }
        if (!inBeijing) {
            return message.warn(`是否在京`)
        }
        if (!isHealth) {
            return message.warn(`今日身体是否健康`)
        }
        if (!temperature) {
            return message.warn(`请填写今日体温`)
        }
        if (isHealth == 2 && !remark) {
            return message.warn(`请描述你的症状`)
        }

        this.setState({
            loading: true
        })
        let c = cid.toUpperCase();
        api.submitHealthSign({
            cid: c,
            remark: isHealth == 2 ? remark : " ",
            inBeijing,
            isHealth,
            temperature
        })
            .then(res => {
                message.success(`你已成功签到`)
                this.setState({
                    showEnd: true,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                message.warn(`发生错误，请稍后重试`)
            })

    }
    render() {
        let { cid, temperature, remark, isHealth, inBeijing, memberInfo, enable, showEnd } = this.state;
        if (showEnd) {
            return <div className="page-health-sign" style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column"
            }}>
                <IconFont type={"icontrue"} style={{ color: "#05EA21", fontSize: "50px", marginTop: 100 }} />
                <div className={`form-title`} style={{ marginTop: 30 }}>你已成功签到</div>
            </div>
        }
        if (!enable) {
            return <div className="page-health-sign" style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column"
            }}>
                <IconFont type={"iconnaozhong"} style={{ color: "#EAC01B", fontSize: "50px", marginTop: 100 }} />
                <div className={`form-title`} style={{ marginTop: 30 }}>每天08：00 —— 12：00开放签到</div>
            </div>
        }
        return (
            <div className="page-health-sign">

                <div className={`name`}>早上好，{memberInfo && memberInfo.username ? memberInfo.username : ""}</div>
                <div className={`form-item`}>
                    <div className={`form-title`}>身份证号</div>
                    <Input size={`large`} value={cid || ""} onChange={e => {

                        this.setState({
                            cid: e.target.value
                        }, () => {
                            if (this.state.cid && this.state.cid.length == 18) {
                                this.getMemberInfo()
                            }
                        })

                    }} />
                </div>
                <div className={`form-item`}>
                    <div className={`form-title`}>是否在京</div>
                    <Radio.Group className={`radio-group`} onChange={e => {
                        this.setState({
                            inBeijing: e.target.value
                        })
                    }} value={inBeijing}>
                        <Radio className={`radio`} value={1}>我在北京</Radio>
                        <Radio className={`radio`} value={2}>我不在北京</Radio>
                    </Radio.Group>
                </div>
                <div className={`form-item`}>
                    <div className={`form-title`}>今日身体是否健康</div>
                    <Radio.Group className={`radio-group`} onChange={e => {
                        this.setState({
                            isHealth: e.target.value
                        })
                    }} value={isHealth}>
                        <Radio className={`radio`} value={1}>感觉很好</Radio>
                        <Radio className={`radio`} value={2}>有些不适</Radio>
                    </Radio.Group>
                    <Input size={`large`} style={{ marginTop: 20 }} hidden={isHealth != 2} value={remark} placeholder={`请描述你的症状`}
                        onChange={e => {
                            this.setState({
                                remark: e.target.value
                            })
                        }} />
                </div>
                <div className={`form-item`}>
                    <div className={`form-title`}>今日体温</div>
                    <Input size={`large`} value={temperature} placeholder={`请填写你的体温°C`}
                        onChange={e => {
                            this.setState({
                                temperature: e.target.value
                            })
                        }} />
                </div>
                <Button className={`submit-btn`} type={'primary'} loading={this.state.loading} onClick={this.submitSign}>签到,我要上课了</Button>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

};
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(HealthSign);
