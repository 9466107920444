/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "./nav.scss"
import {connect} from 'react-redux'
import {Avatar, Dropdown, Menu, Button, Drawer, Icon, Row, Col, Popover,Badge} from 'antd'
import IconFont from '../iconfont/icon-font'
import {Link} from 'react-router-dom'
import actions from "../../redux/actions";
import LoginRegisterModal from "../../layouts/login-register-modal/LoginRegisterModal";
import config from '../../config';
import ListEmpty from "../listEmpty/ListEmpty";


const defaultDropMenus = [{
    name: "用户中心",
    key: "account"
},
// {
//     name: "发布作品",
//     key: "shot-post"
// }, 
    {
        name: "注销登录",
        key: "logout"
    }];

const navItems = [
    {
        name: "首页",
        href: "/",
    },
    {
        name: "线上课",
        href: "/course",
    },
    {
        name: "像素范儿线下课时间表",
        href: "/course-plan",
    },
    {
        name: "作品",
        href: "/shots",
    },
    {
        name: "工具",
        href: "/tool",
    },
    {
        name: "设计导航",
        href: "http://so.uigreat.com",
        target: "_blank",
        isOut: true
    },
    {
        name: "且曼播客",
        href: "/podcast",
    },

];

class Nav extends React.Component {
    constructor(props) {
        super();
        this.state = {
            drawerVisible: false,
            showLoginAndRegister: false,
            loginAndRegisterAction: "login",

            ...props
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            hidden: nextProps.hidden,
            style: nextProps.style,
            className: nextProps.className,
            loginUserInfo:nextProps.loginUserInfo,
            isLogin:nextProps.isLogin,
            unreadMessagesData:nextProps.unreadMessagesData,
            systemBulletin:nextProps.systemBulletin
        };
    }

    showLoginAndRegister = e => {
        return window.appLogin();
    }

    componentDidMount() {

    }



    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    composeAccountArea(isLogin, loginUserInfo,unreadMessagesData) {

        if (this.props.componentsRightArea) {
            return <div className='flex-r'>
                {this.props.componentsRightArea}
            </div>
        }
        let accountArea = (
            <div className='flex-r'>
                <Button className={'action-btn'} onClick={this.showLoginAndRegister} data-action='login'>登录</Button>
                <Button className={'action-btn'} onClick={this.showLoginAndRegister} data-action='register'>注册</Button>
            </div>
        )
        if (isLogin) {
            let {avatar, username} = loginUserInfo;
            let userAvatar = <Avatar icon={<IconFont type='icon-icon30'/>} style={{width: 40, height: 40}}/>
            if (avatar) {
                userAvatar =
                    <Avatar src={avatar} style={{width: 36, height: 36, border: "2px solid rgba(255,255,255,0.2)"}}/>
            } else if (username) {
                let str = username[0];
                userAvatar =
                    <Avatar style={{fontSize: 20, background: '#F7F9FC', color: "#59B6FF"}}>{str.toUpperCase()}</Avatar>
            }
            
            let messages = unreadMessagesData.rows.map((item,index)=>{
               let body = item.body || {};
               let fromUser = body.fromUser || {};
               let courseInfo = body.courseInfo || {};
               let shotInfo = body.shotInfo || {};
                let audioInfo = body.audioInfo || {};
                switch (item.noticeType) {
                    case `course-comment`:
                        return <div className={`message-item`} key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在课程<div className={`title`}>《{courseInfo.title}》</div>中发布了评论</div>
                        </div>
                    case `course-comment-reply`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在课程<div className={`title`}>《{courseInfo.title}》</div>中回复了你</div>
                        </div>
                    case `audio-comment`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在播客<div className={`title`}>《{audioInfo.title}》</div>中发布了评论</div>
                        </div>
                    case `audio-comment-reply`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在播客<div className={`title`}>《{audioInfo.title}》</div>中回复了你</div>
                        </div>
                    case `audio-comment-like`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在播客<div className={`title`}>《{audioInfo.title}》</div>中赞了你的评论</div>
                        </div>
                    case `shot-comment`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在作品<div className={`title`}>《{shotInfo.title}》</div>中发布了评论</div>
                        </div>
                    case `shot-comment-reply`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在作品<div className={`title`}>《{shotInfo.title}》</div>中回复了你</div>
                        </div>
                    case `shot-comment-like`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在作品<div className={`title`}>《{shotInfo.title}》</div>中赞了你的评论</div>
                        </div>
                    case `course-comment-like`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>在课程<div className={`title`}>《{courseInfo.title}》</div>中赞了你的评论</div>
                        </div>
                    case `course-collection`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>收藏了课程<div className={`title`}>《{courseInfo.title}》</div></div>
                        </div>
                    case `shot-collection`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>收藏了作品<div className={`title`}>《{shotInfo.title}》</div></div>
                        </div>
                    case `shot-like`:
                        return <div className={`message-item`}  key={item.id}>
                            <div className={`message-title`}><div className={`nickname`}>{fromUser.nickname}</div>赞了作品<div className={`title`}>《{shotInfo.title}》</div></div>
                        </div>
                    default :
                        break;
                }
                
            })
            let messageList = <Link to={`/account?tab=message`}>
                <div className={`message-list`} >
                    <ListEmpty className={`list-empty`} show={!unreadMessagesData.rows.length} text={'无未读消息'} />
                    {messages}
                </div>
            </Link> ;

            accountArea = (
                <div className='flex-r'>
                    <Popover placement="bottomRight" title={"消息通知"} content={messageList} trigger="click" overlayClassName={`message-overlay-box`}>
                        <Badge dot={unreadMessagesData.rows.length > 0} className={`notice-box`}>
                            <IconFont className={`notice-icon`} type={`icontongzhi`}/>
                        </Badge>

                    </Popover>
                    <Dropdown overlay={this.composeDropMenus(loginUserInfo)} placement='bottomRight'>
                        {userAvatar}
                    </Dropdown>

                </div>
            )
        }

        return accountArea;
    }

    composeDropMenus = (loginUserInfo) => {
        let menuItems = defaultDropMenus.map((item, index) => {
            let path = ''
            let isOutUrl = false;
            switch (item.key) {
                case 'post':
                    path = '/post'
                    break;
                case 'shot-post':
                    if (!loginUserInfo.userPower || !loginUserInfo.userPower.canPostShot) {
                        return null;
                    }
                    path = `/post/shot/create`;
                    break;
                case 'account':
                    path = `/account/`
                    break;
                case 'logout':
                    if (config.environment === "development") {
                        path = `/logout/`
                    } else {
                        isOutUrl = true
                        path = config.logoutUrl
                    }
                    break;
                default:
                    break;
            }
            if (isOutUrl) {
                return <Menu.Item key={index}><a href={path}>{item.name}</a></Menu.Item>
            } else {
                return <Menu.Item key={index}><Link to={path}>{item.name}</Link></Menu.Item>
            }

        })



        if (loginUserInfo && loginUserInfo.roles ) {
            let showAdmin = false;
            for( let roleKey in loginUserInfo.roles){
                if(loginUserInfo.roles[roleKey]){
                    showAdmin = true;
                    break;
                }
            }
            if(showAdmin){
                menuItems.splice(menuItems.length - 1, 0, <Menu.Divider key={`Divider-1`}/>, <Menu.Item key={"admin99999999"}><a
                    href={`http://account.uigreat.com/?uhost=http://admin.uigreat.com/ug-account-center/admin-login`}
                    target={`_blank`}>后台管理</a></Menu.Item>)
            }

        }
        menuItems.splice(menuItems.length - 1, 0, <Menu.Divider key={`Divider-2`}/>)
        return <Menu>{menuItems}</Menu>;
    }

    composeNavItems = () => {

    };

    showDrawer = () => {
        this.setState({
            drawerVisible: true,
        });
    };

    onClose = () => {
        this.setState({
            drawerVisible: false,
        });
    };

    render() {
        let {isLogin, loginUserInfo, hidden, style,unreadMessagesData} = this.state;
        let accountArea = this.composeAccountArea(isLogin, loginUserInfo,unreadMessagesData);

        if (hidden) {
            return null;
        }


        return (
            <div className={`bks-nav ${this.state.className || ''}`} style={style}>
                <div className={`index-header`}>
                    <img src="/bg/bg_item1.png" alt=""/>
                    <img src="/bg/bg_item2.png" alt=""/>
                    <img src="/bg/bg_item3.png" alt=""/>
                    <img src="/bg/bg_item1.png" width="70" alt=""/>
                    <img src="/bg/bg_item1.png" width="70" alt=""/>
                </div>
                <div className='container'>
                    <Row
                        type="flex"
                        justify="space-between"
                        gutter={[16, 16]}
                        align="middle"
                        style={{height: "100%", width: "100%"}}
                    >
                        <Col xs={{span: 0}} md={{span: 2}} lg={{span: 2}} xl={{span: 2}}>
                            <Link to={"/"}> <img className='logo' src="/logo.svg" alt=''/></Link>
                        </Col>
                        <Col xs={{span: 6}} md={{span: 18}} lg={{span: 18}} xl={{span: 18}}
                             style={{display: "flex", justifyContent: "flex-start"}}>
                            <Icon type="menu" onClick={this.showDrawer} className="xs-show" style={{color: "#ffffff"}}/>
                            <Drawer
                                title={
                                    <img className='logo' src="/logo.svg" alt='' width="50"/>
                                }
                                placement="top"
                                closable={false}
                                onClose={this.onClose}
                                visible={this.state.drawerVisible}
                                height={"auto"}
                                bodyStyle={{background: "#3945F9"}}
                                headerStyle={{background: "#3945F9"}}
                            >
                                <div className={'nav-items'}>
                                    {navItems.map((item, index) => {
                                        if (item.isOut) {
                                            return <a title={item.name} href={item.href} target={item.target}
                                                      key={index}>{item.name}</a>
                                        } else {
                                            return <Link title={item.name} to={item.href} key={index}>{item.name}</Link>
                                        }
                                    })}
                                </div>
                            </Drawer>
                            <div className={'nav-items xs-hidden'}>
                                {navItems.map((item, index) => {
                                    if (item.isOut) {
                                        return <a title={item.name} href={item.href} target={item.target}
                                                  key={index}>{item.name}</a>
                                    } else {
                                        return <Link title={item.name} to={item.href} key={index}>{item.name}</Link>
                                    }
                                })}
                            </div>
                        </Col>
                        <Col xs={{span: 6}} md={{span: 4}} lg={{span: 4}} xl={{span: 4}}
                             style={{display: "flex", justifyContent: "flex-end"}}>
                            {accountArea}
                        </Col>
                    </Row>

                </div>
                <LoginRegisterModal/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({

    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo,
    unreadMessagesData:state.account.unreadMessagesData,
})
const mapDispatchToProps = {
    handlerShowLoginRegisterModal: actions.accountShowLoginRegisterModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);