import React from "react";
import Nav from "../../components/nav/Nav";
import ListEmpty from "../../components/listEmpty/ListEmpty";
import Layout from "../../layouts/layout/Layout";
import { Carousel, Icon, Row, Col, Menu } from "antd";
import IconFont from "../../components/iconfont/icon-font";
import MobileCategoryTabs from "../../components/mobileCategoryTabs/MobileCategoryTabs";
import OvalLoading from "../../components/OvalLoading/OvalLoading";
import CourseList from "../../components/courseList/CourseList";
import courseReq from "../../request/courseReq";
import indexReq from "../../request/indexReq";
import "./liveCourseList.scss";
import bannerReq from "../../request/bannerReq";

import "./liveCourseList.scss"
import ListFooter from "../listFooter/ListFooter";
import storage from "../../storage/storage";
import utilFunc from "../../utils/util";
const { SubMenu } = Menu;
const PerPage = 30;
const loadingStatus = {
  init: "init",
  firstLoading: "first-loading",
  loadingMore: "loading-more",
  firstError: "first-error",
  moreError: "more-error"
};

const STORAGE_CACHED_KEY_LiveCourseList_PAGE_STATE = "STORAGE_CACHED_KEY_LiveCourseList_PAGE_STATE"
class LiveCourseList extends React.Component {
  constructor() {
    super();
    this.carouselRef = React.createRef();
  }
  state = {
    selectCategoryIndexPath: [],
    groups: [],
    getGroupFail: false,
    loadingCourseListStatus: loadingStatus.init,
    courseListNoMore: false,
    courses: [],
    courseTotalCount: 0,
    selectedCategoryId: null,
    searchKeyword: null,
    categoryId: -1,
    banners: [],
    littleBanners: [],
    currentBannerIndex: 0,
    searchInputOnFocus: false,
    apps: [],
    imageAveColor: {}
  };

  componentDidMount() {
    utilFunc.setDocumentInfo({
      title:"直播课 | 优阁网",
      description:"直播课",
      keywords:`优阁直播课`
    })
    let cachedState = storage.getCached(STORAGE_CACHED_KEY_LiveCourseList_PAGE_STATE);
    if(cachedState){
      this.setState({
        ...cachedState
      })
    }else {
      this.getGroups();
      this.getCourses();
      this.getBanners();
    }
    document.onkeydown = e => {
      if (
        e &&
        e.keyCode === 13 &&
        this.state.searchInputOnFocus &&
        this.lastSearchValue !== this.state.searchKeyword
      ) {
        this.lastSearchValue = this.state.searchKeyword;
        this.courseListPage = -1;
        this.getCourses();
      }
    };
  }

  componentWillUnmount() {
    storage.setCached(STORAGE_CACHED_KEY_LiveCourseList_PAGE_STATE,this.state)
  }

  getBanners() {
    // 主banner
    bannerReq.getBanners(`live-course-banner`).then(res => {
      if (res && res.length) {
        res.forEach(item => {
          indexReq.getQiniuImageAveColor(item.preview).then(res => {
            let colors = this.state.imageAveColor;
            colors[item.preview] = res;
            this.setState({
              imageAveColor: colors
            });
          });
        });
      }
      this.setState({
        banners: res || []
      });
    });
  }
  getGroups() {
    courseReq
      .getGroupAndCategory('live-course')
      .then(res => {
        this.setState({
          groups: res,
          getGroupFail: false
        });
      })
      .catch(error => {
        this.setState({
          getGroupFail: true
        });
      });
  }

  courseListPage = -1;

  getCourses = () => {
    let that = this;
    if (this.state.loadingCourseListStatus == loadingStatus.firstLoading || this.state.loadingCourseListStatus == loadingStatus.loadingMore) {
      return;
    }
    let condition = (function () {
      let body = {
        perPage: PerPage,
        page: that.courseListPage + 1
      };
      if (that.state.categoryId > 0) {
        body.categoryId = that.state.categoryId;
      }

      if (that.state.searchKeyword) {
        body.teacherName = that.state.searchKeyword;
        body.title = that.state.searchKeyword;
      }

      return body;
    })();
    if (condition.page < 1) {
      this.setState({
        loadingCourseListStatus: loadingStatus.firstLoading,
        courses: []
      });
    } else {
      this.setState({
        loadingCourseListStatus: loadingStatus.loadingMore
      });
    }
    condition.courseType = "live-course";
    courseReq
      .getCourseList(condition)
      .then(res => {
        let moreCourses = res.rows;
        let count = res.count;
        let noMore = false;
        if (moreCourses.length < condition.perPage) {
          noMore = true;
        }
        let courses = this.state.courses;
        if (condition.page < 1) {
          courses = [];
        }
        this.courseListPage += 1;
        if (res && res.rows && res.rows.length) {
          res.rows.forEach(item => {
            indexReq.getQiniuImageAveColor(item.preview).then(res => {
              let colors = this.state.imageAveColor;
              colors[item.preview] = res;
              this.setState({
                imageAveColor: colors
              });
            });
          });
        }
        this.setState({
          loadingCourseListStatus: loadingStatus.init,
          courseListNoMore: noMore,
          courses: courses.concat(moreCourses),
          courseTotalCount: count
        });
      })
      .catch(error => {
        if (condition.page < 1) {
          this.setState({
            loadingCourseListStatus: loadingStatus.firstError
          });
        } else {
          this.setState({
            loadingCourseListStatus: loadingStatus.moreError
          });
        }
      });
  }

  categoryOnSelected = value => {
    this.setState(
      {
        selectCategoryIndexPath: value
      },
      () => {
        this.courseListPage = -1;
        this.getCourses();
      }
    );
  };

  onChangeCategory = categoryId => {
    this.setState(
      {
        categoryId
      },
      () => {
        this.courseListPage = -1;
        this.getCourses();
      }
    );
  };
  render() {
    let {
      courses,
      loadingCourseListStatus,
      courseListNoMore,
      banners
    } = this.state;

    return (
      <Layout rootClassName="live-course">
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
          <Col xs={24}>
            <div className="banner-box">
              <Carousel
                arrows={false}
                autoplay={false}
                autoplaySpeed={4000}
                draggable={true}
                dots={true}
                className={"carousel"}
                afterChange={this.afterChangeBanner}
                ref={this.carouselRef}
              // adaptiveHeight={true}
              >
                {banners.map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={item.url}
                      target={item.openMethod === "self" ? "" : "_blank"}
                    >
                      <div className={"background-box"}>
                        <div className="banner-inner-panel">
                          <img
                            src={item.preview}
                            alt={""}
                            style={{
                              backgroundColor:
                                this.state.imageAveColor[item.preview] || ""
                            }}
                          />
                        </div>
                      </div>
                    </a>
                  );
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
        {/*课程列表分类和搜索*/}
        <div className={"list-header"}>
          <div className={"category-search"}>
            <Row type="flex" justify="space-between" gutter={16} align="middle">
              <Col xs={4} md={16} lg={14} xl={18}>
                <MobileCategoryTabs
                  groups={this.state.groups}
                  onSearch={this.onChangeCategory}
                  useCache={true}
                  cacheKey={`live-course-page-tabs`}
                />
                {/* <IndexGroupCategoryTabs groups={this.state.groups} value={this.state.selectCategoryIndexPath || []} onSelected={this.categoryOnSelected} /> */}
              </Col>
              <Col xs={18} md={8} lg={10} xl={6}>
                <div className={`search-bar`}>
                  <input
                    type={"search"}
                    placeholder={
                      this.state.searchInputOnFocus
                        ? "输入课程名称或老师昵称"
                        : "搜索"
                    }
                    onFocus={() => {
                      this.lastSearchValue = this.state.searchKeyword + "";
                      this.setState({
                        searchInputOnFocus: true
                      });
                    }}
                    onChange={e => {
                      this.setState({
                        searchKeyword: e.currentTarget.value
                      });
                    }}
                    value={this.state.searchKeyword}
                  />
                  <IconFont
                    className={`search-icon ${
                      this.state.searchKeyword ? "searching" : ""
                      }`}
                    type={"iconsearch-icon"}
                    onClick={() => {
                      if (this.lastSearchValue !== this.state.searchKeyword) {
                        this.lastSearchValue = this.state.searchKeyword;
                        this.courseListPage = -1;
                        this.getCourses();
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className={"list-body"}>
          <OvalLoading
            className={"loading"}
            show={loadingCourseListStatus === loadingStatus.firstLoading}
          />
          <CourseList courses={courses} colors={this.state.imageAveColor} />
          <ListEmpty
            show={
              loadingCourseListStatus === loadingStatus.init &&
              !courses.length
            }
          />
        </div>
        <ListFooter show={[loadingStatus.loadingMore, loadingStatus.moreError, loadingStatus.init].indexOf(loadingCourseListStatus) >= 0 && courses.length} status={courseListNoMore ? 'no-more' : loadingCourseListStatus === loadingStatus.loadingMore ? 'loading-more' : "loading-error"} onLoadingMore={this.getCourses} />
      </Layout>
    );
  }
}

export default LiveCourseList;
