import React from 'react';
import "./account.scss";
import Nav from "../../components/nav/Nav";
import {Tabs, Table, Upload, message, Input, Button, Modal, Pagination, Radio, Divider} from "antd";
import AccountCourseListCard from '../../components/accountCourseListCard/AccountCourseListCard';
import IconFont from "../../components/iconfont/icon-font";
import indexReq from "../../request/indexReq";
import accountReq from "../../request/accountReq";
import actions from "../../redux/actions";
import {connect} from "react-redux";
import ListEmpty from "../../components/listEmpty/ListEmpty";
import OvalLoading from "../../components/OvalLoading/OvalLoading";
import courseReq from "../../request/courseReq";
import {Link} from 'react-router-dom'
import dateformat from 'dateformat'
import CourseList from "../../components/courseList/CourseList";
import ShotList from "../../components/shotsList/ShotList";
import shotReq from "../../request/shotReq";
import MessageListItem from "../../components/messageListItem/MessageListItem";

import URLParse from "url-parse";
import AllCourseList from "../../components/allCourseList/AllCourseList";
import audioReq from "../../request/audioReq";


const {TabPane} = Tabs;

const columns = [
    {
        title: '订单编号',
        dataIndex: 'orderNum',
        key: 'orderNum',
        width: 200,
    },
    {
        title: '课程名称',
        dataIndex: 'courseInfo.title',
        key: 'course-title',
        render: (text, item, index) => {
            return <Link to={`/course/${item.courseId}`}>{text}</Link>
        }
    }, {
        title: '金额',
        dataIndex: 'paidAmount',
        key: 'paidAmount',
        width: 100,
        render: text => {
            let number = parseFloat(text / 100).toFixed(2)
            return <div>￥{number}</div>
        }

    }, {
        title: '时间',
        dataIndex: 'paySuccessTime',
        key: 'time',
        width: 120,
        render: text => {
            if (text) {
                return dateformat(new Date(text), 'yyyy/mm/dd HH:MM:ss')
            } else {
                return "";
            }
        }

    }, {
        title: '订单状态',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: status => {
            switch (status) {
                case 'paid':
                    return <div>支付成功</div>
                case 'refunding':
                    return <div>退款中</div>
                case 'refund-fail':
                    return <div>退款失败</div>
                default:
                    return ""
            }
        }
    },
]

class Account extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
        this.nicknameInputRef = React.createRef();
    }

    state = {
        orders: [],
        showNicknameEdit: false,
        searching: false,
        nicknameInfo: {
            status: '',// ing,true,false
            nickname: ''
        },
        recentTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        purchaseTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        currentCollectionTab: "course",
        collectionCourseTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        collectionShotTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        subscribedTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        messageTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        orderTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        myShotsTabData: {
            rows: [],
            page: 1,
            loaded: false,
            perPage: 12,
            total: 0
        },
        currentTab: 'recent',
        messageOnShowCommentArea: null


    }

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    componentDidMount() {

        this.getRecentLearnRecords();
        this.parsePathName();
    }

    parsePathName = () => {

        let url = new URLParse(window.location.href, true)

        if (url && url.query && url.query.tab) {
            this.setState({
                currentTab: url.query.tab
            }, () => {
                this.getMyMessages(1)
            })
        }

    }
    previewUploaderBeforeUpload = (fileInfo) => {
        if (fileInfo.size > 1024 * 1024 * 2) {
            return message.warning('图片不能大于2M')
        }

        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                accountReq.updateAccountInfo({
                    avatar: url
                }).then(res => {
                    if (this.props.addLoginUserInfo) {
                        this.props.addLoginUserInfo(res);
                    }
                    message.success('头像已更新');
                })
                    .catch(() => {
                        message.error('保存头像时发生错误，请重试');
                    })
            },
            progress: (e) => {

            },
            error: (error) => {
                message.error('上传发生错误，请重试');

            },
        })
    };

    changeNickname = e => {
        accountReq.updateAccountInfo({
            nickname: this.state.nicknameInfo.nickname
        }).then(res => {
            if (this.props.addLoginUserInfo) {
                this.props.addLoginUserInfo(res);
            }
            this.setState({
                showNicknameEdit: false
            })
            message.success('昵称已修改');
        })
            .catch(() => {
                message.error('保存昵称时发生错误，请重试');
            })
    }

    nicknameSearchTimer = null;
    nickNameInputOnChange = (e) => {

        let value = e.currentTarget.value;
        if (this.nicknameSearchTimer) {
            clearTimeout(this.nicknameSearchTimer)
        }
        let nicknameInfo = this.state.nicknameInfo;
        nicknameInfo.nickname = value;
        nicknameInfo.status = 'ing'
        this.setState({
            nicknameInfo
        })
        this.nicknameSearchTimer = setTimeout(() => {
            accountReq.checkIfNicknameValid(value)
                .then((res => {
                    nicknameInfo.nickname = value;
                    nicknameInfo.status = res.isValid ? "true" : "false";
                    this.setState({
                        nicknameInfo
                    })
                }))
        }, 1500);
    }

    tabOnChange = current => {
        this.setState({
            currentTab: current
        })
        switch (current) {
            case 'recent':
                if (!this.state.recentTabData.rows.length) {
                    this.getRecentLearnRecords(1);
                }
                break;
            case 'collection':
                if (this.state.currentCollectionTab === 'course') {
                    if (!this.state.collectionCourseTabData.rows.length) {
                        this.getMyCollectionCourse(1);
                    }
                }

                if (this.state.currentCollectionTab === 'shot') {
                    if (!this.state.collectionShotTabData.rows.length) {
                        this.getMyCollectionShots(1);
                    }
                }

                break;
            case 'subscribed':
                if (!this.state.subscribedTabData.rows.length) {
                    this.getSubscribedCourse(1);
                }
                break;
            case "order":
                if (!this.state.orderTabData.rows.length) {
                    this.getMyCourseOrders(1);
                }
                break;
            case "purchased":
                if (!this.state.purchaseTabData.rows.length) {
                    this.getPurchasedCourses(1);
                }
                break;
            case "shots":
                if (!this.state.myShotsTabData.rows.length) {
                    this.getMyShots(1);
                }
                break;
            case "message":
                if (!this.state.messageTabData.rows.length) {
                    this.getMyMessages(1);
                }
                break;
            default:
                break;

        }

    }

    recentTabDataOnChangePage = (current) => {
        this.getRecentLearnRecords(current);
    }
    collectionCourseTabDataOnChangePage = (current) => {
        this.getMyCollectionCourse(current);
    }
    collectionShotTabDataOnChangePage = (current) => {
        this.getMyCollectionShots(current);
    }

    subscribedTabDataOnChangePage = (current) => {
        this.getSubscribedCourse(current);
    }

    messageTabDataOnChangePage = (current) => {
        this.getMyMessages(current);
    }
    orderTabDataOnChangePage = (current) => {
        this.getMyCourseOrders(current);
    }
    purchasedTabDataOnChangePage = (current) => {
        this.getPurchasedCourses(current);
    }
    myShotsTabDataOnChangePage = (current) => {
        this.getMyShots(current);
    }
    getRecentLearnRecords = (page) => {
        courseReq.getRecentWatchedCourse(page - 1, this.state.recentTabData.perPage)
            .then(res => {
                let rows = res.rows;
                let count = res.count;
                let recentTabData = this.state.recentTabData;
                recentTabData.total = count;
                recentTabData.rows = rows || [];
                recentTabData.loaded = true
                this.setState({
                    recentTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }

    getMyCollectionCourse = (page) => {
        courseReq.getMyCollectionCourses(page - 1, this.state.collectionCourseTabData.perPage)
            .then(res => {
                let rows = res.rows;
                let count = res.count;
                let collectionCourseTabData = this.state.collectionCourseTabData;
                collectionCourseTabData.total = count;
                collectionCourseTabData.rows = rows || [];
                collectionCourseTabData.loaded = true
                this.setState({
                    collectionCourseTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }
    getMyCollectionShots = (page) => {
        shotReq.getMyCollectShots(page - 1, this.state.collectionShotTabData.perPage)
            .then(res => {

                let rows = res.rows;
                let count = res.count;
                let collectionShotTabData = this.state.collectionShotTabData;
                collectionShotTabData.total = count;
                collectionShotTabData.rows = rows || [];
                collectionShotTabData.loaded = true
                this.setState({
                    collectionShotTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }
    getSubscribedCourse = (page) => {
        courseReq.getSubscribedCourses(page - 1, this.state.subscribedTabData.perPage)
            .then(res => {
                let rows = res.rows;
                let count = res.count;
                let subscribedTabData = this.state.subscribedTabData;
                subscribedTabData.total = count;
                subscribedTabData.rows = rows || [];
                subscribedTabData.loaded = true
                this.setState({
                    subscribedTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }

    getMyCourseOrders = (page) => {
        courseReq.getMyCourseOrders(page - 1, this.state.orderTabData.perPage)
            .then(res => {
                let rows = res.rows;
                let count = res.count;
                let orderTabData = this.state.orderTabData;
                orderTabData.total = count;
                orderTabData.rows = rows || [];
                orderTabData.loaded = true
                this.setState({
                    orderTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }

    getMyMessages = (page) => {
        let messageTabData = this.state.messageTabData;
        messageTabData.page = page;
        this.setState({
            messageTabData
        })
        accountReq.getMyMessages(null, page - 1, this.state.messageTabData.perPage)
            .then(res => {
                let rows = res.rows;
                let count = res.count;
                let unreadMessageIds = [];
                let shotCommentIds = [];
                let courseCommentIds = [];
                rows.forEach((item, idx) => {
                    item.body = JSON.parse(item.body);
                    item.rowIndex = idx;
                    if (item.noticeType === 'shot-comment' || item.noticeType === 'shot-comment-reply') {
                        if (item.body.commentInfo) {
                            shotCommentIds.push(item.body.commentInfo.id);
                        }
                    }
                    if (item.noticeType === 'course-comment' || item.noticeType === 'course-comment-reply') {
                        if (item.body.commentInfo) {
                            courseCommentIds.push(item.body.commentInfo.id);
                        }
                    }
                    if (!item.hasRead) {
                        unreadMessageIds.push(item.id);
                    }
                })

                if (shotCommentIds.length) {
                    // 获取评论的点赞数
                    shotReq.getCommentLikeCount(shotCommentIds)
                        .then(likeResult => {
                            let messageTabData = this.state.messageTabData;
                            let rows = messageTabData.rows;
                            for (let likeItem of likeResult) {
                                let msgIndex = rows.findIndex(item => !!((item.noticeType === 'shot-comment' || item.noticeType === 'shot-comment-reply') && item.body && item.body.commentInfo && item.body.commentInfo.id === likeItem.id));
                                if (msgIndex >= 0) {
                                    rows[msgIndex].body.commentInfo.likeNum = likeItem.count;
                                }

                            }
                            this.setState({
                                messageTabData
                            })
                        })
                    // 检查是否点赞
                    shotReq.checkIfLikeComments(shotCommentIds)
                        .then(likeResult => {
                            let messageTabData = this.state.messageTabData;
                            let rows = messageTabData.rows;
                            for (let commentId in likeResult) {
                                let msgIndex = rows.findIndex(item => !!((item.noticeType === 'shot-comment' || item.noticeType === 'shot-comment-reply') && item.body && item.body.commentInfo && item.body.commentInfo.id == commentId));
                                if (msgIndex >= 0) {
                                    rows[msgIndex].body.commentInfo.isLike = !!likeResult[commentId];
                                }
                            }
                            this.setState({
                                messageTabData
                            })
                        })
                }

                if (courseCommentIds.length) {
                    // 获取评论的点赞数
                    courseReq.getCommentLikeCount(courseCommentIds)
                        .then(likeResult => {
                            let messageTabData = this.state.messageTabData;
                            let rows = messageTabData.rows;
                            for (let likeItem of likeResult) {
                                let msgIndex = rows.findIndex(item => !!((item.noticeType === 'course-comment' || item.noticeType === 'course-comment-reply') && item.body && item.body.commentInfo && item.body.commentInfo.id === likeItem.id));
                                if (msgIndex >= 0) {
                                    rows[msgIndex].body.commentInfo.likeNum = likeItem.count;
                                }
                            }
                            this.setState({
                                messageTabData
                            })
                        })
                    // 检查是否点赞
                    courseReq.checkIfLikeComments(courseCommentIds)
                        .then(likeResult => {
                            let messageTabData = this.state.messageTabData;
                            let rows = messageTabData.rows;
                            for (let commentId in likeResult) {
                                let msgIndex = rows.findIndex(item => !!((item.noticeType === 'course-comment' || item.noticeType === 'course-comment-reply') && item.body && item.body.commentInfo && item.body.commentInfo.id == commentId));
                                if (msgIndex >= 0) {
                                    rows[msgIndex].body.commentInfo.isLike = !!likeResult[commentId];
                                }
                            }
                            this.setState({
                                messageTabData
                            })
                        })
                }
                if (unreadMessageIds.length) {
                    accountReq.flagMessageRead(unreadMessageIds).then(res => {

                    });
                    this.props.setUnreadMessagesData({
                        rows: [],
                        total: 0,
                    })

                }
                let messageTabData = this.state.messageTabData;
                messageTabData.total = count;
                messageTabData.rows = rows || [];
                messageTabData.loaded = true
                this.setState({
                    messageTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })

    }

    getPurchasedCourses = (page) => {
        courseReq.getPurchasedCourses(page - 1, this.state.orderTabData.perPage)
            .then(res => {
                let rows = res.rows;
                let count = res.count;
                let purchaseTabData = this.state.purchaseTabData;
                purchaseTabData.total = count;
                purchaseTabData.rows = rows || [];
                purchaseTabData.loaded = true
                this.setState({
                    purchaseTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }

    getMyShots = (page) => {
        shotReq.getMyShots(page - 1, this.state.myShotsTabData.perPage)
            .then(res => {
                let rows = res.rows;
                let count = res.count;
                let myShotsTabData = this.state.myShotsTabData;
                myShotsTabData.total = count;
                myShotsTabData.rows = rows || [];
                myShotsTabData.loaded = true
                this.setState({
                    myShotsTabData
                })
            })
            .catch(() => {
                message.error(`加载失败`)
            })
    }

    collectionTypeTabOnChange = (e) => {
        let val = e.target.value;
        if (val === 'course') {
            if (!this.state.collectionCourseTabData.rows.length) {
                this.getMyCollectionCourse(1);
            }
        }

        if (val === 'shot') {
            if (!this.state.collectionShotTabData.rows.length) {
                this.getMyCollectionShots(1);
            }
        }
        this.setState({
            currentCollectionTab: val
        })

    }

    handlerCommentMessageLike = (noticeMessage) => {
        let comment = noticeMessage.body.commentInfo;
        if (noticeMessage.noticeType === 'audio-comment' || noticeMessage.noticeType === 'audio-comment-reply') {
            if (comment.isLike) {
                audioReq.unlikeComment(comment.id).then(res => {
                    let messageTabData = this.state.messageTabData;
                    let comment = messageTabData.rows[noticeMessage.rowIndex].body.commentInfo;
                    noticeMessage.rows[noticeMessage.rowIndex].body.commentInfo.isLike = false
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.likeNum = parseInt(comment.likeNum || 0)
                        ? parseInt(comment.likeNum) - 1
                        : 0;
                    this.setState({
                        messageTabData
                    });
                })
                    .catch(error =>{
                        if( error && error.code === 7140){
                            message.error(`评论已被删除，不能点赞`)
                        }else {
                            message.error(`操作失败，请重试`)
                        }
                    })
                ;
            } else {
                audioReq.likeComment(comment.id).then(res => {
                    let messageTabData = this.state.messageTabData;
                    let comment = messageTabData.rows[noticeMessage.rowIndex].body.commentInfo;
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.isLike = true
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.likeNum = parseInt(comment.likeNum || 0) + 1
                    this.setState({
                        messageTabData
                    });
                }) .catch(error =>{

                    if( error && error.code === 7140){
                        message.error(`评论已被删除，不能点赞`)
                    }else {
                        message.error(`操作失败，请重试`)
                    }
                });
            }
        } else if (noticeMessage.noticeType === 'shot-comment' || noticeMessage.noticeType === 'shot-comment-reply') {
            if (comment.isLike) {
                shotReq.unlikeComment(comment.id).then(res => {
                    let messageTabData = this.state.messageTabData;
                    let comment = messageTabData.rows[noticeMessage.rowIndex].body.commentInfo;
                    noticeMessage.rows[noticeMessage.rowIndex].body.commentInfo.isLike = false
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.likeNum = parseInt(comment.likeNum || 0)
                        ? parseInt(comment.likeNum) - 1
                        : 0;
                    this.setState({
                        messageTabData
                    });
                })
                    .catch(error =>{
                        if( error && error.code === 7140){
                            message.error(`评论已被删除，不能点赞`)
                        }else {
                            message.error(`操作失败，请重试`)
                        }
                    })
                ;
            } else {
                shotReq.likeComment(comment.id).then(res => {
                    let messageTabData = this.state.messageTabData;
                    let comment = messageTabData.rows[noticeMessage.rowIndex].body.commentInfo;
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.isLike = true
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.likeNum = parseInt(comment.likeNum || 0) + 1
                    this.setState({
                        messageTabData
                    });
                }) .catch(error =>{

                    if( error && error.code === 7140){
                        message.error(`评论已被删除，不能点赞`)
                    }else {
                        message.error(`操作失败，请重试`)
                    }
                });
            }
        } else {
            if (comment.isLike) {
                courseReq.unlikeComment(comment.id).then(res => {
                    let messageTabData = this.state.messageTabData;
                    let comment = messageTabData.rows[noticeMessage.rowIndex].body.commentInfo;
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.isLike = false
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.likeNum = parseInt(comment.likeNum || 0)
                        ? parseInt(comment.likeNum || 0) - 1
                        : 0;
                    this.setState({
                        messageTabData
                    })
                })
                    .catch(error =>{
                        if( error && error.code === 7140){
                            message.error(`评论已被删除，不能点赞`)
                        }else {
                            message.error(`操作失败，请重试`)
                        }
                    });
            } else {
                courseReq.likeComment(comment.id).then(res => {
                    let messageTabData = this.state.messageTabData;
                    let comment = messageTabData.rows[noticeMessage.rowIndex].body.commentInfo;
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.isLike = true
                    messageTabData.rows[noticeMessage.rowIndex].body.commentInfo.likeNum = parseInt(comment.likeNum || 0) + 1
                    this.setState({
                        messageTabData
                    });
                }) .catch(error =>{
                    if( error && error.code === 7140){
                        message.error(`评论已被删除，不能点赞`)
                    }else {
                        message.error(`操作失败，请重试`)
                    }
                });
            }
        }

    }

    messageOnSubmitCommentReply = (commentContent,messageInfo,textAreaTarget,messageTarget)=>{
        let commentInfo = messageInfo.body.commentInfo || {};
        let shotInfo = messageInfo.body.shotInfo || {};
        let audioInfo = messageInfo.body.audioInfo || {};
        let courseInfo = messageInfo.body.courseInfo || {}
        let fromUser = messageInfo.body.fromUser || {}

        if (messageInfo.noticeType === 'audio-comment' || messageInfo.noticeType === 'audio-comment-reply') {

            audioReq
                .postComment({
                    audioId: audioInfo.id,
                    content: `@${fromUser.nickname || fromUser.username} ${commentContent}`,
                    replyTargetId:commentInfo.id
                })
                .then(res => {
                    textAreaTarget.clearContent();
                    messageTarget.hideCommentTextarea();
                    message.success(`回复成功`)
                })
                .catch(error => {
                    if( error && error.code === 7140){
                        message.error(`评论已被删除，不能回复`)
                    }else {
                        message.error(`回复失败，请重试`)
                    }
                });
        }else if (messageInfo.noticeType === 'shot-comment' || messageInfo.noticeType === 'shot-comment-reply') {

            shotReq
                .postComment({
                    shotId: shotInfo.id,
                    content: `@${fromUser.nickname || fromUser.username} ${commentContent}`,
                    replyTargetId:commentInfo.id
                })
                .then(res => {
                    textAreaTarget.clearContent();
                    messageTarget.hideCommentTextarea();
                    message.success(`回复成功`)
                })
                .catch(error => {
                    if( error && error.code === 7140){
                        message.error(`评论已被删除，不能回复`)
                    }else {
                        message.error(`回复失败，请重试`)
                    }
                });
        }else {

            courseReq
                .postComment({
                    courseId: courseInfo.id,
                    content: `@${fromUser.nickname || fromUser.username} ${commentContent}`,
                    replyTargetId:commentInfo.id
                })
                .then(res => {
                    textAreaTarget.clearContent();
                    messageTarget.hideCommentTextarea();
                    message.success(`回复成功`)
                })
                .catch(error => {
                    if( error && error.code === 7140){
                        message.error(`评论已被删除，不能回复`)
                    }else {
                        message.error(`回复失败，请重试`)
                    }
                });
        }
    }

    render() {
        let {showUpload, loginUserInfo, showNicknameEdit, recentTabData, nicknameInfo, subscribedTabData, messageTabData, orderTabData, purchaseTabData, myShotsTabData, currentCollectionTab, collectionCourseTabData, collectionShotTabData, currentTab} = this.state;
        loginUserInfo = loginUserInfo || {};

        return (
            <div className="account">
                <Nav/>
                <div className="account-panel">
                    <div className="account-top">
                        <div className="account-info">
                            <Upload className={'uploader'}
                                    accept={'image/jpeg,image/png,image/jpg,image/gif'}
                                    showUploadList={false}
                                    beforeUpload={this.previewUploaderBeforeUpload}
                                    disabled={this.state.uploading}
                            >
                                <div className="avatar" onMouseOver={() => {
                                    this.setState({
                                        showUpload: true
                                    })
                                }} onMouseLeave={() => {
                                    this.setState({
                                        showUpload: false
                                    })
                                }}>
                                    <div className={`hover-box`} hidden={!showUpload}>
                                        <IconFont style={{color: 'white', fontSize: 30}} type={'iconxiangji1'}/>
                                    </div>
                                    <img src={loginUserInfo.avatar} alt=""/>
                                </div>
                            </Upload>
                            <div className="name-panel">
                                <div className="nickname" onClick={() => {
                                    this.setState({
                                        showNicknameEdit: true
                                    })
                                }}><label>{loginUserInfo.nickname || loginUserInfo.username}</label><IconFont
                                    className={`edit-icon`} type={'iconbianji'}/></div>
                                <div className="label">设计师</div>
                            </div>
                        </div>
                        <a href="http://account.uigreat.com/usercenter" target="_blank">
                            <div className="account-safe">
                                <img src="/icons/safe.png" alt="" width="15"/>
                                <div className="account-txt">账户安全</div>
                            </div>
                        </a>

                    </div>
                    <div className="account-content">
                        <Tabs activeKey={currentTab} onChange={this.tabOnChange}>
                            <TabPane tab="最近学习" key="recent" className={`tab-pane`}>
                                <OvalLoading show={!recentTabData.loaded}/>
                                {
                                    recentTabData.rows.map((item, index) => {
                                        return <AccountCourseListCard type="recent" data={item} key={index}
                                                                      goToCoursePage={(courseId) => {
                                                                          let href = '/course/' + courseId;
                                                                          this.props.history.push(href);
                                                                      }}/>
                                    })
                                }

                                <ListEmpty text={'最近有些懒哦~'} show={recentTabData.loaded && !recentTabData.rows.length}/>
                                <Pagination defaultCurrent={1} total={recentTabData.total}
                                            pageSize={recentTabData.perPage} onChange={this.recentTabDataOnChangePage}
                                            className={`tab-list-pagination`} hideOnSinglePage={true}
                                            hidden={recentTabData.loaded && !recentTabData.rows.length}/>
                            </TabPane>
                            <TabPane tab="已购课程" key="purchased">
                                <OvalLoading show={!purchaseTabData.loaded}/>
                                {
                                    purchaseTabData.rows.map((item, index) => {
                                        return <AccountCourseListCard type="purchased" data={item} key={index}
                                                                      goToCoursePage={courseId => {
                                                                          this.props.history.push('/course/' + courseId)
                                                                      }}/>
                                    })
                                }
                                <ListEmpty text={'暂无已购课程'}
                                           show={purchaseTabData.loaded && !purchaseTabData.rows.length}/>
                                <Pagination defaultCurrent={1} total={purchaseTabData.total}
                                            pageSize={purchaseTabData.perPage}
                                            onChange={this.purchasedTabDataOnChangePage}
                                            className={`tab-list-pagination`} hideOnSinglePage={true}
                                            hidden={recentTabData.loaded && !purchaseTabData.rows.length}/>

                            </TabPane>
                            <TabPane tab="我的订阅" key="subscribed">
                                <AllCourseList courses={subscribedTabData.rows} showSkeleton={!subscribedTabData.loaded} colors={this.state.imageAveColor}/>
                                <ListEmpty text={'你没有订阅任何老师的课程哦~~'}
                                           show={subscribedTabData.loaded && !subscribedTabData.rows.length}/>
                                <Pagination defaultCurrent={1} total={subscribedTabData.total}
                                            pageSize={subscribedTabData.perPage}
                                            onChange={this.subscribedTabDataOnChangePage}
                                            className={`tab-list-pagination`} hideOnSinglePage={true}
                                            hidden={subscribedTabData.loaded && !subscribedTabData.rows.length}/>
                            </TabPane>
                            <TabPane tab="我的收藏" key="collection">

                                <Radio.Group defaultValue="course" value={currentCollectionTab}
                                             onChange={this.collectionTypeTabOnChange}>
                                    <Radio.Button value="course">课程</Radio.Button>
                                    <Divider type="vertical"/>
                                    <Radio.Button value="shot">作品</Radio.Button>
                                </Radio.Group>
                                <div className={`list`} hidden={currentCollectionTab !== 'course'}>
                                    {/*<OvalLoading show={!collectionCourseTabData.loaded}/>*/}
                                    <AllCourseList courses={collectionCourseTabData.rows} showSkeleton={!collectionCourseTabData.loaded} colors={this.state.imageAveColor}/>
                                    <ListEmpty text={'你的收藏空空如也~~'}
                                               show={collectionCourseTabData.loaded && !collectionCourseTabData.rows.length}/>
                                    <Pagination defaultCurrent={1} total={collectionCourseTabData.total}
                                                pageSize={collectionCourseTabData.perPage}
                                                onChange={this.collectionCourseTabDataOnChangePage}
                                                className={`tab-list-pagination`} hideOnSinglePage={true}
                                                hidden={collectionCourseTabData.loaded && !collectionCourseTabData.rows.length}/>

                                </div>
                                <div className={`list`} hidden={currentCollectionTab !== 'shot'}>
                                    {/*<OvalLoading show={!collectionShotTabData.loaded}/>*/}
                                    <ShotList shots={collectionShotTabData.rows} showSkeleton={!collectionShotTabData.loaded} colors={this.state.imageAveColor}/>
                                    <ListEmpty text={'你的收藏空空如也~~'}
                                               show={collectionShotTabData.loaded && !collectionShotTabData.rows.length}/>
                                    <Pagination defaultCurrent={1} total={collectionShotTabData.total}
                                                pageSize={collectionShotTabData.perPage}
                                                onChange={this.collectionShotTabDataOnChangePage}
                                                className={`tab-list-pagination`} hideOnSinglePage={true}
                                                hidden={collectionShotTabData.loaded && !collectionShotTabData.rows.length}/>

                                </div>


                            </TabPane>


                            <TabPane tab="我的作品" key="shots">
                                <OvalLoading show={!myShotsTabData.loaded}/>
                                <ShotList shots={myShotsTabData.rows} colors={this.state.imageAveColor}/>
                                <ListEmpty text={'你还没有发布任何作品哦~~'}
                                           show={myShotsTabData.loaded && !myShotsTabData.rows.length}/>
                                <Pagination defaultCurrent={1} total={myShotsTabData.total}
                                            pageSize={myShotsTabData.perPage} onChange={this.myShotsTabDataOnChangePage}
                                            className={`tab-list-pagination`} hideOnSinglePage={true}
                                            hidden={myShotsTabData.loaded && !myShotsTabData.rows.length}/>
                            </TabPane>
                            <TabPane tab="|" key={'divider'} disabled>
                            </TabPane>
                            <TabPane tab="我的消息" key="message">
                                <OvalLoading show={!messageTabData.loaded}/>
                                <div className={`message-list`}>
                                    {
                                        messageTabData.rows.map((item, index) => {
                                            return <MessageListItem data={item} key={item.id}
                                                                    handlerCommentMessageLike={this.handlerCommentMessageLike}
                                                                    onChangeShowCommentTextarea={(isShow,messageTarget) => {
                                                                        let messageOnShowCommentArea = this.state.messageOnShowCommentArea;
                                                                        if(isShow){
                                                                            if(messageOnShowCommentArea){
                                                                                messageOnShowCommentArea.hideCommentTextarea()
                                                                            }
                                                                            this.setState({
                                                                                messageOnShowCommentArea:messageTarget
                                                                            })
                                                                        }else {
                                                                            this.setState({
                                                                                messageOnShowCommentArea:null
                                                                            })
                                                                        }
                                                                    }} onSubmitCommentReply={this.messageOnSubmitCommentReply}/>
                                        })
                                    }
                                </div>
                                <ListEmpty text={'暂无消息'} show={messageTabData.loaded && !messageTabData.rows.length}/>
                                <Pagination defaultCurrent={1} total={messageTabData.total}
                                            pageSize={messageTabData.perPage} onChange={this.messageTabDataOnChangePage}
                                            className={`tab-list-pagination`} hideOnSinglePage={true}
                                            hidden={messageTabData.loaded && !messageTabData.rows.length}/>
                            </TabPane>
                            <TabPane tab="我的订单" key="order">
                                <div hidden={orderTabData.loaded && (!orderTabData.rows || !orderTabData.rows.length)}>

                                    <Table scroll={{x: 932}} columns={columns} size="default"
                                           dataSource={orderTabData.rows} pagination={false} rowKey={(item) => {
                                        return item.orderNum;
                                    }}/>
                                </div>

                                <ListEmpty text={'暂无订单'} show={orderTabData.loaded && !orderTabData.rows.length}/>
                                <Pagination defaultCurrent={1} total={orderTabData.total} size={orderTabData.perPage}
                                            onChange={this.orderTabDataOnChangePage} className={`tab-list-pagination`}
                                            hideOnSinglePage={true}
                                            hidden={orderTabData.loaded && !orderTabData.rows.length}/>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Modal className={'edit-nickname-modal'} visible={showNicknameEdit} centered={true} footer={null}
                       title={null} onCancel={() => {
                    this.setState({
                        showNicknameEdit: false
                    })
                }}>
                    <div className={`edit-nickname`} hidden={!showNicknameEdit}>
                        <h4>修改昵称</h4>
                        <div className={'input-box'}>
                            <Input defaultValue={loginUserInfo.nickname} onChange={this.nickNameInputOnChange}
                                   ref={this.nicknameInputRef}/>
                            {/*<Spin indicator={ <IconFont type={nicknameInfo.status === 'ing' ? ` iconloading`:nicknameInfo.status === 'true' ? "icontrue": nicknameInfo.status === 'false' ? "iconfalse-":""} className={`input-status`}/>} spinning={true} hidden={nicknameInfo.status !== 'ing' && nicknameInfo.status !== 'false' && nicknameInfo.status !== 'true'} style={{marginLeft:15,color:nicknameInfo.status === 'true' ? "green":nicknameInfo.status === 'false' ? "red":""}}/>*/}
                        </div>
                        <div className={'valid-tip'}
                             style={{color: nicknameInfo.status === 'true' ? "green" : nicknameInfo.status === 'false' ? 'red' : ""}}>
                            <IconFont
                                type={nicknameInfo.status === 'true' ? "icontrue" : nicknameInfo.status === 'false' ? "iconfalse-" : ""}
                                hidden={nicknameInfo.status !== 'false' && nicknameInfo.status !== 'true'}/>
                            <div className={`tip-text`}
                                 hidden={nicknameInfo.status !== 'false' && nicknameInfo.status !== 'true'}>{nicknameInfo.status === 'true' ? "昵称可以使用" : nicknameInfo.status === 'false' ? "昵称已被占用" : ""}</div>
                        </div>


                        <div className={'footer-btns'}>

                            <Button className={`edit-btn`} onClick={() => {
                                this.setState({
                                    showNicknameEdit: false
                                })
                            }}>取消</Button>

                            <Button className={`edit-btn`} type={'primary'} onClick={this.changeNickname}
                                    disabled={nicknameInfo.nickname === loginUserInfo.nickname || !nicknameInfo.nickname || nicknameInfo.status !== 'true'}>修改</Button>

                        </div>

                    </div>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        isLogin: state.account.isLogin,
        loginUserInfo: state.account.loginUserInfo
    });
};
const mapDispatchToProps = {
    addLoginUserInfo: actions.accountAddLoginUserInfo,
    setUnreadMessagesData: actions.accountSetUnreadMessagesData
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
