import AppRequest from './AppRequest'
import * as qiniu from "qiniu-js";
const uuidv4 = require('uuid/v4');
const  urlGetFileUploadToken = '/api_v1/public/file/upload/token';
const urlGetFooterFriendLinks = '/api_v1/navigation/friend/link/list';
const urlGetFooterNavGroups = "/api_v1/navigation/list";
const urlGetLatestBulletin = "/api_v1/bulletin/message"


class IndexRequest extends AppRequest {
    getWeixinShareDebugStatus(){
        return this.get("/api_v1/development/check/share/debug")
    }
    getWeixinShareSign(url){
        return this.post('/api_v1/public/weixin-share/sign',{url})
    }
    submitHealthSign(info){
        return this.post('/api/user/research/create',info)
    }

    getMemberInfo(cid){
        return this.get("/api/user/search/cid",{cid})
    }
    getQiniuImageAveColor(imageURL){
        if(!imageURL){
               return Promise.reject({
                code:400,
                message:"imageURL is required"
            })
        }
        imageURL = imageURL.replace(/http:\/\//g,"//");
        return this.baseGet(imageURL + "?imageAve")
            .then(res =>{
                if(res.status < 400 && res.data){
                    try {
                        let RGB = JSON.parse(res.data).RGB.replace(/0x/g,'#');
                        return  Promise.resolve(RGB);
                    }catch(e){
                        return Promise.reject({
                            code:400,
                            message:"qiniu request error"
                        })
                    }
                }else {
                    return Promise.reject({
                        code:400,
                        message:"qiniu request error"
                    })
                }
            })
    }

    getFileUploadToken(){
        return this.get(urlGetFileUploadToken);
    }

    uploadFileToQiniu(file,fileName,callback){
        fileName = fileName || '';
        callback = callback || {};
        this.getFileUploadToken()
            .then(info => {

                const successFn = (response) => {
                
                    if(callback.success && typeof callback.success ==='function'){
                        callback.success(`${info.host}${response.key}`);
                    }
                };
                let paths = fileName.split('.');
                let key = null
                if (paths.length > 1) {
                    key = uuidv4() + "." + paths[paths.length - 1];
                }
                let observable = qiniu.upload(file, key, info.uploadToken, null, null);
                observable.subscribe(callback.progress, callback.error, successFn);
            })
            .catch(error => {
                if(callback.error && typeof callback.error ==='function'){
                    callback.error(error);
                }
            })
    }
    getFooterFriendLinks(){
        return this.get(urlGetFooterFriendLinks,{type:"link"})
    }

    getFooterNavGroups(){
        return this.get(urlGetFooterNavGroups)
    }

    getLatestBulletin(){
        return this.get(urlGetLatestBulletin)
    }

    submitDiseasePromise(info){
        return this.post("/api/disease/info",info)
    }
}

const  indexReq = new IndexRequest();
export default indexReq