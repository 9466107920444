import AppRequest from './AppRequest'

const urlGetAudioSeriesDetail = "/api_v1/audio/series/detail";
const urlGetAudioDetail = "/api_v1/audio/detail";
const urlLikeComment = '/api_v1/comment/like';
const urlUnlikeComment = '/api_v1/comment/delete/like'
const urlPostComment = '/api_v1/comment/post';
class AudioReq extends AppRequest {


    getAudioSeriesDetail(audioSeriesId){
        return this.get(urlGetAudioSeriesDetail,{audioSeriesId})
    }

    getAudioDetail(audioId){
        return this.get(urlGetAudioDetail,{id:audioId});
    }

    checkIfLikeAudio(audioId){
        return this.get("/api_v1/audio/check/like",{audioId});
    }


    getAudioCommentList(audioId, perPage, page){
        return this.get("/api_v1/comment/list",{audioId,perPage,page,type:"audio"});
    }

    postComment(info){
        return this.post(urlPostComment,{...info,type:'audio'});
    }
  
    deleteComment(commentId){
        return this.delete("/api_v1/comment/delete",{type:"audio",commentId})
    }
    checkIfLikeComments(commentIds){
        return this.get("/api_v1/comment/istrue",{commentId:commentIds,type:'audio'})
    }
    likeComment(commentId){
        return this.post(urlLikeComment,{commentId,type:"audio"});
    }
    unlikeComment(commentId){
        return this.delete(urlUnlikeComment,{commentId,type:"audio"});
    }

    handlerLikeAudio(audioId,isLike){
        return this.post("/api_v1/audio/like",{audioId,status:isLike});
    }
    searchAudioWithTag(tag,page,perPage){
        return this.get("/api_v1/audio/tag/list",{tag,page :page  || 0,perPage:perPage || 10})
    }

    getAudioListOfSeriesId(audioSeriesId,perPage,page){
        return this.get("/api_v1/audio/series/audio/list",{audioSeriesId,perPage,page})
    }

    incrementPlayNum(audioId){
        return this.get("/api_v1/audio/play/increase",{audioId})
    }
    getOnlineCount(audioId){
        return this.get("/api_v1/audio/get-online",{audioId})
    }

    queryAudioGroupOnlineCount(audioIds){
        return this.post("/api_v1/audio/onlineCountGroups",{audioIds})
    }

}

const  audioReq = new AudioReq();
export default audioReq
