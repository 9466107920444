// 添加登录用户的信息
const ACCOUNT_ADD_LOGIN_USER_INFO = 'ACCOUNT_ADD_LOGIN_USER_INFO' ;
// 移除登录用户的信息
const ACCOUNT_REMOVE_LOGIN_USER_INFO = 'ACCOUNT_REMOVE_LOGIN_USER_INFO';
// 标记登录或注销
const ACCOUNT_FLAG_LOGIN_STATUS = 'ACCOUNT_FLAG_LOGIN';

// 显示/隐藏登录注册页面
const ACCOUNT_SHOW_LOGIN_REGISTER_MODAL = 'ACCOUNT_SHOW_LOGIN_REGISTER_MODAL';
// 账户未读消息
const ACCOUNT_UNREAD_MESSAGES = 'ACCOUNT_UNREAD_MESSAGES';
// 账户能否有权进行评论置顶操作
const ACCOUNT_CAN_HANDLER_TOP_COMMENT = "ACCOUNT_CAN_HANDLER_TOP_COMMENT"
export default {
    ACCOUNT_ADD_LOGIN_USER_INFO,
    ACCOUNT_REMOVE_LOGIN_USER_INFO,
    ACCOUNT_FLAG_LOGIN_STATUS,
    ACCOUNT_SHOW_LOGIN_REGISTER_MODAL,
    ACCOUNT_UNREAD_MESSAGES,
    ACCOUNT_CAN_HANDLER_TOP_COMMENT
}