
import React from "react";
import './cardHeader.scss'
class CardHeader extends  React.Component{

    constructor(props) {
        super(props);
        Object.assign(this.state,props);
    }
    state = {

    };
    static  getDerivedStateFromProps(nextProps,prevState){

        return nextProps;
    }

    render() {
        return (
            <div className={`comp-card-header ${ this.state.className || ""}`} style={this.state.style || {}}>
                <div className={`comp-dotted`}>
                    <div className={'dotted1'}/>
                    <div className={'dotted2'}/>
                </div>
                <div className={'title'}>{this.state.title || ""}</div>
            </div>
        )
    }

}


export default CardHeader;