
import systemTypes  from '../actionTypes/systemTypes'
const  {  SYSTEM_SET_WINDOW_SIZE,SYSTEM_SET_INPUT, SYSTEM_BULLETIN} = systemTypes;
function systemSetWindowSize(size) {
    return { type: SYSTEM_SET_WINDOW_SIZE, payload:size }
}

function systemFlagOnInput(isInput) {
    return { type: SYSTEM_SET_INPUT, payload:isInput }
}

function systemShowBulletin(bulletin) {
    return { type: SYSTEM_BULLETIN, payload:bulletin }
}



export  default {
    systemSetWindowSize,
    systemFlagOnInput,
    systemShowBulletin
}