import React from 'react'
import './GanttChart.scss'
import {Row, Col} from 'antd'
import dateformat from 'dateformat'
function getMonthsAndTotalDay(minDateStr, maxDateStr) {
    let minDate = new Date(minDateStr);
    let maxDate = new Date(maxDateStr)
    let months = 0;
    if (maxDate.getFullYear() !== minDate.getFullYear()) {
        months = (maxDate.getFullYear() - minDate.getFullYear()) * 12;
        months = months - minDate.getMonth();
        months = months + maxDate.getMonth() + 1;
    } else {
        months = maxDate.getMonth() - minDate.getMonth() + 1;
    }
    let monthObjs = [];
    let totalDays = 0;
    for (let index = 0; index < months; index++) {
        let minDate = new Date(minDateStr);
        minDate.setMonth(minDate.getMonth() + index)
        let oneDay = minDate.setDate(1);
        let mObj = {
            onDay: new Date(oneDay),
            oneDayST: new Date(oneDay).getTime()

        }
        minDate.setMonth(minDate.getMonth() + 1);
        minDate.setDate(minDate.getDate() - 1);

        mObj.lastDay = minDate;
        mObj.lastDayST = minDate.getTime()
        mObj.days = mObj.lastDay.getDate() - mObj.onDay.getDate() + 1;
        totalDays += mObj.days;
        monthObjs.push(mObj)
    }
    return {months: monthObjs, totalDays}

}

class GanttChart extends React.Component {
    constructor(props) {
        super(props);

    }

    state = {
        rows: [],
        animateRows:[],
        months: [],
        totalDays: 0,
        readyAnimate:false,
        widths:[]


    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.rows || !nextProps.rows.length) {
            return {}

        }

        let newRows = [], minDate, maxDate;
        let animateRows = []
        for (const item of nextProps.rows) {
            if (item.startAt && item.endAt) {
                let startAt = new Date(item.startAt);
                let endAt = new Date(item.endAt);
                minDate = minDate && minDate.getTime() > startAt.getTime() ? startAt : minDate || startAt;
                minDate = minDate && minDate.getTime() > endAt.getTime() ? endAt : minDate || endAt;
                maxDate = maxDate && maxDate.getTime() < endAt.getTime() ? endAt : maxDate || endAt;
                maxDate = maxDate && maxDate.getTime() < startAt.getTime() ? startAt : maxDate || endAt;
                if (startAt.getTime() <= endAt) {
                    item.startAt = startAt;

                    item.endAt = endAt
                } else {
                    item.startAt = endAt;
                    item.endAt = startAt;
                }
                item.startTS = item.startAt.getTime()
                item.endTS = item.endAt.getTime()
                newRows.push(item);
                // animateRows.push({...item,endAt:item.startAt,endTS: item.startTS})
            }
        }

        let monthInfo = getMonthsAndTotalDay(minDate.toString(), maxDate.toString());

        return {
            rows: newRows,
            readyAnimate:true,
            months: monthInfo.months,
            totalDays: monthInfo.totalDays
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if((!this.state.widths || !this.state.widths.length) && this.state.totalDays){
            setTimeout(()=>{
                let widths = [];

                for(let item of this.state.rows){
                    widths.push(    (item.endTS - item.startTS) / 10 / 3600 / 24 / this.state.totalDays)
                }
                this.setState({
                    widths
                })
            },500)

        }
    }

    goAboutWebsite = e=>{
        let className = e.currentTarget.dataset.classname;
        let schoolid = e.currentTarget.dataset.schoolid;
        let href = "http://pxfer.uigreat.com/"
        if(className.indexOf('运营') >=0){
            href = "https://design.uigreat.com/"
        }

        if(schoolid == 2){
            href = "https://www.pxfer.com/"
        }
        if(schoolid == 3){
            href = "http://sz.uigreat.com/"
        }
        if(schoolid == 6){
            href = "http://jn.uigreat.com/"
        }

        window.open(href,"_blank")

    }

    render() {


        let {rows, totalDays, months,animateRows,widths} = this.state;
        return <div className={`comp-gantt-chart`}>
            <Row className={`table-row`}>
                <Col span={4} className={`row-titles`}>
                    <div className={`table-title`}>{this.props.title || ""}</div>
                    {
                        rows.map((item, index) => {
                            return <div className={`title`} key={index}><span>{item.name || ""}</span></div>
                        })
                    }
                </Col>
                <Col span={20} className={`table-grids`}>
                    <div className={`table-item-grid`} style={{minWidth: 50 * months.length}}>
                        {
                            months.map((month, idx) => {
                                return <div key={idx} className={`grid-box table-header-item`}>
                                    <div>{dateformat(month.onDay, month.onDay.getMonth() === 0 ? 'yyyy年m月' : 'm月')}</div>
                                </div>
                            })
                        }
                    </div>

                    {
                        rows.map((item, index) => {
                            let firstMonth = months[0],
                                left = 0,
                                width = 0,
                                fullColor = item.class_color || "#3c81f0",
                                startDateStr = dateformat(item.startAt, 'm月d日'),
                                endDateStr = dateformat(item.endAt, 'm月d日');
                            left = (item.startTS - firstMonth.oneDayST) / 10 / 3600 / 24 / totalDays;
                            // width = (item.endTS - item.startTS) / 10 / 3600 / 24 / totalDays
                            return (
                                <div className={`table-item-grid`}
                                     style={{minWidth: 50 * months.length}} key={index}>
                                    {
                                        months.map((month, idx) => <div key={idx} className={`grid-box`}/>)
                                    }


                                            <div className={`full-bar ${widths[index] ? 'full-bar-animate':''}`} style={{
                                                backgroundColor: fullColor,
                                                left: left + "%",
                                                // width: "0%",
                                                width: widths[index] ? widths[index] + "%":0,
                                            }} onClick={this.goAboutWebsite} data-classname={item.name || ""} data-schoolid={item.school_id}>

                                                <div className={`start-date`}>{startDateStr}</div>
                                                <div className={`start-date end-date`}>{endDateStr}</div>
                                            </div>

                                </div>
                            )
                        })
                    }

                </Col>
            </Row>
        </div>
    }

}

export default GanttChart