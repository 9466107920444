import React from 'react';
import { Row, Col } from 'antd'
import { Link } from "react-router-dom";
import "./toolList.scss"
import courseReq from "../../request/courseReq";
import indexReq from "../../request/indexReq";
import storage from "../../storage/storage";


const  STORAGE_CACHED_KEY_TOOL_LIST = "STORAGE_CACHED_KEY_TOOL_LIST"
class ToolList extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {

        toolGroups: [],
        imageAveColor: {}
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    componentDidMount() {
        let cachedState  = storage.getCached(STORAGE_CACHED_KEY_TOOL_LIST)
        if(cachedState){
            this.setState({
                ...cachedState
            })
        }else {
            this.getToolGroups();
        }

    }
    componentWillUnmount() {
        storage.setCached(STORAGE_CACHED_KEY_TOOL_LIST,this.state);
    }


    getToolGroups = () => {
        courseReq.getToolList()
            .then((res) => {
                if (res && res.length) {
                    for (let group of res) {
                        if (group.toolInfo && group.toolInfo.length) {
                            group.toolInfo.forEach(item => {
                                indexReq.getQiniuImageAveColor(item.preview)
                                    .then(res => {
                                        let colors = this.state.imageAveColor;
                                        colors[item.preview] = res;
                                        this.setState({
                                            imageAveColor: colors
                                        })
                                    })
                            })
                        }
                    }

                }
                this.setState({
                    toolGroups: res || []
                }, () => {
                    // this.listenImg()
                })
            })
    }

    render() {
        let toolGroups = this.state.toolGroups || [];





        let groupsList = toolGroups.map((g, gIndex) => {
            let toolCards = null;
            if (g.toolInfo && g.toolInfo.length) {

                toolCards = g.toolInfo.map((item, index) => {

                    return <Col xs={12} sm={12} md={8} lg={6} xl={4} key={index}>
                        <div className="tool-card">
                            <a href={item.url} target="_blank">
                                <div className="tool-cover">
                                    <div className="tool-card-bg" style={{ backgroundColor: this.state.imageAveColor[item.preview] || "" }} />

                                    <div className="tool-cover-inner">
                                        <img src={item.preview} alt="" />
                                    </div>
                                </div>
                            </a>
                            <div className="tool-text-panel">
                                <div className="tool-title">{item.name}</div>
                                {/* <div className="tool-des">UI 设计利器，加速移动设计</div> */}
                            </div>
                        </div>
                    </Col>
                });
            }

            return <div key={gIndex}>
                <div className="tool-main-title">{g.name}</div>
                <Row gutter={[{ xs: 8, sm: 16 }, { xs: 8, sm: 16 }]}>
                    {toolCards}
                </Row>
            </div>
        })
        return (
            <div className="tool-panel">
                {groupsList}
            </div>
        )
    }
}


export default ToolList;