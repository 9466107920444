import AppRequest from './AppRequest'

const  urlGetBanners = '/api_v1/banner/list'
const urlRecordBannerHit = '/api_v1/banner/hit/banner'
class BannerRequest extends AppRequest {

    getBanners(type){
        return this.get(urlGetBanners,{type});
    }

    recordBannerHit(bannerId){
        return this.get(urlRecordBannerHit,{id:bannerId});
    }
}

const  bannerReq = new BannerRequest();
export default bannerReq
