import React from "react";
import "./skeletonCourseListCard.scss";
import {Avatar} from "antd";

export  default  props =>{
    return (
        <div className={`skeleton-course-list-card`}
        >
            <div className={"card"}>
                <div className={"bks-shot-flex0"}>
                    <div className={"bks-shot-flex1"}>
                        <div
                            className={"bks-shot-flex2"}
                        >
                        </div>
                    </div>
                </div>

                <div className={"control-bar"}>
                    <div className="course-card-top">
                        <label className={"title"} />
                        <div className={"flex-m"}>
                            <div className={"apps"}>

                            </div>
                        </div>
                        <div className="flex-m-b">
                            <div className={"authors"}>
                                <Avatar className={"avatar"} />
                                <label />
                            </div>
                            <div className="course-duration"/>
                        </div>
                    </div>
                    {/*<div className={"flex-b"}>*/}
                    {/*    <div className="price" />*/}
                    {/*    <div className="count" />*/}
                    {/*</div>*/}

                </div>
            </div>
        </div>
    )
}



