import React from "react";
import Layout from "../../layouts/layout/Layout";
import {Carousel, Row, Col, Icon} from "antd";
import "./index.scss";
import CardHeader from "../../components/cardHeader/CardHeader";
import LittleBanner from "../../components/littleBanner/LittleBanner";
import courseReq from "../../request/courseReq";
import AllCourseList from "../../components/allCourseList/AllCourseList";
import ShotList from "../../components/shotsList/ShotList"
import bannerReq from "../../request/bannerReq";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import indexReq from "../../request/indexReq";
import storage from "../../storage/storage";
import shotReq from "../../request/shotReq";
import {Link} from 'react-router-dom'

const STORAGE_CACHED_KEY_INDEX_PAGE_STATE = "STORAGE_CACHED_KEY_INDEX_PAGE_STATE"

class Index extends React.Component {
    constructor() {
        super();
        this.carouselRef = React.createRef();
    }

    state = {
        courses: [],
        banners: [{}],
        littleBanners: [],
        currentBannerIndex: 0,
        apps: [],
        imageAveColor: {},
        shots: [],
        loadingShot: false,
        loadingCourse: false,
        loadingBanner: false
    };

    componentDidMount() {

        let cachedState = storage.getCached(STORAGE_CACHED_KEY_INDEX_PAGE_STATE);
        if (cachedState) {
            this.setState({
                ...cachedState
            })
        } else {
            this.getCourses();
            this.getBanners();
            this.getApps();
            this.getShots()
        }
    }

    getApps = () => {
        courseReq.getAppList().then(res => {
            this.setState({
                apps: res || []
            });
        });
    };

    getShots = () => {
        this.setState({
            loadingShot: true
        })
        shotReq
            .getShotList({perPage: 12, page: 0, isRecommend: true})
            .then(res => {

                // if (res && res.rows && res.rows.length) {
                //     res.rows.forEach(item => {
                //         indexReq.getQiniuImageAveColor(item.preview).then(res => {
                //             let colors = this.state.imageAveColor;
                //             colors[item.preview] = res;
                //             this.setState({
                //                 imageAveColor: colors
                //             });
                //         });
                //     });
                // }
                this.setState({
                    shots: res.rows || [],
                    loadingShot: false
                });
            })
            .catch(error => {

            });
    }

    getBanners() {
        // 主banner
        this.setState({
            loadingBanner: true
        })
        bannerReq.getBanners(`course-home`).then(res => {

            // if (res && res.length) {
            //     res.forEach(item => {
            //         indexReq.getQiniuImageAveColor(item.preview).then(res => {
            //             let colors = this.state.imageAveColor;
            //             colors[item.preview] = res;
            //             this.setState({
            //                 imageAveColor: colors
            //             });
            //         });
            //     });
            // }
            this.setState({
                banners: res || [],
                loadingBanner: false
            });
        });

        bannerReq.getBanners(`course-home-child-banner`).then(res => {
            // if (res && res.length) {
            //     res.forEach(item => {
            //         indexReq.getQiniuImageAveColor(item.preview).then(res => {
            //             let colors = this.state.imageAveColor;
            //             colors[item.preview] = res;
            //             this.setState({
            //                 imageAveColor: colors
            //             });
            //         });
            //     });
            // }
            this.setState({
                littleBanners: res || []
            });
        });
    }

    componentWillUnmount() {
        storage.setCached(STORAGE_CACHED_KEY_INDEX_PAGE_STATE, this.state)
    }

    getCourses = () => {
        this.setState({
            loadingCourse: true
        })
        courseReq
            .getCourseList({perPage: 12})
            .then(res => {


                if (res && res.rows && res.rows.length) {

                    let rows = [...res.rows]
                    this.setState({
                        courses: rows,
                        loadingCourse: false
                    }, () => {
                        console.log(`>>>>>>getCourseList`, res.rows)
                        // rows.forEach(item => {
                        //     indexReq.getQiniuImageAveColor(item.preview)
                        //         .then(res => {
                        //             let colors = this.state.imageAveColor;
                        //             colors[item.preview] = res;
                        //             this.setState({
                        //                 imageAveColor: colors
                        //             });
                        //         })
                        //         .catch(e => {

                        //         })
                        // });
                    });

                }

            })
            .catch(error => {

            });
    }

    softAppItemOnHover = () => {
    };

    softAppItemOnMouseLeave = () => {
    };


    afterChangeBanner = current => {
        this.setState({
            currentBannerIndex: current
        });
    };

    changeBanner = e => {
        this.carouselRef.current.goTo(e.currentTarget.dataset.index);
    };

    render() {
        let {
            courses,
            shots,
            banners,
            currentBannerIndex,
            apps,
            loadingBanner
        } = this.state;
        banners = banners || [];
        apps = apps || [];
        let appList = [];
        for (let index = 0; index < 6 && index < apps.length; index++) {
            let app = apps[index];
            let node = (
                <div
                    className={"app-item"}
                    onMouseEnter={this.softAppItemOnHover}
                    onMouseLeave={this.softAppItemOnMouseLeave}
                    key={index}
                >
                    <div className={"img-box"}>
                        <a title={app.name} href={`/app/${app.id}`} target="_blank">
                            <div className={"img-container"}>
                                <img src={app.iconUrl} alt={""}/>
                                <div className="name lg-hidden">{app.name}</div>
                            </div>
                        </a>
                    </div>
                </div>
            );
            appList.push(node);
        }

        return (
            <Layout rootClassName="index" navType="high">
                {/* <a href="http://old.uigreat.com/" target="_blank" style={{display: "block"}}>
                    <div class="old-web">
                        <img src="https://img.uigreat.com/new/logo.svg" alt=""/>
                        <span>旧版官网</span>
                    </div>
                </a> */}
                <Row gutter={[{xs: 8, sm: 16, md: 16, lg: 16}, 20]}>
                    <Col xs={24} md={18}>
                        <div className={"banner-box"}>
                            <Carousel
                                arrows={false}
                                autoplay={true}
                                autoplaySpeed={4000}
                                draggable={true}
                                dots={false}
                                className={"carousel"}
                                afterChange={this.afterChangeBanner}
                                ref={this.carouselRef}
                            >
                                {banners.map((item, index) => {
                                    return (
                                        <a
                                            key={index}
                                            href={item.url}
                                            target={item.openMethod === "self" ? "" : "_blank"}
                                            title={item.name}
                                        >
                                            <div className={"background-box"}>
                                                <div className="banner-inner-panel">
                                                    <img
                                                        hidden={loadingBanner}
                                                        src={item.preview}
                                                        alt={""}
                                                        style={{
                                                            backgroundColor:
                                                                this.state.imageAveColor[item.preview] ||
                                                                ""
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    );
                                })}
                            </Carousel>
                            <div className={"banner-bottom"}>
                                <CardHeader
                                    title={
                                        banners.length && currentBannerIndex >= 0
                                            ? banners[currentBannerIndex].name
                                            : ""
                                    }
                                />
                                <div className={"carousel-indicator"}>
                                    {banners.map((item, index) => {
                                        return (
                                            <div
                                                className={`dotted ${
                                                    currentBannerIndex === index ? "select" : ""
                                                }`}
                                                data-index={index}
                                                key={index}
                                                onClick={this.changeBanner}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={0} md={6}>
                        <LittleBanner
                            banners={this.state.littleBanners}
                            colors={this.state.imageAveColor}
                        />
                        <div className={"course-apps"}>
                            <div className={"header"}>
                                <CardHeader title={"软件分类"}/>
                            </div>
                            <div className={"list"}>{appList}</div>
                        </div>
                    </Col>
                </Row>
                <div className={"list-body"}>
                    <div className="list-top">
                        <div className="list-title">最新课程</div>
                        <Link className="list-link" to={"/course"}>更多<Icon type="swap-right"
                                                                           style={{marginLeft: "2px"}}/></Link>
                    </div>
                    <AllCourseList showSkeleton={this.state.loadingCourse} courses={courses}
                                   colors={this.state.imageAveColor}/>
                </div>

                <div className={"list-body"}>
                    <div className="list-top">
                        <div className="list-title">推荐作品</div>
                        <Link className="list-link" to={"/shots"}>更多<Icon type="swap-right"
                                                                          style={{marginLeft: "2px"}}/></Link>
                    </div>
                    <ShotList showSkeleton={this.state.loadingShot} shots={shots} colors={this.state.imageAveColor}/>
                </div>

            </Layout>

        );
    }
}

export default Index;
