import React from 'react';
import ReactDOM from 'react-dom';
import {ConfigProvider} from 'antd'
import {Provider} from 'react-redux'
import './index.css';
import './antDesignStyleImport'
import App from './App';
import * as serviceWorker from './serviceWorker';
import {CookiesProvider} from 'react-cookie'
import store from './redux/store'
import zhCN from 'antd/es/locale/zh_CN';

ReactDOM.render(
    <Provider store={store}>

        <ConfigProvider locale={zhCN}>
            <CookiesProvider>
                <App/>
            </CookiesProvider>
        </ConfigProvider>

    </Provider>
    , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
