import React from 'react';
import Nav from '../../components/nav/Nav'
import './appCourse.scss'
import AllCourseList from "../../components/allCourseList/AllCourseList";
import ListEmpty from "../../components/listEmpty/ListEmpty";
import courseReq from "../../request/courseReq";
import ListFooter from "../listFooter/ListFooter";

import utilFunc from "../../utils/util";
import storage from "../../storage/storage";


const loadingStatus = {
    init: "init",
    firstLoading: "first-loading",
    loadingMore: 'loading-more',
    firstError: "first-error",
    moreError: "more-error"

}

const PerPage = 30;
const STORAGE_CACHED_KEY_APP_COURSES = "STORAGE_CACHED_KEY_APP_COURSES"
class AppCourse extends React.Component {
    constructor(props) {
        super(props);


    }

    state = {
        courses:[],
        loadingCourseListStatus:"init",
        courseListNoMore:false,
        apps:[]

    };

    // /* life circle method */
    // static getDerivedStateFromProps(nextProps, prevState) {
    //
    //     return nextProps;
    // }

    componentDidMount() {
        utilFunc.setDocumentInfo({
            title:"课程 | 优阁网",
            description:"课程",
            keywords:`课程,软件,优阁,设计`
        })
        let cachedState = storage.getCached(STORAGE_CACHED_KEY_APP_COURSES);
        if (cachedState) {
            this.setState({
                ...cachedState
            })
        } else {
            this.getApps();
            this.setState({
                appId:this.props.match.params.appId
            },()=>{
                this.getCourses();
            })
        }



    }

    componentWillUnmount() {
        storage.setCached(STORAGE_CACHED_KEY_APP_COURSES, this.state)
    }

    getApps = ()=>{
        courseReq.getAppList()
            .then((res)=>{
                this.setState({
                    apps:res || []
                })
            })
    }

    courseListPage = -1;
    getCourses = ()=> {

        let that = this;
        if(this.state.loadingCourseListStatus == loadingStatus.firstLoading || this.state.loadingCourseListStatus == loadingStatus.loadingMore){
            return;
        }
        let condition = function () {

            let body = {
                perPage: PerPage,
                page: that.courseListPage + 1,
                softWareId:that.state.appId
            }

            return body;
        }();
        let delayLoadingTimer = null
        if (condition.page < 1) {
            delayLoadingTimer = setTimeout(()=>{
                this.setState({
                    loadingCourseListStatus: loadingStatus.firstLoading,
                    courses: []
                })
            },200)

        } else {
            this.setState({
                loadingCourseListStatus: loadingStatus.loadingMore
            })
        }
        courseReq.getCourseList(condition)
            .then(res => {
                if(delayLoadingTimer){
                    clearTimeout(delayLoadingTimer)
                }
                let moreCourses = res.rows;
                let count = res.count;
                let noMore = false;
                if (moreCourses.length < condition.perPage) {
                    noMore = true;
                }
                let courses = this.state.courses;
                if (condition.page < 1) {
                    courses = [];
                }
                this.courseListPage += 1;
                this.setState({
                    loadingCourseListStatus: loadingStatus.init, courseListNoMore: noMore,
                    courses: courses.concat(moreCourses),
                    courseTotalCount: count

                })


            })
            .catch(error => {
                if (condition.page < 1) {
                    this.setState({
                        loadingCourseListStatus: loadingStatus.firstError
                    })
                } else {
                    this.setState({
                        loadingCourseListStatus: loadingStatus.moreError
                    })
                }

            })


    }

    onChangeApp = e=>{
        let id = e.currentTarget.dataset.id;
        if(id != this.state.appId){
            this.setState({
                appId:id
            },()=>{
                this.courseListPage = -1;
                this.getCourses()
            })
            this.props.history.replace(`/app/${id}`)
        }
    }


    render() {

        let { courses, loadingCourseListStatus, courseListNoMore,apps,appId} = this.state;

        apps = apps || [];
        let appList = apps.map((item,index)=>{
            return <div className={`app-item ${appId == item.id ? "selected":""}`} key={index} data-id={item.id} onClick={this.onChangeApp}>
                <img src={item.iconUrl} alt={''} />
                <div>{item.name}</div>
            </div>
        })
        return (
            <div className='app-course-list'>
                <Nav/>
                <div className="course-nav">
                    <div className="wrapper">
                        <div className="course-nav-inner">
                            <div className={'flex-l'}>
                                {appList}
                            </div>

                        </div>
                    </div>
                </div>
                <div className={'index-content'}>
                    {/*<OvalLoading className={'loading'} show={loadingCourseListStatus === loadingStatus.firstLoading} />*/}
                    <AllCourseList showSkeleton={loadingCourseListStatus === loadingStatus.firstLoading}  courses={courses}/>
                    <ListEmpty show={loadingCourseListStatus === loadingStatus.init &&  !courses.length}/>
                    <ListFooter show={ [loadingStatus.loadingMore,loadingStatus.moreError,loadingStatus.init].indexOf(loadingCourseListStatus) >= 0 && courses.length } status={courseListNoMore ? 'no-more':loadingCourseListStatus === loadingStatus.loadingMore ? 'loading-more':"loading-error"} onLoadingMore={this.getCourses}/>
                </div>
            </div>
        )
    }
}



export default AppCourse;
