import React from "react";
import Layout from "../../layouts/layout/Layout"
import "./shots.scss"
import ShotsLayout from "../../layouts/shotsLayout/ShotsLayout";
import actions from "../../redux/actions";
import {connect} from "react-redux";
import {message} from 'antd'
import utilFunc from "../../utils/util";

class Shots extends React.Component {

  state = {

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      isLogin: nextProps.isLogin,
      loginUserInfo: nextProps.loginUserInfo,
    };
  }

  onClickShotPost = () => {
    if (this.state.isLogin) {
      if (this.state.loginUserInfo && this.state.loginUserInfo.userPower && !this.state.loginUserInfo.userPower.canPostShot) {
        message.warning(`当前账号不能发布作品`)
      } else {
        this.props.history.push(`/post/shot/create`)
      }
    } else {
      window.appLogin();
    }
  }

  componentDidMount() {
    utilFunc.setDocumentInfo({
      title:"作品 | 优阁网",
      description:"优阁学员优秀作品",
      keywords:`学员优秀作品`
    })

  }

  componentWillUnmount() {

  }

  render() {
    let {loginUserInfo} = this.state;
    let showRecommend = loginUserInfo && loginUserInfo.roles && (loginUserInfo.roles.admin || loginUserInfo.roles.teacher )
    return (
      <Layout rootClassName="shots">
        <ShotsLayout showRecommend={showRecommend} onClickShotPost={this.onClickShotPost} useCache={true}/>
      </Layout>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  isLogin: state.account.isLogin,
  loginUserInfo: state.account.loginUserInfo,
});
const mapDispatchToProps = {
  handlerShowLoginRegisterModal: actions.accountShowLoginRegisterModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Shots);


