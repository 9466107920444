import React from 'react'
import IconFont from "../iconfont/icon-font";
import "./listEmpty.scss"
class ListEmpty extends React.Component {


    state = {
        show:false
    }

    static  getDerivedStateFromProps(nextProps,prevState){

        return {
            show:!!nextProps.show,
            className:nextProps.className,
            style:nextProps.style,
            text:nextProps.text,
        };
    }

    render () {

        return <div className={`comp-list-empty ${this.state.className || ""}`} style={this.state.style} hidden={!this.state.show} >
            <IconFont className={`icon`} type={'iconempty'} />
            <div className={`tip`}>{this.state.text || "没有找到相关内容"}</div>
        </div>
    }

}

export default ListEmpty