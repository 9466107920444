import React from "react";
import Nav from "../../components/nav/Nav";
import "./layout.scss";

class Layout extends  React.Component{



    render() {

        return <div className="layout">
            <Nav className={`nav ${this.props.navType}`} hidden={this.props.hiddenNav} />
            <div className={`layout-content ${this.props.rootClassName || ""}`}>
                {this.props.children}
            </div>
        </div>
    }
}



export default Layout
