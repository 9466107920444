import React from "react";
import Nav from "../../components/nav/Nav";
import indexReq from "../../request/indexReq";
// import IconFont from '../../components/iconfont/icon-font'
import Layout from "../../layouts/layout/Layout";
import { Row, Col, message, Tabs, Avatar, Button, Affix, Icon, Divider } from "antd";
import "./course.scss";
import IconFont from "../../components/iconfont/icon-font";
import CardHeader from "../../components/cardHeader/CardHeader";
import Card from "../../components/card/Card";
import WorkComment from "../../components/work-comment/WorkComment";
import LittleBanner from "../../components/littleBanner/LittleBanner";
import courseReq from "../../request/courseReq";
import WeiXinPayQRCodeModal from "../../layouts/WeiXinPayQRCodeModal/WeiXinPayQRCodeModal";
import actions from "../../redux/actions";
import { connect } from "react-redux";
import accountReq from "../../request/accountReq";
import CommentTextarea from "../../components/comment-textarea/CommentTextarea";
import config from "../../config";
import { Link } from "react-router-dom";
import bannerReq from "../../request/bannerReq";
import ListFooter from "../listFooter/ListFooter";
import ShotsLayout from "../../layouts/shotsLayout/ShotsLayout";
import ContentNoFound from "../../components/contentNoFound/ContentNoFound";
import utilFunc from "../../utils/util";
import commentReq from "../../request/commentReq";

const dateformat = require("dateformat");
const { TabPane } = Tabs;
const playerParams = {
    id: "lesson-edit-player-container",
    qualitySort: "asc",
    format: "m3u8",
    mediaType: "video",
    encryptType: 1,
    width: "100%",
    height: "auto",
    autoplay: false,
    isLive: false,
    rePlay: false,
    playsinline: false,
    preload: true,
    controlBarVisibility: "hover",
    useH5Prism: true,
    skinLayout: [
        {
            name: "bigPlayButton",
            align: "cc"
        },
        {
            name: "H5Loading",
            align: "cc"
        },
        {
            name: "errorDisplay",
            align: "tlabs",
            x: 0,
            y: 0
        },
        {
            name: "infoDisplay"
        },
        {
            name: "tooltip",
            align: "blabs",
            x: 0,
            y: 56
        },
        {
            name: "thumbnail"
        },
        {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [
                {
                    name: "progress",
                    align: "blabs",
                    x: 0,
                    y: 44
                },
                {
                    name: "playButton",
                    align: "tl",
                    x: 15,
                    y: 12
                },
                {
                    name: "timeDisplay",
                    align: "tl",
                    x: 10,
                    y: 7
                },
                {
                    name: "fullScreenButton",
                    align: "tr",
                    x: 10,
                    y: 12
                },
                {
                    name: "setting",
                    align: "tr",
                    x: 15,
                    y: 12
                },
                {
                    name: "volume",
                    align: "tr",
                    x: 5,
                    y: 10
                }
            ]
        }
    ]
};
const CourseLevels = {
    primary: "初级",
    middle: "中级",
    high: "高级"
}
const CourseLevelColor = {
    primary: "#5FC4FF",
    middle: "#FFB15F",
    high: "#FF5F5F"
}
const loadingStatus = {
    init: "init",
    firstLoading: "first-loading",
    loadingMore: "loading-more",
    firstError: "first-error",
    moreError: "more-error"
};
const CommentPerPage = 20;

class Course extends React.Component {
    constructor(props) {
        super(props);

    }

    state = {
        loadingCommentListStatus: loadingStatus.init,
        courseInfo: null,
        selectedLessonIndex: -1,
        playingLesson: null,
        showWechatPay: false,
        hasPurchase: false,
        loginUserInfo: null,
        comments: [],
        count: 0,
        top: 0,
        showContinuePlayAlert: false,
        shownContinueAlert: false,
        imageAveColor: {},
        activeKey: "2",
        commentPage: 0,
        seriesCourses: [],
        canTopComment:false

    };

    /* life circle method */
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            isLogin: nextProps.isLogin,
            loginUserInfo: nextProps.loginUserInfo,
            systemOnInput: nextProps.systemOnInput,
        canTopComment:nextProps.canTopComment
        };
    }

    componentDidMount() {
        this.getCourseDetail();
        this.checkIfPurchase();
        this.getBanners();
    }



    getBanners() {
        // 主banner
        bannerReq.getBanners(`course-detail`).then(res => {
            if (res && res.length) {
                res.forEach(item => {
                    indexReq.getQiniuImageAveColor(item.preview).then(res => {
                        let colors = this.state.imageAveColor;
                        colors[item.preview] = res;
                        this.setState({
                            imageAveColor: colors
                        });
                    });
                });
            }
            this.setState({
                banners: res || []
            });
        });
    }

    componentWillUnmount() {
        if (
            window.aliyunPlayer &&
            typeof window.aliyunPlayer.dispose === "function"
        ) {
            window.aliyunPlayer.dispose();
        }
    }

    purchaseCheckTimer = null;
    refreshPurchaseStatus = () => {
        this.purchaseCheckTimer = setInterval(() => {
            this.checkIfPurchase();
        }, 2000);
    };

    checkIfPurchase = () => {
        if (this.state.isLogin) {
            courseReq
                .checkIfPurchaseCourse(this.props.match.params.courseId)
                .then(res => {
                    this.setState(
                        {
                            hasPurchase: res.hasPurchase
                        },
                        () => {
                            if (this.state.hasPurchase) {
                                this.setState({
                                    showWechatPay: false
                                });
                                if (this.purchaseCheckTimer) {
                                    clearInterval(this.purchaseCheckTimer);
                                }
                            }
                        }
                    );
                })
                .catch(error => {
                });
        }
    };


    checkIfFollowUsers = () => {
        let courseInfo = this.state.courseInfo || {};
        if (
            this.state.isLogin &&
            courseInfo.teacherInfo &&
            courseInfo.teacherInfo.length
        ) {
            let ids = courseInfo.teacherInfo.map(user => user.id);
            accountReq
                .checkIfFollowUser(ids.toString())
                .then(res => {
                    let courseInfo = this.state.courseInfo || {};
                    let teacherInfo = courseInfo.teacherInfo || [];
                    teacherInfo = teacherInfo.map(user => {
                        user.followed = !!res[user.id];
                        return user;
                    });
                    courseInfo.teacherInfo = teacherInfo;
                    this.setState({
                        courseInfo
                    });
                })
                .catch(error => {
                });
        }
    };

    checkIfCourseCollected() {
        let courseInfo = this.state.courseInfo;
        if (this.state.isLogin && courseInfo) {
            accountReq
                .checkIfCourseCollected(courseInfo.id)
                .then(res => {

                    let courseInfo = this.state.courseInfo || {};
                    courseInfo.hasCollected = !!res.hasCollected;
                    this.setState({
                        courseInfo
                    });
                })
                .catch(error => {
                });
        }
    }

    handlerCollectCourse = () => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        if (!this.state.courseInfo) {
            return;
        }
        accountReq
            .handlerCollectCourse(
                this.state.courseInfo.id,
                !this.state.courseInfo.hasCollected
            )
            .then(res => {
                let courseInfo = this.state.courseInfo || {};
                courseInfo.hasCollected = !courseInfo.hasCollected;
                this.setState({
                    courseInfo
                });
                if (courseInfo.hasCollected) {
                    message.success("收藏成功");
                }
            })
            .catch(error => {
                message.error("收藏失败");
            });
    };


    lastPlayRecord = { lessonId: null, watchedTime: 0 };
    initPlayer = (vid, playAuth, source) => {
        this.emptyPreviousPlayerHtml();
        if (!document.getElementById("lesson-edit-player-container")) {
            if (this.createPlayerTimer) {
                return;
            } else {
                this.createPlayerTimer = setTimeout(() => {
                    this.initPlayer(vid, playAuth, source);
                }, 300);
            }

            return
        }
        if (this.createPlayerTimer) {
            clearTimeout(this.createPlayerTimer);
        }

        let params = {
            ...playerParams,
            vid,
            playauth: playAuth
        };
        if (source) {
            params.source = JSON.stringify(source);
        }
        // eslint-disable-next-line no-undef
        window.aliyunPlayer = new Aliplayer(params, player => {
        });

        let handleReady = e => {

            let videoDom = document.getElementsByTagName("video");
            if (videoDom && videoDom.length) {
                videoDom[0].addEventListener("click", e => {
                    if (window.aliyunPlayer && window.aliyunPlayer.play) {
                        if (window.aliyunPlayer.getStatus() === "playing") {
                            window.aliyunPlayer.pause();
                        } else if (window.aliyunPlayer.getStatus() === "pause") {
                            window.aliyunPlayer.play();
                        }
                    }
                });
                document.onkeydown = e => {

                    if (e && e.keyCode === 32) {
                        if (this.props.systemOnInput) {
                            return
                        }
                        if (window.aliyunPlayer && window.aliyunPlayer.play) {
                            if (window.aliyunPlayer.getStatus() === "playing") {
                                window.aliyunPlayer.pause();
                            } else if (window.aliyunPlayer.getStatus() === "pause") {
                                window.aliyunPlayer.play();
                            }
                        }
                        e.preventDefault();

                    }
                };
            }

            if (window.location.href.match(/\/course\/.*/)) {
                if (
                    this.state.playingLesson.watchRecord &&
                    this.state.playingLesson.watchRecord.watchedDuration > 10 &&
                    !this.state.shownContinueAlert
                ) {
                    this.setState({
                        showContinuePlayAlert: true
                    });
                    return;
                }
                window.aliyunPlayer.play();
            } else {
                if (window.aliyunPlayer) {
                    window.aliyunPlayer.dispose();
                }
            }
        };
        window.aliyunPlayer.on("ready", handleReady);
        let handleError = function (e) {

        };
        window.aliyunPlayer.on("error", handleError);
        let handlerEnd = () => {
            if (this.state.isLogin && this.state.playingLesson) {
                let courseInfo = this.state.courseInfo || {};
                let watchRecord = this.state.playingLesson.watchRecord || {};
                watchRecord.hasFinal = true;
                this.setState({
                    courseInfo
                });
                courseReq.recordLessonWatchProgress(
                    this.state.playingLesson.id,
                    this.state.playingLesson.lessonIndex,
                    null,
                    true
                );
            }
        };
        let handlerPlayTimeUpdate = () => {
            if (this.state.isLogin && this.state.playingLesson) {
                let watchedDuration = window.aliyunPlayer.getCurrentTime();
                let duration = window.aliyunPlayer.getDuration();
                if (
                    this.lastPlayRecord.lessonId === this.state.playingLesson.id &&
                    Math.abs(watchedDuration - this.lastPlayRecord.watchedTime) < 10
                ) {
                    return;
                }
                let isFinal = watchedDuration / duration > 0.9;
                courseReq.recordLessonWatchProgress(
                    this.state.playingLesson.id,
                    this.state.playingLesson.lessonIndex + 1,
                    watchedDuration,
                    isFinal
                );
                let courseInfo = this.state.courseInfo || {};
                let watchRecord = this.state.playingLesson.watchRecord || {};
                watchRecord.watchedDuration = watchedDuration;
                watchRecord.hasFinal = isFinal;
                this.setState({
                    courseInfo
                });
                this.lastPlayRecord.lessonId = this.state.playingLesson.id;
                this.lastPlayRecord.watchedTime = watchedDuration;
            }
        };
        window.aliyunPlayer.on("ended", handlerEnd);
        window.aliyunPlayer.on("timeupdate", handlerPlayTimeUpdate);
    };

    continuePlayCurrentLesson = () => {
        if (this.state.playingLesson && window.aliyunPlayer) {
            if (this.state.playingLesson.watchRecord) {

                window.aliyunPlayer.seek(
                    parseInt(this.state.playingLesson.watchRecord.watchedDuration)
                );
                window.aliyunPlayer.play();
            } else {
                window.aliyunPlayer.seek(0);
                window.aliyunPlayer.play();
            }
        }
        this.setState({
            showContinuePlayAlert: false,
            shownContinueAlert: true
        });
    };

    replayCurrentLesson = () => {
        if (this.state.playingLesson && window.aliyunPlayer) {
            window.aliyunPlayer.play();
        }
        this.setState({
            showContinuePlayAlert: false,
            shownContinueAlert: true
        });
    };

    emptyPreviousPlayerHtml = () => {
        if (document.getElementById("lesson-edit-player-container")) {
            document.getElementById("lesson-edit-player-container").innerHTML = "";
        }
    };

    onSelectLesson = e => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let courseInfo = this.state.courseInfo || {};
        let lessonInfo = courseInfo.lessonInfo || [];
        let lesson = lessonInfo[e.currentTarget.dataset.index];
        lesson.lessonIndex = parseInt(e.currentTarget.dataset.index);
        if (this.state.playingLesson && this.state.playingLesson.id === lesson.id) {
            return;
        }
        if (this.state.hasPurchase || courseInfo.free || lesson.free) {
            this.setState({
                selectedLessonIndex: e.currentTarget.dataset.index,
                playingLesson: lesson,
                showContinuePlayAlert: false
            });
            if (window.aliyunPlayer) {
                window.aliyunPlayer.dispose();
                window.aliyunPlayer = null;
            }
            this.playVideo(lesson.id);
        } else {
            this.showPay();
        }
    };

    playVideo(lessonId) {
        if (window.deviceInfo.isMobile) {
            courseReq
                .getLessonPlayUrl(lessonId)
                .then(info => {
                    if (window.aliyunPlayer) {
                        window.aliyunPlayer.dispose();
                        this.initPlayer(null, null, info);
                    } else {
                        this.initPlayer(null, null, info);
                    }
                    return Promise.resolve();
                })
                .catch(error => {
                    message.error(`视频暂时无法播放，请稍后重试`);
                });
        } else {
            courseReq
                .getLessonPlayAuth(lessonId)
                .then(info => {
                    if (
                        window.aliyunPlayer &&
                        window.aliyunPlayer.replayByVidAndPlayAuth &&
                        typeof window.aliyunPlayer.replayByVidAndPlayAuth === "function"
                    ) {
                        window.aliyunPlayer.dispose();
                        this.initPlayer(info.videoId, info.playAuth);
                    } else {
                        this.initPlayer(info.videoId, info.playAuth);
                    }

                    return Promise.resolve();
                })
                .catch(error => {
                    message.error(`视频暂时无法播放，请稍后重试`);
                });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isLogin && !prevProps.isLogin) {
            this.checkIfPurchase();
            this.checkIfFollowUsers();
            this.checkIfCourseCollected();

            if (this.state.courseInfo && this.state.courseInfo.courseType === 'online-course') {
                this.getCourseWatchRecords();
            }

            this.checkIfCommentLike(this.state.comments);
        }
        if (prevProps.match.params.courseId !== this.props.match.params.courseId) {

            if (window.aliyunPlayer) {
                window.aliyunPlayer.dispose();
                window.aliyunPlayer = null;
            }
            this.setState({
                loadingCommentListStatus: loadingStatus.init,
                courseInfo: null,
                selectedLessonIndex: -1,
                playingLesson: null,
                showWechatPay: false,
                hasPurchase: false,
                loginUserInfo: null,
                comments: [],
                count: 0,
                top: 0,
                showContinuePlayAlert: false,
                shownContinueAlert: false,
                imageAveColor: {},
                activeKey: "2",
                commentPage: 0,
                seriesCourses: []
            }, () => {
                this.getCourseDetail();
                this.checkIfPurchase();
                this.getBanners();
            })
        }
    }

    getCourseDetail() {
        courseReq
            .getCourseDetailWithCourseId(this.props.match.params.courseId)
            .then(res => {
                if(res){
                    res.courseType = res.courseType === 'online-course' && res.playOrigin === "local" ? "online-course" : "live-course";
                }

                utilFunc.setDocumentInfo({
                    title: res && res.title ? res.title : null,
                    description: res && res.subtitle ? res.tags : "优阁,设计,课程",
                    keywords: res && res.tags ? res.tags : "优阁课程"
                })
                if (res && res.tags) {
                    res.tags = res.tags.split(`,`);
                }
                indexReq.getQiniuImageAveColor(res.preview).then(res => {
                    let colors = this.state.imageAveColor;
                    colors[res.preview] = res;

                    this.setState({
                        imageAveColor: colors
                    });
                });
                let activeKey = this.state.activeKey;

                activeKey = res.courseType === 'online-course' ? "2" : "1";
                if (res && res.courseType === 'live-course') {
                    delete res.lessonInfo;
                }
                if (res.courseSeriesId) {
                    this.getOtherCourseOfSeries(res.courseSeriesId);
                }
                this.setState(
                    {
                        activeKey,
                        courseInfo: res
                    },
                    () => {
                        this.checkIfFollowUsers();
                        this.checkIfCourseCollected();
                        this.getCommentList();
                        if (this.state.courseInfo && this.state.courseInfo.courseType === 'online-course') {
                            this.getCourseWatchRecords();
                        }

                    }
                );
            })
            .catch(error => {
                if (error && error.code === 7001) {
                    return this.setState({
                        noFound: true
                    })
                }
                message.error(`获取课程详情时发生错误`);
            });
    }

    getOtherCourseOfSeries(seriesId) {
        courseReq.getSeriesDetail(seriesId)
            .then(res => {
                let seriesCourses = []
                if (res && res.courses) {
                    for (let course of res.courses) {
                        if (course.id !== this.state.courseInfo.id) {
                            seriesCourses.push(course);
                        }
                    }
                }
                this.setState({
                    seriesCourses
                })


            })
            .catch(error => {

            })
    }

    getPayInfo = () => {
        courseReq.purchaseCourse(this.state.courseInfo.id).then(payInfo => {
            this.setState({
                payInfo
            });
        });
    };

    showPay = () => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        this.setState(
            {
                showWechatPay: true,
                payInfo: {}
            },
            () => {
                this.refreshPurchaseStatus();
            }
        );
        this.getPayInfo();
    };

    cancelPay = () => {
        this.setState({
            showWechatPay: false,
            payInfo: {}
        });
        if (this.purchaseCheckTimer) {
            clearInterval(this.purchaseCheckTimer);
            this.checkIfPurchase();
        }
    };

    handlerFollow = e => {
        let index = e.currentTarget.dataset.index;
        let user = this.state.courseInfo.teacherInfo[index];
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        if (user.followed) {
            accountReq.cancelFollowUser(user.id).then(res => {
                let user = this.state.courseInfo.teacherInfo[index];
                user.followed = false;
                this.setState({
                    courseInfo: this.state.courseInfo
                });
            });
        } else {
            accountReq.followUser(user.id).then(res => {
                let user = this.state.courseInfo.teacherInfo[index];
                user.followed = true;
                this.setState({
                    courseInfo: this.state.courseInfo
                });
                message.success("订阅成功");
            });
        }
    };

    pushComment = (value, target) => {
        courseReq
            .postComment({
                courseId: this.state.courseInfo.id,
                content: value
            })
            .then(res => {
                message.success(`评论发布成功`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                comments = [res].concat(comments);
                this.setState({
                    comments
                });
                target.clearContent();
            })
            .catch(error => {
                message.error(JSON.stringify(error));
            });
    };

    getCommentList = () => {

        if (this.state.loadingCommentListStatus === loadingStatus.firstLoading || this.state.loadingCommentListStatus === loadingStatus.loadingMore) {
            return;
        }

        if (this.state.commentPage < 1) {
            this.setState({
                loadingCommentListStatus: loadingStatus.firstLoading,
                comments: []
            });
        } else {

            this.setState({
                loadingCommentListStatus: loadingStatus.loadingMore
            });
        }
        courseReq
            .getComments(this.state.courseInfo.id, CommentPerPage, this.state.commentPage)
            .then(res => {
                let moreComments = res.rows;
                let count = res.count;
                let noMore = false;
                if (moreComments.length < CommentPerPage) {
                    noMore = true;
                }
                let comments = this.state.comments;
                if (this.state.commentPage < 1) {
                    comments = [];
                }
                this.checkIfCommentLike(moreComments);
                this.setState({
                    commentPage: this.state.commentPage + 1,
                    comments: comments.concat(moreComments),
                    count,
                    noMoreComment: noMore,
                    loadingCommentListStatus: loadingStatus.init,
                });
            })
            .catch(error => {
                if (this.state.commentPage < 1) {
                    this.setState({
                        loadingCommentListStatus: loadingStatus.firstError
                    });
                } else {
                    this.setState({
                        loadingCommentListStatus: loadingStatus.moreError
                    });
                }
            });
    };

    checkIfCommentLike = targetComments => {
        if (this.state.isLogin) {
            if (!targetComments || !targetComments.length) {
                return;
            }
            let ids = [];
            if (targetComments && targetComments.length) {
                for (let pc of targetComments) {
                    ids.push(pc.id);
                    if (pc.replayTargetInfo && pc.replayTargetInfo.length) {
                        for (let cc of pc.replayTargetInfo) {
                            ids.push(cc.id);
                        }
                    }
                }
            }

            courseReq.checkIfLikeComments(ids).then(res => {
                let comments = this.state.comments;
                if (comments && comments.length) {
                    for (let pc of comments) {
                        if (res.hasOwnProperty(pc.id)) {
                            pc.isLike = res[pc.id];
                        }
                        if (pc.replayTargetInfo && pc.replayTargetInfo.length) {
                            let a = []
                            for (let cc of pc.replayTargetInfo) {
                                if (res.hasOwnProperty(cc.id)) {
                                    cc.isLike = res[cc.id];
                                }
                                a.push({ ...cc })
                            }
                            pc.replayTargetInfo = a;
                        }
                    }
                    this.setState({
                        comments: [].concat(comments)
                    });
                }
            });
        }
    };

    startPlay = () => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let courseInfo = this.state.courseInfo || {};
        let lessonInfo = courseInfo.lessonInfo || [];
        if (!lessonInfo || !lessonInfo.length) {
            return;
        }
        let lesson = lessonInfo[0];
        if (this.state.hasPurchase || courseInfo.free || lesson.free) {
            this.setState({
                selectedLessonIndex: 0,
                playingLesson: lesson
            });
            this.playVideo(lesson.id);
        } else {
            this.showPay();
        }
    };

    onPostReplay = (content, comment, key, target) => {

        courseReq
            .postComment({
                courseId: this.state.courseInfo.id,
                content: `@${comment.userInfo.nickname ||
                    comment.userInfo.username} ${content}`,
                replyTargetId: comment.rootTargetId ? comment.rootTargetId : comment.id
            })
            .then(res => {

                message.success(`已成功回复`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                let c = this.state.comments[key];
                c.replayTargetInfo = c.replayTargetInfo || [];
                c.replayTargetInfo = [res].concat(c.replayTargetInfo);
                target.clearContent();
                target.showCommentTextarea(false);
                this.setState({
                    comments
                });

            })
            .catch(error => {
                message.error(JSON.stringify(error));
            });
    };

    onSelfPostReplay = (content, comment, key, target) => {

        courseReq
            .postComment({
                courseId: this.state.courseInfo.id,
                content: `${content}`,
                replyTargetId: comment.rootTargetId ? comment.rootTargetId : comment.id
            })
            .then(res => {

                message.success(`已成功回复`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                let c = this.state.comments[key];
                c.replayTargetInfo = c.replayTargetInfo || [];
                c.replayTargetInfo = [res].concat(c.replayTargetInfo);
                target.clearContent();
                target.showCommentTextarea(false);
                this.setState({
                    comments
                });
            })
            .catch(error => {
                message.error(JSON.stringify(error));
            });
    };

    getCourseWatchRecords() {
        if (this.state.isLogin && this.state.courseInfo) {
            courseReq
                .getUserCourseWatchRecords(this.state.courseInfo.id)
                .then(res => {
                    res = res || [];
                    if (!res.length) {
                        return;
                    }
                    let courseInfo = this.state.courseInfo || {};
                    let lessonInfo = courseInfo.lessonInfo || [];
                    let latestWatchedLesson = null;
                    let latestIndex = null;
                    for (let m = 0; m < res.length; m++) {
                        let record = res[m];

                        for (let n = 0; n < lessonInfo.length; n++) {
                            let lesson = lessonInfo[n];
                            if (record.courseLessonId == lesson.id) {
                                lesson.watchRecord = record;
                                if (m === 0) {
                                    lesson.lessonIndex = n;
                                    latestWatchedLesson = lesson;
                                }
                            }
                        }
                    }

                    this.setState(
                        {
                            courseInfo,
                            playingLesson: latestWatchedLesson
                        },
                        () => {
                            if (this.state.hasPurchase || courseInfo.free || latestWatchedLesson.free) {
                                if (latestWatchedLesson && latestWatchedLesson.lessonIndex < courseInfo.lessonInfo.length) {
                                    this.setState({
                                        selectedLessonIndex: latestWatchedLesson.lessonIndex,
                                        showContinuePlayAlert: false
                                    });
                                    if (window.aliyunPlayer) {
                                        window.aliyunPlayer.dispose();
                                        window.aliyunPlayer = null;
                                    }
                                    this.playVideo(this.state.playingLesson.id);
                                }

                            }
                        }
                    );
                });
        }
    }

    openCommentTarget = null;
    commentInputOnShow = target => {
        if (this.openCommentTarget) {
            if (this.openCommentTarget.clearContent) {
                this.openCommentTarget.clearContent();
            }

            if (this.openCommentTarget.showCommentTextarea) {
                this.openCommentTarget.showCommentTextarea(false);
            }
            this.openCommentTarget = null;
        }

        this.openCommentTarget = target;
    };

    childCommentDelete = (commentId, rowIndex, parentRowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments || [];

        let comment = comments[parentRowIndex] || {};
        let childComment = comment.replayTargetInfo
            ? comment.replayTargetInfo[rowIndex] || {}
            : {};
        if (
            childComment.id != commentId ||
            !childComment.userInfo ||
            !this.state.loginUserInfo ||
            childComment.userInfo.id != this.state.loginUserInfo.id
        ) {
            return;
        }
        courseReq
            .deleteComment(commentId)
            .then(res => {
                let comments = this.state.comments || [];
                let comment = comments[parentRowIndex] || {};
                comment.replayTargetInfo.splice(rowIndex, 1);
                let na = [].concat(comment.replayTargetInfo);
                comment.replayTargetInfo = na;
                let newComment = { ...comment };
                comments[parentRowIndex] = newComment;
                this.setState({
                    comments: [].concat(comments)
                });
                message.success(`评论已删除`);
            })
            .catch(error => {
                message.error(`删除失败`);
            });
    };
    parentCommentDelete = (commentId, rowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }

        let comments = this.state.comments || [];

        let comment = comments[rowIndex] || {};
        if (
            comment.id != commentId ||
            !comment.userInfo ||
            !this.state.loginUserInfo ||
            comment.userInfo.id != this.state.loginUserInfo.id
        ) {
            return;
        }
        courseReq
            .deleteComment(commentId)
            .then(res => {
                let comments = this.state.comments || [];
                comments.splice(rowIndex, 1);
                this.setState({
                    comments: [].concat(comments)
                });
                message.success(`评论已删除`);
            })
            .catch(error => {
                message.error(`删除失败`);
            });
    };

    loadingDownloadSource = false;
    downloadLessonSource = () => {
        if (this.loadingDownloadSource) {
            return;
        }
        let playingLesson = this.state.playingLesson;
        if (
            playingLesson &&
            playingLesson.sourceInfo &&
            playingLesson.sourceInfo.length
        ) {
            this.loadingDownloadSource = true;
            courseReq
                .getLessonSourceDownloadUrl(playingLesson.id)
                .then(url => {
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.loadingDownloadSource = false;
                })
                .catch(error => {
                    this.loadingDownloadSource = false;
                    return message.error(`发生错误，素材暂不可下载`);
                });
        } else {
            return message.warn(`素材不存在`);
        }
    };

    parentCommentLike = (commentId, rowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments;
        let comment = comments[rowIndex];
        if (comment.isLike) {
            courseReq.unlikeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[rowIndex];
                comment.isLike = false;
                comment.likeNum = parseInt(comment.likeNum)
                    ? parseInt(comment.likeNum) - 1
                    : 0;
                let newComment = {}
                Object.assign(newComment, comment);
                comments[rowIndex] = newComment
                this.setState({
                    comments: [].concat(comments)
                });
            });
        } else {
            courseReq.likeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[rowIndex];
                comment.isLike = true;
                comment.likeNum = parseInt(comment.likeNum) + 1;
                let newComment = {}
                Object.assign(newComment, comment);
                comments[rowIndex] = newComment
                this.setState({
                    comments: [].concat(comments)
                });
            });
        }
    };

    childCommentLike = (commentId, rowIndex, parentRowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments;
        let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
        if (comment.isLike) {
            courseReq.unlikeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
                comment.isLike = false;
                comment.likeNum = parseInt(comment.likeNum)
                    ? parseInt(comment.likeNum) - 1
                    : 0;
                let newComment = {}
                Object.assign(newComment, comment);
                comments[parentRowIndex].replayTargetInfo[rowIndex] = newComment
                comments[parentRowIndex].replayTargetInfo = [].concat(comments[parentRowIndex].replayTargetInfo)
                this.setState({
                    comments: [].concat(comments)
                });

            });
        } else {
            courseReq.likeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
                comment.isLike = true;
                comment.likeNum = parseInt(comment.likeNum) + 1;
                let newComment = {}
                Object.assign(newComment, comment);
                comments[parentRowIndex].replayTargetInfo[rowIndex] = newComment
                comments[parentRowIndex].replayTargetInfo = [].concat(comments[parentRowIndex].replayTargetInfo)
                this.setState({
                    comments: [].concat(comments)
                });
            });
        }
    };

    onClickShotPost = () => {
        if (this.state.isLogin) {
            if (this.state.loginUserInfo && this.state.loginUserInfo.userPower && !this.state.loginUserInfo.userPower.canPostShot) {
                message.warning(`当前账号不能发布作品`)
            } else {
                this.props.history.push(`/post/shot/create?linkCourse=${this.state.courseInfo.id}`)
            }
        } else {
            window.appLogin();
        }
    }
    handlerTopComment = (comment,rowIndex)=>{

        commentReq.handlerTop(comment.id,!comment.isTop,"course")
            .then(res =>{
                let comments = this.state.comments || [];
                let findIndex = comments.findIndex(item => item.id === comment.id);
                if(findIndex >= 0){
                    if(comment.isTop){

                        message.success('已取消置顶')
                    }else {

                        message.success('已置顶')
                    }
                    this.commentPage = 0;
                    this.setState({
                        commentPage:0,
                        loadingCommentListStatus:loadingStatus.init,
                        comments:[]
                    },()=>{
                        this.getCommentList();
                    });

                }

            })
            .catch(e =>{
                message.error('操作失败，请重试')
            })

    };
    render() {
        let {
            playingLesson,
            selectedLessonIndex,
            courseInfo,
            showWechatPay,
            payInfo,
            hasPurchase,
            loginUserInfo,
            comments,
            showContinuePlayAlert,
            loadingCommentListStatus,
            noMoreComment,
            noFound,
            seriesCourses
        } = this.state;
        if (noFound) {
            return (
                <div className="course-detail">
                    <Nav />
                    <Layout rootClassName={"course"} hiddenNav={true}>
                        <ContentNoFound text={`课程不存在或已被删除`} />
                    </Layout>

                </div>
            )
        }
        let lessonDoms = [];
        courseInfo = courseInfo || {};
        if (courseInfo.lessonInfo && courseInfo.lessonInfo.length) {
            let courseFree = courseInfo.free;

            lessonDoms = courseInfo.lessonInfo.map((item, index) => {
                return (
                    <li
                        key={index}
                        data-index={index}
                        onClick={this.onSelectLesson.bind(this)}
                    >
                        <div
                            className={`lesson-title ${
                                selectedLessonIndex == index ? "lesson-selected" : ""
                                }`}
                        >
                            <span>{index + 1}、</span>
                            {item.title}
                        </div>
                        <div className="lesson-status">
                            <div
                                className="lesson-free"
                                hidden={hasPurchase || courseFree || !item.free}
                            >
                                免费
                            </div>
                            <div
                                className="lesson-charge"
                                hidden={hasPurchase || courseFree || item.free}
                            >
                                <IconFont type="iconlock"></IconFont>
                            </div>
                        </div>
                    </li>
                );
            });
        }
        let tags = null;
        if (courseInfo.tags && courseInfo.tags.length) {
            tags = courseInfo.tags.map((item, idx) => {
                return <span key={idx}>#{item}</span>;
            });
        }

        let teachers = null;
        let teacherIds = [];
        if (courseInfo.teacherInfo && courseInfo.teacherInfo.length) {
            teachers = courseInfo.teacherInfo.map((item, idx) => {
                teacherIds.push(item.id);
                return (
                    <div className="course-teacher-panel" key={idx}>
                        <Link to={`/teacher/${item.username}`}>
                            <div className="course-teacher">
                                <Avatar className="course-teacher-avatar" src={item.avatar} />
                                <div className="course-teacher-name">{item.nickname}</div>
                            </div>
                        </Link>
                        <div
                            className="teacher-subscribe"
                            onClick={this.handlerFollow}
                            data-index={idx}
                        >
                            <span hidden={item.followed}>+</span>
                            <span>{item.followed ? "已订阅" : "订阅"}</span>
                        </div>
                    </div>
                );
            });
        }
        comments = comments || [];
        let commentList = comments.map((item, index) => {
            return (
                <WorkComment
                    data={item}
                    childComments={item.replayTargetInfo}
                    key={item.id}
                    compKey={item.id}
                    rowIndex={index}
                    onSelfPostReplay={this.onSelfPostReplay}
                    onPostReplay={this.onPostReplay}
                    openReplayInput={this.commentInputOnShow}
                    loginUserId={
                        loginUserInfo && loginUserInfo.id ? loginUserInfo.id : null
                    }
                    handlerDelete={this.parentCommentDelete}
                    handlerChildDelete={this.childCommentDelete}
                    handlerLike={this.parentCommentLike}
                    handlerChildLike={this.childCommentLike}
                    showTopComment={this.state.canTopComment}
                    handlerTopComment={this.handlerTopComment}
                />
            );
        });

        let courseState = null;
        let lessonInfo = courseInfo.lessonInfo || [];
        let totalNum = courseInfo.lessonTotalNum || 0;
        if (totalNum <= lessonInfo.length) {
            courseState = (
                <div className="course-brief">
                    <div className="course-status" style={{ color: "#6f6f6f" }}>
                        已完结
                    </div>
                    <div className="course-process">
                        本课程共 <span>{lessonInfo.length}</span> 集
                    </div>
                </div>
            );
        } else {
            courseState = (
                <div className="course-brief">
                    <div className="course-status">更新中...</div>
                    <div className="course-process">
                        本课程共 <span>{totalNum || ""}</span> 集,已更新至{" "}
                        <span>{lessonInfo.length}</span> 集
                    </div>
                </div>
            );
        }
        // totalNum = Math.max(totalNum,lessonInfo.length)

        // 上次播放时间
        let watchTime = "";
        if (playingLesson && playingLesson.watchRecord) {
            let ts =
                1573747200000 +
                parseInt(playingLesson.watchRecord.watchedDuration) * 1000;
            watchTime = dateformat(new Date(ts), "MM:ss");
        }

        let showShotFixed = loginUserInfo && loginUserInfo.roles && (loginUserInfo.roles.admin || (loginUserInfo.roles.teacher && teacherIds.indexOf(loginUserInfo.id) >= 0))
        let showRecommend = loginUserInfo && loginUserInfo.roles && (loginUserInfo.roles.admin || loginUserInfo.roles.teacher)


        return (
            <div className="course-detail" >
                <WeiXinPayQRCodeModal
                    show={showWechatPay}
                    onCancel={this.cancelPay}
                    courseId={courseInfo.id}
                    payInfo={payInfo}
                />
                <Nav></Nav>
                <Affix hidden={courseInfo.courseType !== "online-course"} offsetTop={this.state.top}
                    style={{ width: "100%" }}>
                    <div className="course-nav">
                        <div className="wrapper">
                            <div className="course-nav-inner">
                                <div className={"flex-l"}>
                                    <CardHeader style={{ padding: 0 }} title={<h1>{courseInfo.title}</h1>} />

                                </div>

                                <div className="course-purchase" hidden={courseInfo.free}>
                                    <div
                                        className="course-price"
                                        hidden={courseInfo.free || hasPurchase}
                                    >
                                        ￥
                                        {courseInfo.price
                                            ? parseFloat(courseInfo.price / 100).toFixed(2)
                                            : 0}
                                    </div>
                                    <div
                                        className="course-purchase-btn"
                                        onClick={this.showPay.bind(this)}
                                        hidden={courseInfo.free || hasPurchase}
                                    >
                                        购买
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Affix>

                <Layout rootClassName={"course"} hiddenNav={true}>
                    <Row gutter={16}>
                        <Col
                            xs={24}
                            sm={24}
                            lg={courseInfo.courseType !== "online-course" ? 24 : 18}
                        >
                            <div
                                className="course-panel"
                                hidden={courseInfo.courseType !== "online-course"}
                            >
                                <div className={`course-player-container`}>
                                    <div
                                        className="course-video"
                                        id={"lesson-edit-player-container"}
                                        style={{
                                            backgroundImage: `url(${courseInfo.preview})`,
                                            backgroundColor: this.state.imageAveColor[
                                                courseInfo.preview
                                            ]
                                        }}
                                    >
                                        <div className="video-play">
                                            <img
                                                src="/icons/svg/play.svg"
                                                alt=""
                                                onClick={this.startPlay}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`continue-alert`}
                                        hidden={!showContinuePlayAlert}
                                    >
                                        <div className={"title"}>上次看到 {watchTime}</div>
                                        <div className={`flex-b`}>
                                            <Button
                                                className="replay-btn"
                                                onClick={this.replayCurrentLesson}
                                            >
                                                从头播放
                                            </Button>
                                            <Button
                                                className="continue-btn"
                                                type={"primary"}
                                                onClick={this.continuePlayCurrentLesson}
                                            >
                                                继续播放
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <div className="course-lesson-title" hidden={!playingLesson}>
                                    <div className="lesson-title">
                                        {playingLesson ? playingLesson.title : ""}
                                    </div>
                                    <div
                                        className="course-lesson-file xs-hidden"
                                        hidden={
                                            !playingLesson ||
                                            !playingLesson.sourceInfo ||
                                            !playingLesson.sourceInfo.length
                                        }
                                        onClick={this.downloadLessonSource}
                                    >
                                        <img src="/icons/zip.png" width="18" />
                                        <span>下载本集素材</span>
                                    </div>
                                </div>
                            </div>
                            <div hidden={courseInfo.courseType === "online-course"} className="live-course-panel">
                                <Row type="flex" align="stretch" gutter={16}
                                    hidden={courseInfo.courseType !== "live-course"}>
                                    <Col xs={24} lg={18}>
                                        <div className="live-course-cover">
                                            <img
                                                src={courseInfo.preview}
                                                alt=""
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={6}>
                                        <div className="live-course-info"
                                            hidden={courseInfo.courseType !== "live-course"}>
                                            <div className="top-padding">
                                                <div className="course-info-detail">
                                                    <div className="title">
                                                        {courseInfo.title || ""}
                                                    </div>
                                                    <div className="date"
                                                        hidden={!courseInfo || (!courseInfo.startAt && !courseInfo.endAt)}><span style={{ color: "#3945f9" }}>
                                                            {courseInfo && courseInfo.startAt ? dateformat(new Date(courseInfo.startAt), 'yyyy年mm月dd日') : ""}{courseInfo.startAt && courseInfo.endAt ? " - " : ""}{courseInfo.endAt ? dateformat(new Date(courseInfo.endAt), 'yyyy年mm月dd日') : ""}上课
                                                        </span>
                                                        <span hidden={!courseInfo.lessonTotalNum}>
                                                            <Divider type="vertical" style={{ background: "#999999" }} />
                                                            <span style={{ color: "#777777" }}>已更新<span style={{ color: "#3945f9" }}>
                                                                {courseInfo.lessonTotalNum}</span>课时</span>
                                                        </span>
                                                    </div>

                                                    <div className={"flex-m"}>
                                                        <LevelTag text={CourseLevels[courseInfo.level || "primary"]}
                                                            color={CourseLevelColor[courseInfo.level || "primary"]} hidden={false} />
                                                        <Divider type="vertical" />
                                                        <div className={"apps"}>
                                                            {/*遍历显示课程软件*/}
                                                            {(function () {
                                                                if (courseInfo.softWareInfo && courseInfo.softWareInfo.length) {
                                                                    return courseInfo.softWareInfo.map((soft, index) => {
                                                                        return (
                                                                            <div className="app-icon-panel" key={index}>
                                                                                <img
                                                                                    className={"app-icon"}
                                                                                    src={soft.iconUrl}
                                                                                    key={index}
                                                                                    alt={""}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    });
                                                                } else {
                                                                    return null;
                                                                }
                                                            })()}
                                                        </div>
                                                    </div>

                                                    {/* <div>197次学习</div> */}
                                                    <div className="description" hidden={!courseInfo.timeRemark}>
                                                        {courseInfo.timeRemark || ""}
                                                    </div>
                                                </div>
                                                <div className="teacher">{teachers}</div>
                                            </div>
                                            <a href={courseInfo && courseInfo.liveUrl ? courseInfo.liveUrl : "#course-intro"}
                                                target={courseInfo && courseInfo.liveUrl ? "_blank":""}>
                                                <div className="price-detail">
                                                    <span className="price"
                                                        hidden={!courseInfo || courseInfo.free}><span
                                                            className="price-symbol">￥</span><span>{parseFloat(courseInfo.price / 100).toFixed(courseInfo.price % 100 > 0 ? courseInfo.price % 10 > 0 ? 2 : 1 : 0)}</span><span
                                                                className="original-price"
                                                                hidden={!courseInfo || !courseInfo.originalPrice}>￥{parseFloat(courseInfo.originalPrice / 100).toFixed(courseInfo.originalPrice % 100 > 0 ? (courseInfo.originalPrice % 10 > 0 ? 2 : 1) : 0)}</span></span>
                                                    <span className="price"
                                                        hidden={!courseInfo || !courseInfo.free}><span
                                                            style={{ fontSize: "20px" }}>免费</span></span>
                                                    <div className="apply-btn">加入课程<Icon type="caret-right" /></div>
                                                </div>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="course-group-panel" hidden={!seriesCourses || !seriesCourses.length}>
                                <div className="course-group-title">
                                    系列课程
                                </div>
                                <div className="course-group">
                                    {
                                        seriesCourses.map((item, index) => {
                                            return <Link to={`/course/${item.id}`} key={index}>
                                                <div className="course-group-card" >
                                                    <div className="course-group-card-inner">
                                                        <img
                                                            src={item.preview}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="course-group-card-title">
                                                        {item.title || ""}
                                                    </div>
                                                </div>
                                            </Link>
                                        })
                                    }


                                </div>
                            </div>
                            <div hidden={courseInfo.courseType !== "online-course"}>
                                <Row>
                                    <Col xs={24} lg={0}>
                                        <Card title="播放列表" className="card-marginbottom">
                                            <ul className="lesson-list">{lessonDoms}</ul>
                                        </Card>
                                        <Card title="课程概要" className="card-marginbottom">
                                            {courseState}
                                            <div className="course-tag">
                                                {/*遍历显示tag*/}
                                                {tags}
                                            </div>
                                            {/*遍历显示课程老师*/}
                                            {teachers}
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className="course-panel">
                                <Tabs activeKey={this.state.activeKey} onChange={(e) => {
                                    this.setState({
                                        activeKey: e
                                    })
                                }}>
                                    <TabPane tab="课程简介" key="1">
                                        <div
                                            id="course-intro"
                                            className={`rich-text`}
                                            dangerouslySetInnerHTML={{
                                                __html: courseInfo.description || "暂无简介"
                                            }}
                                        />
                                    </TabPane>
                                    <TabPane tab="评论" key="2">
                                        <div
                                            className={"bks-work-textarea"}
                                            hidden={!this.state.isLogin}
                                        >
                                            <Avatar
                                                className={"avatar"}
                                                src={
                                                    loginUserInfo && loginUserInfo.avatar
                                                        ? loginUserInfo.avatar
                                                        : ""
                                                }
                                            />
                                            <CommentTextarea

                                                show={true}
                                                onSubmit={this.pushComment}
                                            />
                                        </div>
                                        <div className={`login-remind`} hidden={this.state.isLogin}>
                                            <Button
                                                type={`primary`}
                                                onClick={() => {
                                                    return window.appLogin();
                                                }}
                                            >
                                                登录后写评论
                                            </Button>
                                        </div>
                                        {commentList}
                                        <ListFooter
                                            show={[loadingStatus.loadingMore, loadingStatus.moreError, loadingStatus.init].indexOf(loadingCommentListStatus) >= 0 && comments.length}
                                            status={noMoreComment ? 'no-more' : loadingCommentListStatus === loadingStatus.loadingMore ? 'loading-more' : "loading-error"}
                                            onLoadingMore={this.getCommentList} />
                                    </TabPane>
                                    <TabPane tab="作品" key="3">
                                        <ShotsLayout listType={'course-shot'}
                                            courseId={courseInfo ? courseInfo.id : null} hideCategory={true}
                                            disableLoadingMore={true} showShotFixed={showShotFixed}
                                            showRecommend={showRecommend} onClickShotPost={this.onClickShotPost} />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Col>
                        <Col
                            xs={0}
                            sm={0}
                            lg={6}
                            hidden={courseInfo.courseType !== "online-course"}
                        >
                            <Card title="课程概要" className="card-marginbottom">
                                <div className="flex-base">
                                    {courseState}
                                    <IconFont
                                        type={"iconshoucang"}
                                        className={"collection-icon"}
                                        style={{ color: courseInfo.hasCollected ? "gold" : "gray" }}
                                        onClick={this.handlerCollectCourse}
                                    />
                                </div>
                                <div className="course-tag">{tags}</div>
                                {teachers}
                            </Card>
                            <Card title="播放列表" className="card-marginbottom">
                                <ul className="lesson-list">{lessonDoms}</ul>
                            </Card>
                            <Card
                                title="相关课程"
                                className="card-marginbottom"
                                hidden={true}
                            >
                                <ul className="related-list">
                                    <li>
                                        <div className="related-cover">
                                            <div className="related-cover-inner"></div>
                                        </div>
                                        <div className="related-title">
                                            用PS制作霓虹灯光LOGO效果
                                        </div>
                                    </li>
                                    <li>
                                        <div className="related-cover">
                                            <div className="related-cover-inner"></div>
                                        </div>
                                        <div className="related-title">
                                            用PS制作霓虹灯光LOGO效果
                                        </div>
                                    </li>
                                    <li>
                                        <div className="related-cover">
                                            <div className="related-cover-inner"></div>
                                        </div>
                                        <div className="related-title">
                                            用PS制作霓虹灯光LOGO效果
                                        </div>
                                    </li>
                                </ul>
                            </Card>
                            <LittleBanner
                                banners={this.state.banners}
                                colors={this.state.imageAveColor}
                            />
                        </Col>
                    </Row>
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo,
    systemOnInput: state.system.documentOnInput,
    canTopComment:state.account.canTopComment,

});
const mapDispatchToProps = {
    handlerShowLoginRegisterModal: actions.accountShowLoginRegisterModal
};
const LevelTag = props => {
    let backgroundColor = "rgba(255,255,255,0.1)";

    if (props.color) {
        backgroundColor =
            "rgba(" +
            parseInt("0x" + props.color.slice(1, 3)) +
            "," +
            parseInt("0x" + props.color.slice(3, 5)) +
            "," +
            parseInt("0x" + props.color.slice(5, 7)) +
            "," +
            0.1 +
            ")";
    }
    return (
        <div
            hidden={props.hidden}
            style={{
                color: props.color,
                backgroundColor,
                fontSize: 12,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 4px"
            }}
        >
            <label className="level-tag">{props.text || ""}</label>
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(Course);
