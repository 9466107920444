import React from 'react';

import './commentTextarea.scss'
import IconFont from "../iconfont/icon-font";
import 'emoji-mart/css/emoji-mart.css'
import emojiData from '../../utils/emoji-messager-people'
import { NimblePicker,Emoji } from 'emoji-mart'
import InputEmoji from 'react-input-emoji'
import actions from "../../redux/actions";
import { connect } from "react-redux";

const emojiI18N = {
    skintext: 'Choose your default skin tone',
    categories: {
        recent: '',
        people: '',

    },
    categorieslabel: '分类', // Accessible title for the list of categories
    skintones: {
    },
}
class CommentTextarea extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);

    }

    state = {
        content: "",
        showEmojiPicker:false
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            placeholder: nextProps.placeholder,
            show: nextProps.show,
            okText: nextProps.okText
        };
    }
    textOnChange = e => {

        this.setState({
            content: e.currentTarget.value
        })
    }
    onSubmit = e => {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.content,this);
        }
    }

    clearContent() {

        this.setState({
            content: ""
        })
    }
    hideLoading = () => {

    }

    handleOnEnter =  (text) => {

      }
    showEmojiPicker =()=>{
        this.setState({
            showEmojiPicker:!this.state.showEmojiPicker
        })
    }
    render() {
        let { placeholder, content, okText,showEmojiPicker } = this.state;

        let emojiPicker = null;
        if(showEmojiPicker){
            emojiPicker = <NimblePicker title={''} i18n={emojiI18N}  set='messenger'  data={emojiData} onSelect={(emoji)=>{

                // this.setState({
                //     content:this.state.content ? this.state.content + `:${emoji.short_names[0]}:` :  `:${emoji.short_names[0]}:`
                // })
            }} />
        }
        return (
            <div className="comment-textarea" hidden={!this.state.show}>

                {/*<Emoji*/}
                {/*    set={'messenger'}*/}
                {/*    emoji={'kissing_smiling_eyes'}*/}
                {/*    size={24}*/}
                {/*    fallback={(emoji, props) => {*/}
                {/*        return emoji ? `:${emoji.short_names[0]}:` : props.emoji*/}
                {/*    }}*/}
                {/*/>*/}

                {/*<div contentEditable={true} >*/}
                {/*    <img src={''}/>*/}

                {/*</div>*/}
                <textarea rows="3" placeholder={placeholder || "写下你的评论"} value={this.state.content} onChange={this.textOnChange} onFocus={()=>{
                    if(this.props.systemFlagOnInput){
                        this.props.systemFlagOnInput(true)
                    }
                }} onBlur={()=>{
                    if(this.props.systemFlagOnInput){
                        this.props.systemFlagOnInput(false)
                    }
                }} />
                <div className="textarea-tool">
                     {/*<div className="expression" onClick={this.showEmojiPicker}><IconFont type="iconxiaolian" className="iconxiaolian" />表情</div>*/}
                    <div></div>
                    <div className="flex-group">
                        <div>{content.length}/600</div>
                        <button onClick={this.onSubmit} disabled={!content || !content.length}>{okText || "发布"}</button>
                    </div>
                </div>



            </div>
        )
    }
}


const mapDispatchToProps = {
    systemFlagOnInput: actions.systemFlagOnInput
};

export default connect(null,mapDispatchToProps)(CommentTextarea);