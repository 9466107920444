
import accountTypes  from '../actionTypes/accountTypes'
const  {  ACCOUNT_ADD_LOGIN_USER_INFO,
    ACCOUNT_REMOVE_LOGIN_USER_INFO,
    ACCOUNT_FLAG_LOGIN_STATUS,ACCOUNT_SHOW_LOGIN_REGISTER_MODAL ,ACCOUNT_UNREAD_MESSAGES,ACCOUNT_CAN_HANDLER_TOP_COMMENT} = accountTypes
function accountAddLoginUserInfo(info) {
    return { type: ACCOUNT_ADD_LOGIN_USER_INFO, payload:info }
}

function accountRemoveLoginUserInfo(){
    return { type: ACCOUNT_REMOVE_LOGIN_USER_INFO,payload:null }
}

function accountFlagLoginStatus(isLogin){
    return { type: ACCOUNT_FLAG_LOGIN_STATUS,payload:!!isLogin }
}

function accountShowLoginRegisterModal(status) {
    // status : "none','login','register'
    return { type: ACCOUNT_SHOW_LOGIN_REGISTER_MODAL,payload:status }
}

function  accountSetUnreadMessagesData(messageData) {
    return { type: ACCOUNT_UNREAD_MESSAGES, payload: messageData}
}
// 账户能否有权进行评论置顶操作
function  accountCanHandlerTopComment(isPower) {
    return { type: ACCOUNT_CAN_HANDLER_TOP_COMMENT, payload: isPower}
}

export  default {
    accountAddLoginUserInfo,
    accountRemoveLoginUserInfo,
    accountFlagLoginStatus,
    accountShowLoginRegisterModal,
    accountSetUnreadMessagesData,
    accountCanHandlerTopComment
}