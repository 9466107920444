import React from "react";
import {Avatar} from 'antd'
import "./skeletonShotListCard.scss";

export default props => {
    return (

        <div
            className={`bks-skeleton-shot-list-card`}
        >
            <div className={"card"}>
                <div className={"bks-shot-flex0"}>
                    <div className={"bks-shot-flex1"}>
                        <div className={"bks-shot-flex2"}>

                        </div>
                    </div>
                </div>
                <div className="shot-title"></div>
                <div className={"control-bar"}>
                    <div className={"user"}>
                        <Avatar className={"avatar"}/>
                        <label></label>
                    </div>
                    <div className="icon-panel">
                        <label className={"control-item"}>

                        </label>

                        <label className={"control-item"}>

                        </label>
                    </div>
                </div>
            </div>
        </div>)
}

