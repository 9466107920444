
import React from 'react'
import DotLoading from "../../components/DotLoading/DotLoading";
import './listFooter.scss'
class ListFooter extends  React.Component {

    constructor(props) {
        super(props);
        Object.assign(this.state,props);

    }

    listFooterStatus = {
        init:"init",
        loadingMore:"loading-more",
        loadingError:"loading-error",
        noMore:"no-more"
    };
    state = {

    };
    static  getDerivedStateFromProps(nextProps,prevState){

        return {
            className:nextProps.className,
            style:nextProps.style,
            show:nextProps.show,
            status:nextProps.status
        };
    }

    componentDidMount() {
        this.listenWindowScroll()
    }
    lastScrollHeight = 0;
    listenWindowScroll() {
        window.onscroll = () => {
            let scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            let scrollHeight = document.body.scrollHeight;
            let clientHeight =
                document.body.clientHeight || document.documentElement.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight - 100 && this.lastScrollHeight <= scrollHeight ) {
                this.lastScrollHeight = scrollHeight
                this.onReachBottom();
            }
        };
    }

    onReachBottom() {

        if(this.state.status !== this.listFooterStatus.noMore && this.props.onLoadingMore && this.state.show){
            this.props.onLoadingMore();
        }

    }


    render() {
        let {status} = this.state;
        return (
            <div className={`comp-list-footer  ${this.state.className || ""}`} style={this.state.style || {}}  hidden={!this.props.show} >
                    <DotLoading show={status === this.listFooterStatus.loadingMore}/>
                    <div
                        className={`bottom-loading ${status === this.listFooterStatus.noMore ? "loaded-all" : ""}`}
                        hidden={ status !== this.listFooterStatus.noMore && status !== this.listFooterStatus.loadingError}
                        onClick={()=>{
                            if(status !== this.listFooterStatus.noMore && this.props.onLoadingMore){
                                this.props.onLoadingMore();
                            }

                        }}
                        style={{
                            cursor:status === this.listFooterStatus.noMore ? "":"pointer"
                        }}
                    >

                        <div className={"title"}  >
                            {status === this.listFooterStatus.noMore ? "已加载全部" : "查看更多"}
                        </div>
                    </div>
            </div>
        )
    }

}

export default ListFooter;