import AppRequest from './AppRequest'

class CommentReq extends AppRequest {

    handlerTop(commentId,isTop,type){
        return this.post("/api_v1/comment/isTop",{commentId,type,status:isTop})
    }

}

const  commentReq = new CommentReq();
export default commentReq
