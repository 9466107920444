import React from "react";
import Nav from "../../../components/nav/Nav";
import "./audioDetail.scss";
import { Avatar, Button, message, BackTop, Icon } from "antd";
import { Link } from 'react-router-dom'
import WorkComment from "../../../components/work-comment/WorkComment";
import { connect } from "react-redux";
import LoginRegisterModal from "../../../layouts/login-register-modal/LoginRegisterModal";
import actions from "../../../redux/actions";
import CommentTextarea from "../../../components/comment-textarea/CommentTextarea";
import ListFooter from "../../listFooter/ListFooter";

import ContentNoFound from "../../../components/contentNoFound/ContentNoFound";
import audioReq from '../../../request/audioReq'
import commentReq from '../../../request/commentReq';
import utilFunc from "../../../utils/util";
import dateformat from "dateformat";
import AudioIcon from "../../../components/audioIcon/AudioIcon"
import IconFont from "../../../components/iconfont/icon-font";


const loadingStatus = {
    init: "init",
    firstLoading: "first-loading",
    loadingMore: "loading-more",
    firstError: "first-error",
    moreError: "more-error"
};
const CommentPerPage = 20;



class AudioDetail extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);

    }

    state = {
        loadingCommentListStatus: loadingStatus.init,
        comments: [],
        audioId: 9,
        noFound: false,
        commentLoadingStatus: "none", // 'loading','no-more','error'
        commentInputValue: null,
        submitCommentLoading: false,
        detail: {
        },
        firstLoaded: false,
        onlineCount: 0
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            isLogin: nextProps.isLogin,
            loginUserInfo: nextProps.loginUserInfo,
            currentPlayAudio: nextProps.currentPlayAudio,
            audioId: nextProps.audioId
        }
    }

    /* life circle method */
    componentDidMount() {
        this.getCommentList();
        this.refreshAudioOnlineCounts()

    }



    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.isLogin && !prevProps.isLogin) {
            this.checkIfAudioLiked()
            this.checkIfCommentLike(this.state.comments);
        }

        if (!prevState.firstLoaded || this.props.audioId !== prevProps.audioId) {
            this.setState({
                firstLoaded: true
            })
            this.getSeriesDetail(()=>{
                this.getDetail();
            })

        }
    }

    getSeriesDetail = (completeCK) => {

        audioReq.getAudioSeriesDetail(1)
            .then(res => {


                if (res.tags) {
                    res.tags = res.tags.split(",");
                }

                if (res.otherSources) {
                    try {
                        res.otherSources = JSON.parse(res.otherSources);
                    } catch (e) {
                        res.otherSources = null;
                    }


                }
                this.setState({
                    seriesDetail: res,
                }, () => {

                })
                if(completeCK){
                    completeCK()
                }

            })
            .catch(()=>{
                if(completeCK){
                    completeCK()
                }
            })
    }


    refreshAudioOnlineCounts = () => {

        this.refreshOnlineTimer = setInterval(() => {

            if (!this.state.audioId) {
                return
            }
            audioReq.queryAudioGroupOnlineCount([this.state.audioId])
                .then(res => {
                    let detail = this.state.detail || {};
                    detail.onlineCount = res && res[this.state.audioId] || 0
                    this.setState({
                        detail
                    })

                })
        }, 15000)

    };

    componentWillUnmount() {

        if (this.refreshOnlineTimer) {
            clearInterval(this.refreshOnlineTimer);
        }
    }


    getDetail = () => {

        if (!this.state.audioId) {

            return
        }
        audioReq
            .getAudioDetail(this.state.audioId)
            .then(detail => {

                utilFunc.setDocumentInfo({
                    title: detail && detail.title ? detail.title : "且曼播客",
                    keywords: detail && detail.tags ? detail.tags : null
                })
                // console.log(`detail`,detail);
                detail = this.formatAudio(detail)

                this.setState({
                    detail
                }, () => {
                    window.scrollTo(0, 0);
                    this.checkIfAudioLiked();
                    let deviceInfo = window.deviceInfo
                    // console.log(deviceInfo)
                    if(deviceInfo.isMobile){
                        if (this.props.onShowMiniCode && typeof this.props.onShowMiniCode === 'function') {
                            this.props.onShowMiniCode(this.state.detail);
                        }
                    }
                    this.getCommentList();
                    if (window.registerWeixinShare) {
                        window.registerWeixinShare({
                            title: detail.title,
                            description: detail.description,
                            shareIcon: this.state.seriesDetail ? this.state.seriesDetail.preview ? this.state.seriesDetail.preview.indexOf('//') === 0 ? `https:` +  this.state.seriesDetail.preview :this.state.seriesDetail.preview:"":"",
                            type:"audio",
                            sourceUrl:detail.sourceUrl
                        })
                    }

                })
            })
            .catch(error => {
                // console.log(error)
                if (error && error.code === 101101) {
                    return this.setState({
                        noFound: true
                    })
                }
                message.error(`发生错误，请稍后刷新`);
            });
    };
    commentPage = 0;
    getCommentList = () => {

        if (this.state.loadingCommentListStatus === loadingStatus.firstLoading || this.state.loadingCommentListStatus === loadingStatus.loadingMore) {
            return;
        }

        if (this.commentPage < 1) {
            this.setState({
                loadingCommentListStatus: loadingStatus.firstLoading,
                comments: []
            });
        } else {

            this.setState({
                loadingCommentListStatus: loadingStatus.loadingMore
            });
        }
        audioReq
            .getAudioCommentList(this.state.audioId, CommentPerPage, this.commentPage)
            .then(res => {
                let moreComments = res.rows;
                let count = res.count;
                let noMore = false;
                if (moreComments.length < CommentPerPage) {
                    noMore = true;
                }
                let comments = this.state.comments;
                if (this.commentPage < 1) {
                    comments = [];
                }
                this.checkIfCommentLike(moreComments);
                this.commentPage = this.commentPage + 1;
                this.setState({

                    comments: comments.concat(moreComments),
                    count,
                    noMoreComment: noMore,
                    loadingCommentListStatus: loadingStatus.init,
                });
            })
            .catch(error => {
                if (this.commentPage < 1) {
                    this.setState({
                        loadingCommentListStatus: loadingStatus.firstError
                    });
                } else {
                    this.setState({
                        loadingCommentListStatus: loadingStatus.moreError
                    });
                }
            });
    };

    checkIfCommentLike = targetComments => {
        if (this.state.isLogin) {
            if (!targetComments || !targetComments.length) {
                return;
            }
            let ids = [];
            if (targetComments && targetComments.length) {
                for (let pc of targetComments) {
                    ids.push(pc.id);
                    if (pc.replayTargetInfo && pc.replayTargetInfo.length) {
                        for (let cc of pc.replayTargetInfo) {
                            ids.push(cc.id);
                        }
                    }
                }
            }

            audioReq.checkIfLikeComments(ids).then(res => {
                let comments = this.state.comments;
                if (comments && comments.length) {
                    for (let pc of comments) {
                        if (res.hasOwnProperty(pc.id)) {
                            pc.isLike = res[pc.id];
                        }
                        if (pc.replayTargetInfo && pc.replayTargetInfo.length) {
                            let newArray = []
                            for (let cc of pc.replayTargetInfo) {
                                if (res.hasOwnProperty(cc.id)) {
                                    cc.isLike = res[cc.id];
                                }
                                newArray.push({ ...cc });
                            }

                            pc.replayTargetInfo = newArray
                        }
                    }
                    this.setState({
                        comments: [].concat(comments)
                    });
                }
            });
        }
    };

    childCommentDelete = (commentId, rowIndex, parentRowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments || [];
        let comment = comments[parentRowIndex] || {};
        let childComment = comment.replayTargetInfo
            ? comment.replayTargetInfo[rowIndex] || {}
            : {};
        if (
            childComment.id != commentId ||
            !childComment.userInfo ||
            !this.state.loginUserInfo ||
            childComment.userInfo.id != this.state.loginUserInfo.id
        ) {
            return;
        }
        audioReq
            .deleteComment(commentId)
            .then(res => {
                let comments = this.state.comments || [];
                let comment = comments[parentRowIndex] || {};
                comment.replayTargetInfo.splice(rowIndex, 1);
                let na = [].concat(comment.replayTargetInfo);
                comment.replayTargetInfo = na;
                let newComment = { ...comment };
                comments[parentRowIndex] = newComment;
                this.setState({
                    comments: [].concat(comments)
                });
                message.success(`评论已删除`);
            })
            .catch(error => {
                message.error(`删除失败`);
            });
    };
    parentCommentDelete = (commentId, rowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments || [];

        let comment = comments[rowIndex] || {};
        if (
            comment.id != commentId ||
            !comment.userInfo ||
            !this.state.loginUserInfo ||
            comment.userInfo.id != this.state.loginUserInfo.id
        ) {
            return;
        }
        audioReq
            .deleteComment(commentId)
            .then(res => {
                let comments = this.state.comments || [];
                comments.splice(rowIndex, 1);
                this.setState({
                    comments: [].concat(comments)
                });
                message.success(`评论已删除`);
            })
            .catch(error => {
                message.error(`删除失败`);
            });
    };

    parentCommentLike = (commentId, rowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments;
        let comment = comments[rowIndex];
        if (comment.isLike) {
            audioReq.unlikeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[rowIndex];
                comment.isLike = false;
                comment.likeNum = parseInt(comment.likeNum)
                    ? parseInt(comment.likeNum) - 1
                    : 0;
                let newComment = {}
                Object.assign(newComment, comment);
                comments[rowIndex] = newComment
                // console.log('no点赞',res,commentId)
                this.setState({
                    comments: [].concat(comments)
                });
            });
        } else {
            audioReq.likeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[rowIndex];
                comment.isLike = true;
                // console.log('点赞',res,commentId)
                comment.likeNum = parseInt(comment.likeNum) + 1;
                let newComment = {}
                Object.assign(newComment, comment);

                comments[rowIndex] = newComment
                this.setState({
                    comments: [].concat(comments)
                });
            });
        }
    };

    childCommentLike = (commentId, rowIndex, parentRowIndex) => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        let comments = this.state.comments;
        let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
        if (comment.isLike) {
            audioReq.unlikeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
                comment.isLike = false;
                comment.likeNum = parseInt(comment.likeNum)
                    ? parseInt(comment.likeNum) - 1
                    : 0;
                let newComment = {}
                Object.assign(newComment, comment);
                comments[parentRowIndex].replayTargetInfo[rowIndex] = newComment
                let newChildComments = []
                newChildComments = newChildComments.concat(comments[parentRowIndex].replayTargetInfo);
                comments[parentRowIndex].replayTargetInfo = newChildComments;
                this.setState({
                    comments: [].concat(comments)
                });
            });
        } else {
            audioReq.likeComment(commentId).then(res => {
                let comments = this.state.comments;
                let comment = comments[parentRowIndex].replayTargetInfo[rowIndex];
                comment.isLike = true;
                comment.likeNum = parseInt(comment.likeNum) + 1;
                let newComment = {}
                Object.assign(newComment, comment);

                comments[parentRowIndex].replayTargetInfo[rowIndex] = newComment
                let newChildComments = []
                newChildComments = newChildComments.concat(comments[parentRowIndex].replayTargetInfo);
                comments[parentRowIndex].replayTargetInfo = newChildComments;
                this.setState({
                    comments: [].concat(comments)
                });
            });
        }
    };

    onPostReplay = (content, comment, key, target) => {
        audioReq
            .postComment({
                audioId: this.state.detail.id,
                content: `@${comment.userInfo.nickname ||
                    comment.userInfo.username} ${content}`,
                replyTargetId: comment.rootTargetId ? comment.rootTargetId : comment.id
            })
            .then(res => {

                message.success(`已成功回复`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                let c = this.state.comments[key];
                c.replayTargetInfo = c.replayTargetInfo || [];
                c.replayTargetInfo = [res].concat(c.replayTargetInfo);
                target.clearContent();
                target.showCommentTextarea(false);
                this.setState({
                    comments
                });

            })
            .catch(error => {
                if (error && error.code === 7706) {
                    return message.error(`此音频已关闭评论功能`)
                }
                message.error(`回复失败，请稍后重试`);
            });
    };

    onSelfPostReplay = (content, comment, key, target) => {
        audioReq
            .postComment({
                audioId: this.state.detail.id,
                content: `${content}`,
                replyTargetId: comment.rootTargetId ? comment.rootTargetId : comment.id
            })
            .then(res => {

                message.success(`已成功回复`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                let c = this.state.comments[key];
                c.replayTargetInfo = c.replayTargetInfo || [];
                c.replayTargetInfo = [res].concat(c.replayTargetInfo);
                target.clearContent();
                target.showCommentTextarea(false);
                this.setState({
                    comments
                });

            })
            .catch(error => {
                if (error && error.code === 7706) {
                    return message.error(`已关闭评论功能`)
                }
                message.error(`回复失败，请稍后重试`);
            });
    };

    pushComment = (value, target) => {
        audioReq
            .postComment({
                audioId: this.state.audioId,
                content: value
            })
            .then(res => {

                message.success(`评论发布成功`);
                let comments = this.state.comments || [];
                res.userInfo = this.state.loginUserInfo;
                comments = [res].concat(comments);
                this.setState({
                    comments
                });
                target.clearContent();
            })
            .catch(error => {
                if (error && error.code === 7706) {
                    return message.error(`已关闭评论功能`)
                }
                message.error(`评论失败，请稍后重试`);
            });
    };





    checkIfAudioLiked() {
        if (this.state.isLogin) {
            audioReq
                .checkIfLikeAudio(this.state.audioId)
                .then(res => {

                    let detail = this.state.detail || {};
                    detail.hasLiked = !!res.hasLike;
                    this.setState({
                        detail
                    });
                })
                .catch(error => {
                });
        }
    }

    handlerLikeAudio = () => {
        if (!this.state.isLogin) {
            return window.appLogin();
        }
        if (!this.state.detail) {
            return;
        }
        audioReq
            .handlerLikeAudio(
                this.state.audioId,
                !this.state.detail.hasLiked
            )
            .then(res => {
                let detail = this.state.detail || {};
                detail.hasLiked = !detail.hasLiked;
                this.setState({
                    detail
                });
                if (detail.hasLiked) {
                    message.success("已赞");
                } else {
                    message.success("已取消赞");
                }
            })
            .catch(error => {
                message.error("操作失败");
            });
    };
    formatAudio(audio) {
        if (audio && audio.duration) {
            audio.duration_fm = this.formatDuration(audio.duration);
        }

        if (audio && audio.tags && typeof audio.tags === "string") {
            audio.tags = audio.tags.split(',')
        }
        if (audio && audio.createdAt) {
            audio.createdAtParsed = dateformat(new Date(audio.createdAt), 'yyyy-mm-dd')
        }
        return audio
    }
    formatDuration(duration) {
        if (!duration) {
            return "00:00";
        }
        if (duration < 10) {
            return `00:0${duration}`
        } else if (duration < 60) {
            return `00:${duration}`
        } else if (duration / 60 < 10) {
            let second = duration % 60;
            let minute = parseInt(duration / 60);
            return `0${minute}:${second < 10 ? "0" + second : second}`
        } else {
            let second = duration % 60;
            let minute = parseInt(duration / 60);
            return `${minute}:${second < 10 ? "0" + second : second}`
        }
    }

    commentInputOnShow = target => {
        if (this.openCommentTarget) {
            if (this.openCommentTarget.clearContent) {
                this.openCommentTarget.clearContent();
            }

            if (this.openCommentTarget.showCommentTextarea) {
                this.openCommentTarget.showCommentTextarea(false);
            }
            this.openCommentTarget = null;
        }

        this.openCommentTarget = target;
    };

    handlerTopComment = (comment,rowIndex)=>{

        commentReq.handlerTop(comment.id,!comment.isTop,"audio")
            .then(res =>{
                let comments = this.state.comments || [];
                let findIndex = comments.findIndex(item => item.id === comment.id);
                if(findIndex >= 0){
                    if(comment.isTop){

                        message.success('已取消置顶')
                    }else {

                        message.success('已置顶')
                    }
                    this.commentPage = 0;
                    this.setState({
                        commentPage:0,
                        loadingCommentListStatus:loadingStatus.init,
                        comments:[]
                    },()=>{
                        this.getCommentList();
                    })

                }
            })
            .catch(e =>{
                message.error('操作失败，请重试')
            })

    };

    render() {
        let {
            detail, noFound, loadingCommentListStatus,
            noMoreComment, comments, loginUserInfo, isLogin,
            currentPlayAudio,
            onlineCount

        } = this.state;


        if (noFound) {


            return <div className="audio-page">
                <BackTop />
                <Nav />
                <LoginRegisterModal />
                <div className={"content"}>
                    <ContentNoFound text={`播客音频不存在或已被删除`} />;
                </div>
            </div>
        }

        let commentList = null

        if (comments && comments.length) {
            commentList = comments.map((item, index) => {
                return (
                    <WorkComment
                        data={item}
                        childComments={item.replayTargetInfo}
                        key={item.id}
                        compKey={item.id}
                        rowIndex={index}
                        onSelfPostReplay={this.onSelfPostReplay}
                        onPostReplay={this.onPostReplay}
                        openReplayInput={this.commentInputOnShow}
                        loginUserId={
                            loginUserInfo && loginUserInfo.id ? loginUserInfo.id : null
                        }
                        handlerDelete={this.parentCommentDelete}
                        handlerChildDelete={this.childCommentDelete}
                        handlerLike={this.parentCommentLike}
                        handlerChildLike={this.childCommentLike}
                        showTopComment={this.props.canTopComment}
                        handlerTopComment={this.handlerTopComment}
                    />
                );
            });
        }


        return (
            <div className="audio-detail-page">
                <BackTop />

                <LoginRegisterModal />
                <div className={'audio-content'}>
                    <div className="sub-props">
                        {
                            detail && detail.tags ? detail.tags.map((tag, idx) => {
                                return <Link className='text' to={`/podcast#${tag}`} key={idx}>#{tag}</Link>
                            }) : null
                        }
                        <div className='text'>{detail.tags ? " // " : ''}{detail.createdAtParsed}</div>
                        <div className='text'>{" // " + detail.playNum + "次播放"}</div>
                        {(currentPlayAudio && currentPlayAudio.id === detail.id) || (detail && detail.onlineCount > 0) ? <div className='text'>{"//"}&nbsp;<AudioIcon />{(currentPlayAudio && currentPlayAudio.id === detail.id ? currentPlayAudio.onlineCount || 1 : detail && detail.onlineCount ? detail.onlineCount : 0) + "人正在收听"}</div> : null}

                        {/* <img className={`qr-code`} hidden={!detail || !detail.wxQRCode} src={detail && detail.wxQRCode ? detail.wxQRCode : ""} onClick={() => {
                            if (this.props.onShowMiniCode && typeof this.props.onShowMiniCode === 'function') {
                                this.props.onShowMiniCode(detail);
                            }
                        }} alt="" /> */}

                    </div>
                    <div className={'header'}>
                        <img className="podcast-icon xs-hidden" src="/icons/voice-podcast.png" alt="" />
                        <div className="play-title">{detail.title}
                        </div>


                        {/* <div className="play-duration">{detail.duration_fm}</div> */}
                        {/* <img onClick={() => {
                            if (detail && this.props.onClickPlay) {
                                this.props.onClickPlay(detail)
                            }
                        }} data-index={0} src={currentPlayAudio && currentPlayAudio.id === detail.id && !this.props.paused ? '/icons/pause.png' : '/icons/play.png'} className="play-btn" alt="" /> */}
                        <div className="play-btn" onClick={() => {
                            if (detail && this.props.onClickPlay) {
                                this.props.onClickPlay(detail)
                            }
                        }}>
                            {
                                currentPlayAudio && currentPlayAudio.id === detail.id && !this.props.paused ? <Icon type="pause-circle" theme="filled" /> : <Icon type="play-circle" theme="filled" />
                            }
                        </div>
                    </div>
                    <div className="podcast-mini" onClick={() => {
                        if (this.props.onShowMiniCode && typeof this.props.onShowMiniCode === 'function') {
                            this.props.onShowMiniCode(detail);
                        }
                    }}>
                        <IconFont className="icon" type="iconxiaochengxu" />
                        <div className="podcast-mini-text">用小程序收听</div>
                    </div>
                    <div className={`audio-rich-text`} dangerouslySetInnerHTML={{ __html: detail && detail.content ? detail.content : "" }}></div>

                </div>
                <h2>发布评论</h2>
                <div className={`comment-list`} id={'shot-detail-comment-list-top'}
                    hidden={!detail || !Object.getOwnPropertyNames(detail).length}>
                    <div
                        className={"bks-work-textarea"}
                        hidden={!this.state.isLogin}
                    >
                        <Avatar
                            className={"avatar"}
                            src={
                                loginUserInfo && loginUserInfo.avatar
                                    ? loginUserInfo.avatar
                                    : ""
                            }
                        />
                        <CommentTextarea
                            show={true}
                            onSubmit={this.pushComment}
                        />
                    </div>
                    <div className={`login-remind`} hidden={this.state.isLogin}>
                        <Button
                            type={`primary`}
                            onClick={window.appLogin}
                        >
                            登录后留言
                        </Button>
                    </div>
                    {commentList}
                    <ListFooter
                        show={[loadingStatus.loadingMore, loadingStatus.moreError, loadingStatus.init].indexOf(loadingCommentListStatus) >= 0 && comments.length}
                        status={noMoreComment ? 'no-more' : loadingCommentListStatus === loadingStatus.loadingMore ? 'loading-more' : "loading-error"}
                        onLoadingMore={this.getCommentList} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo,
    canTopComment:state.account.canTopComment
});

const mapDispatchToProps = {
    handlerShowLoginRegisterModal: actions.accountShowLoginRegisterModal
};


export default connect(mapStateToProps, mapDispatchToProps)(AudioDetail);
