
import React from 'react'

import './ovalLoading.scss'
class OvalLoading extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }
    state = {
        show: false
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            show:nextProps.show,
            className:nextProps.className,
            style:nextProps.style
        };
    }

    render() {
        if(this.state.show){
            return <div hidden={!this.state.show} className={`comp-oval-loading ${this.state.className || ""}`} style={{ ...this.state.style }} >
                <img src="/icons/svg/oval.svg" alt=""/>
            </div>
        }else {
            return null;
        }

    }

}

export default OvalLoading;