
import React from "react";
class AudioIcon extends React.Component {
    render() {
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="24px" height="10px" xmlSpace="preserve">
                <rect x="0" y="10" width="4" height="10" fill="#3945f9" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="1.2s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="6; 12; 6" begin="0s" dur="1.2s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="6; 3; 6" begin="0s" dur="1.2s" repeatCount="indefinite" />
                </rect>
                <rect x="6" y="10" width="4" height="10" fill="#3945f9" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="1.2s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="6; 12; 6" begin="0.3s" dur="1.2s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="6; 3; 6" begin="0.15s" dur="1.2s" repeatCount="indefinite" />
                </rect>
                <rect x="12" y="10" width="4" height="10" fill="#3945f9" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.6s" dur="1.2s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="6; 12; 6" begin="0.6s" dur="1.2s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="6; 3; 6" begin="0.6s" dur="1.2s" repeatCount="indefinite" />
                </rect>
            </svg>
        )
    }

}


export default AudioIcon;