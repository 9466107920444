/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "./footer.scss"
import {Link} from 'react-router-dom'
import indexReq from "../../request/indexReq";


const cooperationLinks = [{
    name: "FancyNode",
    href: ""

}, {
    name: "爱果果",
    href: ""

}, {
    name: "牛大拿导航",
    href: ""
}, {
    name: "莫贝网",
    href: ""
}, {
    name: "图趣网",
    href: ""
},{
    name: "Mockups",
    href: ""
},{
    name: "秀设计",
    href: ""
},{
    name: "Designup",
    href: ""
},{
    name: "设计开开眼",
    href: ""
},{
    name: "汇漫网",
    href: ""
},{
    name: "优创意",
    href: ""
},{
    name: "蓝湖",
    href: ""
},{
    name: "UI模板",
    href: ""
}];

const navItems = [
    {
        name:"问答",
        href:"/",

    },
    {
        name:"作品",
        href:"/shot",
    },
    {
        name:"文章",
        href:"/article",
    },

];

class Footer extends React.Component {
    constructor(props) {
        super();
    }

    state = {
        links:[],
        navGroups:[]
    }


    static  getDerivedStateFromProps(nextProps,prevState){

        return nextProps;
    }
    componentDidMount() {
        indexReq.getFooterFriendLinks()
            .then(res =>{
                this.setState({
                    links:res
                })
            })

        indexReq.getFooterNavGroups()
            .then(res =>{
                this.setState({
                    navGroups:res
                })
            })
    }

    render() {
        let { links ,navGroups} = this.state;

        let friendLinks = links.map((item,index)=>{
            return <a href={item.url} key={index} target={"_blank"}>{item.name || ""}</a>
        })


        return (
            <div className={`comp-footer ${this.props.className || ''}`}>
                <img className="footer-bg-item" src="/bg/bg_item4.png" alt=""/>
                <img className="footer-bg-item" src="/bg/bg_item5.png" alt=""/>
                <img className="footer-bg-item" src="/bg/bg_item6.png" alt=""/>
                <div className='container'>
                    <div className={'items'}>
                        {
                            navGroups.map((group,groupIndex)=>{
                                let links = [];
                                if(group.categories && group.categories.length){
                                    links = group.categories.map((link,linkIndex)=>{
                                        return <a href={link.url} target={`_blank`} key={linkIndex}>{link.name || ""}</a>
                                    })
                                }
                                return <div className={`nav-group`} key={groupIndex}>
                                    <div className={`group-title`}>{group.name || ""}</div>
                                    <div className={`links`}>{links}</div>
                                </div>
                            })
                        }
                    </div>
                    <div className={'cooperation-links'}>
                        <div className={'title'} hidden={!links.length}>友情链接：</div>
                        {friendLinks}
                    </div>
                    <div className={'copyright'}>优阁网－UIGREAT 所有 <a href="http://beian.miit.gov.cn/">京ICP备15031701号-2</a></div>
                </div>
            </div>
        )
    }
}


export default Footer;