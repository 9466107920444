import React from 'react'
import IconFont from "../iconfont/icon-font";
import './ContentNoFound.scss'
export default  props =>{
    return (
        <div className={`comp-content-no-found`}>
            <IconFont className={`icon`} type={'iconempty'} />
            <div className={`tip`}>{props.text || "没有找到相关内容"}</div>
        </div>
    )
}