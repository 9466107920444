import React from 'react';

import '../workComment.scss'

import IconFont from "../../iconfont/icon-font";

import {Avatar} from "antd";

import CommentTextarea from "../../comment-textarea/CommentTextarea"
import {parseTimelineDate} from "../../../utils/dataformat";
import {connect} from 'react-redux'

class WorkCommentChild extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
        this.commentTextAreaRef = React.createRef()

    }

    state = {
        isOpen: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            isChild:nextProps.isChild,
            key: nextProps.rowKey,
            className: nextProps.className,
            style: nextProps.style,
            data: nextProps.data,
            rowIndex:nextProps.rowIndex,
            childComments: nextProps.childComments,
            parentIndex:nextProps.parentIndex,
            loginUserId:nextProps.loginUserId,
            isLogin:nextProps.isLogin
        };
    }

    openReplay = () => {
        if(!this.state.isLogin){
            return window.appLogin()
        }
        let isOpen = this.state.isOpen
        if(!isOpen){
            if(this.props.openReplayInput){
                this.props.openReplayInput(this);
            }
        }
        this.setState({
            isOpen: !isOpen
        })

    }

    onPostReplay = value =>{

        if(this.props.onPostReplay){
            this.props.onPostReplay(value,this.state.data,this);
        }
    }

    clearContent(){
        if(this.commentTextAreaRef && this.commentTextAreaRef.current){
            this.commentTextAreaRef.current.clearContent();
        }

    }

    showCommentTextarea(isShow){
        this.setState({
            isOpen:isShow
        })
    }

    handlerDelete = ()=>{
        if(this.props.handlerDelete){
            this.props.handlerDelete(this.state.data.id,this.state.rowIndex);
        }
    }

    handlerLike = ()=>{
        if(!this.state.isLogin){
            return window.appLogin()
        }
        if(this.props.handlerLike){
            this.props.handlerLike(this.state.data.id,this.state.rowIndex);
        }
    };

    render() {

        let {className, style, key, data,loginUserId} = this.state;

        let createTime = data && data.createdAt ? parseTimelineDate(data.createdAt) : "";
        if (this.props.publishTime) {
            if (typeof this.props.publishTime === "function") {
                createTime = this.props.publishTime(createTime)
            } else {
                createTime = this.props.publishTime;
            }
        }

        let commentArea = null;
        if(this.state.isOpen){
            commentArea =  <CommentTextarea ref={this.commentTextAreaRef} show={this.state.isOpen} okText={'回复'} onSubmit={this.onPostReplay} placeholder={ `回复 ${data && data.userInfo && data.userInfo.nickname ? data.userInfo.nickname :""}`}/>
        }
        return (
            <div className={`bks-work-comment ${className || ""}`} style={style || {}} key={key}>
                <Avatar className={'avatar'} src={data && data.userInfo ? data.userInfo.avatar : ""}/>
                <div className={'flex-r'}>
                    <label className={'nickname'}>
                        {data && data.userInfo ? data.userInfo.nickname : ""}
                    </label>
                    <div className={'com-cnt'}>{data ? data.content : ""}</div>
                    <div className={'bottom-panel'}>
                        <label className={'publish-time'}>{createTime}</label>
                        <label className={'like'}>
                            <IconFont type="iconlike2" className="iconlike2" style={{color:this.props.data  ?  this.props.data.isLike ? 'red':"":""}} onClick={this.handlerLike}></IconFont>
                            <span>{this.props.data && this.props.data.likeNum || 0}</span>
                        </label>
                        <label className={'reply'} onClick={this.openReplay}>回复</label>
                        {/*<Divider type={'vi'}/>*/}
                        <label className={'reply'} style={{marginLeft:15}}  hidden={!data || !data.userInfo || !data.userInfo.id || !loginUserId || loginUserId != data.userInfo.id} onClick={this.handlerDelete}>删除</label>
                    </div>
                    {commentArea}
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    isLogin: state.account.isLogin,
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(WorkCommentChild);