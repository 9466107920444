import React from 'react';
import { Icon, Button } from 'antd'
import './accountCourseListCard.scss'
import dateformat from 'dateformat';
import { Link } from 'react-router-dom'
class AccountCourseListCard extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    render() {
        let type = this.state.type;
        let record = null;
        let btnTxt = null;
        let data = this.state.data || {};
        let time = '';
        let title = ''
        let lessonIndex = 1;
        let preview = null;
        switch (type) {
            case 'recent':



                btnTxt = <div className="account-course-btn" onClick={() => {
                    let data = this.state.data;
                    if (data && data.course && this.props.goToCoursePage) {
                        this.props.goToCoursePage(data.course.id);

                    }
                }}><Icon type="right" className="xs-show" /><span className="xs-hidden">继续学习</span></div>
                let courseRecord = data && data.latestRecord ? data.latestRecord : {};
                time = courseRecord.updatedAt ? dateformat(new Date(courseRecord.updatedAt),'yyyy/mm/dd HH:MM'):"";
                record = <div className="account-course-info">上次看到 <span>第{courseRecord ? courseRecord.lessonIndex : ''}节</span></div>;
                title = data && data.course ? data.course.title : "";
                preview = data && data.course ? data.course.preview : ""
                break;

            case 'purchased':
                record = <div className="account-course-info">已完成课时 <span>3/36</span></div>;
                btnTxt = <div className="account-course-btn"><Icon type="right" className="xs-show" /><span className="xs-hidden">继续学习</span></div>;
                title = data ? data.title : "";
                preview = data ? data.preview : ""
                break;

            case 'collection':
                record = <div className="account-course-info">总课时 <span>36</span></div>;
                btnTxt = <div className="account-course-btn">取消收藏</div>;
                break;

            case 'subscribed':
                record = <div className="account-course-info">讲师 <span>limi</span></div>;
                title = data ? data.title : "";
                preview = data ? data.preview : ""
                break;

            default:
                break;
        }
        return (
            <div className="account-course" key={this.state.key}>
                <div className="account-course-cover">
                    <div className="account-course-cover-inner">
                        <img src={preview} />
                    </div>
                </div>
                <div className="account-course-txt">
                    <div className="account-course-title">{title}</div>
                    {record}
                    <div className="account-course-time">{time}</div>
                </div>
                <div className="account-course-btn-panel">
                    {btnTxt}
                </div>
            </div>
        )
    }
}


export default AccountCourseListCard;