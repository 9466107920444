import systemTypes from '../actionTypes/systemTypes'
import {combineReducers} from "redux";


const {SYSTEM_SET_WINDOW_SIZE, SYSTEM_SET_INPUT,SYSTEM_BULLETIN} = systemTypes;

function systemSetWindowSizeReducer(state = {width: 0, height: 0}, action) {
    switch (action.type) {
        case SYSTEM_SET_WINDOW_SIZE:
            return action.payload;
        default:
            return state;
    }
}

function systemSetInputReducer(state = false, action) {
    switch (action.type) {
        case SYSTEM_SET_INPUT:
            return action.payload;
        default:
            return state;
    }
}

function systemShowBulletinReducer(state = {}, action) {
    switch (action.type) {
        case SYSTEM_BULLETIN:
            return action.payload;
        default:
            return state;
    }
}


const indexReducer = combineReducers({
    windowSize: systemSetWindowSizeReducer,
    documentOnInput:systemSetInputReducer,
    systemBulletin:systemShowBulletinReducer
})

export default indexReducer