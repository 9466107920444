import AppRequest from './AppRequest'
const urlPostComment = '/api_v1/comment/post';
const urlGetShotList = '/api_v1/shot/list';
const urlGetEditShotDetail = '/api_v1/shot/edit/info';
const urlChangePublish = '/api_v1/shot/status/update';
const urlGetShotDetail = '/api_v1/shot/detail';
const urlGetShotCategories = '/api_v1/shot/category/with/shot/list'
const urlGetAllShotCategories = '/api_v1/shot/category/list'
const urlGetComments = '/api_v1/comment/list'
const urlLikeComment = '/api_v1/comment/like';
const urlUnlikeComment = '/api_v1/comment/delete/like'
const urlCheckIfLikeComments = '/api_v1/comment/istrue'
const urlHandlerShot = '/api_v1/shot/post'
const urlDeleteComment = '/api_v1/comment/delete';
const urlCheckIfLikeShot = '/api_v1/shot/check/like';
const urlCheckIfCollectShot = '/api_v1/shot/check/collect';
const urlHandlerCollectShot = '/api_v1/shot/collect';
const urlHandlerLikeShot = '/api_v1/shot/like';
const urlHandlerRecommendShot = '/api_v1/shot/isRecommend/update';
const urlGetMyShots = '/api_v1/shot/post';
const urlGetMyCollectShots = '/api_v1/shot/my/collect';
const urlGetMyShotDetail = '/api_v1/shot/my/shot/detail';
const urlCancelLinkCourse = '/api_v1/shot/course/record/delete';
const urlGetShotLinkedCourse = '/api_v1/shot/record/course/list';
const urlGetCommentLikeCount = '/api_v1/comment/check/comment/like'
class ShotRequest extends AppRequest {

    getShotLinkedCourse(shotId){
        return this.get(urlGetShotLinkedCourse,{shotId,perPage:1000000})
    }

    cancelLinkCourse(shotId,courseId){
        return this.get(urlCancelLinkCourse,{shotId,courseId});
    }
    getMyShots(page = 0,perPage = 15){
        return this.get(urlGetMyShots,{page,perPage});
    }
    getMyShotDetail(shotId){
        return this.get(urlGetMyShotDetail,{id:shotId});
    }
    getMyCollectShots(page = 0,perPage = 15){
        return this.get(urlGetMyCollectShots,{page,perPage});
    }
    checkIfLikeShot(shotId){
        return this.get(urlCheckIfLikeShot,{shotId});
    }
    checkIfCollectShot(shotId){
        return this.get(urlCheckIfCollectShot,{shotId});
    }
    handlerCollectShot(shotId,isCollect){
        return this.post(urlHandlerCollectShot,{shotId,status:isCollect});
    }

    handlerLikeShot(shotId,isLike){
        return this.post(urlHandlerLikeShot,{shotId,status:isLike});
    }
    handlerRecommendShot(shotId,isRecommend){
        return this.post(urlHandlerRecommendShot,{id:shotId,status:isRecommend});
    }

    likeComment(commentId){
        return this.post(urlLikeComment,{commentId,type:"shot"});
    }
    unlikeComment(commentId){
        return this.delete(urlUnlikeComment,{commentId,type:"shot"});
    }
    getCommentLikeCount(commentIds){
        return this.post(urlGetCommentLikeCount,{type:"shot",commentIds})
    }
    checkIfLikeComments(commentIds){
        return this.get(urlCheckIfLikeComments,{commentId:commentIds,type:'shot'})
    }
    deleteComment(commentId){
        return this.delete(urlDeleteComment,{type:"shot",commentId})
    }
    postComment(info){
        return this.post(urlPostComment,{...info,type:'shot'});
    }
    getComments(shotId,perPage = 15,page = 0){
        return this.get(urlGetComments,{shotId,type:"shot",perPage,page})
    }
    getShotDetail(shotId){
        return this.get(urlGetShotDetail,{id:shotId});
    }
    getShotList(condition){
        return this.get(urlGetShotList,condition)
    }
    getEditShotDetail(shotId){
        return this.get(urlGetEditShotDetail,{id:shotId});
    }
    createShot(info){
        return this.post(urlHandlerShot,info);
    }
    updateShot(info){
        return this.put(urlHandlerShot,info);
    }
    deleteShot(shotId){
        return this.delete(urlHandlerShot,{id:shotId});
    }
    changePublish(shotId,isPublish){
        return this.post(urlChangePublish,{id:shotId,status:!!isPublish});
    }

    getShotCategories(){
        return this.get(urlGetShotCategories);
    }
    getAllShotCategories(){
        return this.get(urlGetAllShotCategories);
    }

}

const  shotReq = new ShotRequest();
export default shotReq
