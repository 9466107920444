import React from 'react';

import {Row,Col} from 'antd'
import {Link} from "react-router-dom";
import AllCourseListCard from "../allCourseListCard/AllCourseListCard";
import SkeletonShotListCard from "../SkeletonShotListCard/SkeletonShotListCard";
import SkeletonCourseListCard from "../SkeletonCourseListCard/SkeletonCourseListCard";

class AllCourseList extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }


    render() {
        let {courses,showSkeleton,skeletonCount} = this.state;
         courses = courses || [];
        let courseCards = []
         if(showSkeleton){
             skeletonCount = skeletonCount || 12;
             for (let i = 0; i < skeletonCount; i++) {
                 courseCards.push(
                     <Col xs={12} sm={12} md={8} lg={8} xl={6} key={i}>
                        <SkeletonCourseListCard/>
                     </Col>
                 )
             }
         }else {
             courseCards = courses.map((item, index) => {

                 return <Col xs={12} sm={12} md={8} lg={8} xl={6} key={item.id}>
                     <Link title={item.title} to={`/course/${item.id}`}>
                         <AllCourseListCard  data={item} bgColor={this.state.colors ? this.state.colors[item.preview]:null}/>
                     </Link>

                 </Col>
             });
         }

        return (
            <Row gutter={[{xs: 8, sm: 16}, {xs: 8, sm: 16}]}>
            {courseCards}
        </Row>
        )
    }
}


export default AllCourseList;