import AppRequest from './AppRequest'

const urlLogin = '/api_v1/account/login';
const urlGetAuthUserInfo = '/api_v1/account/info';
const urlLogout = '/api_v1/account/logout';
const urlCheckIfUsernameValid = '/api_v1/account/username/check/valid';
const urlCheckIfNicknameValid = '/api_v1/account/nickname/check/valid';
const urlRegister = '/api_v1/account/register-by-email';
const urlGetUserPublicInfoWithUsername = '/api_v1/account/public/info';
const urlGetFollowing = '/api_v1/account/following';
const urlFollowUser = '/api_v1/account/follow';
const urlCheckIfFollowUser = '/api_v1/account/follow/check';
const urlRecordVisitor = '/api_v1/account/visitor/record';
const urlUpdateAccountInfo = '/api_v1/account/info/update';
const urlGetHomeVisitors = '/api_v1/account/visitor';
const urlHandlerCollectCourse = '/api_v1/course/collect';
const urlCheckIfCourseCollected = '/api_v1/course/check/collect'
const urlGetMyMessages = '/api_v1/account/message/list';
const urlFlagMessageRead = '/api_v1/account/message/update';
const urlGetTeacherList = '/api_v1/course/teacher/list';
class AccountRequest extends AppRequest {

    getTeacherList(){
        return this.get(urlGetTeacherList)
    }
    checkIfCourseCollected(courseId){
        return this.get(urlCheckIfCourseCollected,{courseId});
    }
    handlerCollectCourse(courseId,hasCollected){
        return this.post(urlHandlerCollectCourse,{
            courseId,
            status : hasCollected ? "true":"false"
        })
    }
    getHomeVisitors(userId){
        return this.get(urlGetHomeVisitors,{userId});
    }
    updateAccountInfo(updateInfo){
        return this.put(urlUpdateAccountInfo,updateInfo);
    }
    recordVisitor(targetUserId){
        return this.post(urlRecordVisitor,{targetUserId})
    }
    followUser(targetUserId){
      return this.post(urlFollowUser,{targetUserId})
    }
    cancelFollowUser(targetUserId){
        return this.delete(urlFollowUser,{targetUserId})
    }

    checkIfFollowUser(targetUserIds){
        return this.get(urlCheckIfFollowUser,{targetUserIds});
    }

    getFollowing(userId,perPage,page){
        return this.get(urlGetFollowing,{userId,perPage,page});
    }
    getUserPublicInfoWithUsername(username){
        return this.get(urlGetUserPublicInfoWithUsername,{username})
    }

    register(info){
        return this.post(urlRegister,info);
    }
    checkIfUsernameValid(name){
        return this.get(urlCheckIfUsernameValid,{name});
    }
    checkIfNicknameValid(name){
        return this.get(urlCheckIfNicknameValid,{name});
    }
    logout(){
        return this.get(urlLogout);
    }
    login(account,password){
        return this.post(urlLogin,{account,password})
    }
    getAuthUserInfo(){
        return  this.get(urlGetAuthUserInfo);
    }

    getMyMessages(type,page,perPage){
        return this.get(urlGetMyMessages,{type,perPage,page})
    }

    flagMessageRead(messageIds){
        return this.post(urlFlagMessageRead,{ids:messageIds})
    }

    canLoginUserTopComment(){
        return this.get("/api_v1/account/power/top-comment")
    }

    topComment(type,commentId,isTop){
        return this.post("/api_v1/comment/isTop",{type,commentId,status:isTop})
    }

}

const  accountReq = new AccountRequest();
export default accountReq;