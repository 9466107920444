
import React from "react";
import './card.scss'
import CardHeader from "../cardHeader/CardHeader";
class Card extends  React.Component{

    constructor(props) {
        super(props);
        Object.assign(this.state,props);
    }
    state = {

    };
    static  getDerivedStateFromProps(nextProps,prevState){

        return nextProps;
    }

    render() {
        return (
            <div className={`comp-card ${ this.state.className || ""}`} style={this.state.style || {}} hidden={this.state.hidden || false}>
                <div className="card-header">
                    <CardHeader title={this.state.title}></CardHeader>
                </div>
                <div className="card-body">
                    {this.props.children}
                </div>
            </div>
        )
    }

}


export default Card;