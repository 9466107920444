import React from 'react';
import "./mobileCategoryTabs.scss"
import {Icon, Menu} from 'antd'
import storage from "../../storage/storage";
const {SubMenu} = Menu;

const STORAGE_CACHED_KEY_COMP_MobileCategoryTabs = "STORAGE_CACHED_KEY_COMP_MobileCategoryTabs"
class MobileCategoryTabs extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {current: '-1', selectedKeyPath: []};

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    handleClick = e => {
        let keyPath = e.keyPath || [];
        let selectedKeyPath = [];
        let categoryId = -1;
        if (keyPath.length == 2) {
            let cIndex = keyPath[0].split('-')[1];
            let gIndex = keyPath[1];
            selectedKeyPath = [gIndex, cIndex];
            categoryId = this.state.groups[gIndex].categories[cIndex].id;
        }

        this.setState({
            current: e.key,
            selectedKeyPath: selectedKeyPath
        });
        if (this.props.onSearch) {
            this.props.onSearch(categoryId)
        }

    };
    componentDidMount() {

        let key = this.props.cacheKey ? "STORAGE_CACHED_KEY_MobileCategoryTabs_" + this.props.cacheKey : STORAGE_CACHED_KEY_COMP_MobileCategoryTabs;
        let cachedState = storage.getCached(key);
        if(this.props.useCache && cachedState){
            this.setState({
                ...cachedState
            })
        }
    }

    componentWillUnmount() {
        if(this.props.useCache ){
            let key = this.props.cacheKey ? "STORAGE_CACHED_KEY_MobileCategoryTabs_" + this.props.cacheKey : STORAGE_CACHED_KEY_COMP_MobileCategoryTabs;
           storage.setCached(key,this.state);
        }
    }


    render() {

        let {groups, selectedKeyPath} = this.state;
        groups = groups || [];


        let SubMenus = [];

        for (let gIndex = 0; gIndex < groups.length; gIndex++) {
            let g = groups[gIndex];
            let selectedGIndex = -1;
            let selectedCIndex = -1;
            if (selectedKeyPath && selectedKeyPath.length == 2) {
                selectedGIndex = selectedKeyPath[0];
                selectedCIndex = selectedKeyPath[1];
            }
            if (g.categories && g.categories.length) {
                let items = null;
                items = g.categories.map((category, cIndex) => {
                    return <Menu.Item key={`${gIndex}-${cIndex}`}>{category.name || ""}</Menu.Item>
                })

                let title = g.name;
                if (selectedGIndex == gIndex) {
                    title = g.categories[selectedCIndex].name || ""
                }
                SubMenus.push(<SubMenu
                    title={
                        <span className="submenu-title-wrapper">
                            {title}
                            <Icon type="caret-down" className={'selector-arrow xs-hidden'}/>
                            {/* <IconFont type={'iconselector-arrow-down'} className={'selector-arrow xs-hidden'}></IconFont> */}
                        </span>
                    } key={gIndex}
                >
                    {items}
                </SubMenu>)
            }

        }


        return (
            <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal"
                  overflowedIndicator={<Icon type="menu-fold"/>}>
                <Menu.Item key="-1">
                    全部
                </Menu.Item>

                {SubMenus}
            </Menu>
        )
    }
}

export default MobileCategoryTabs;