/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal } from 'antd'
import LoginRegister from '../../layouts/login-register/LoginRegister'
import "./loginRegisterModal.scss"
import { connect } from 'react-redux'
import actions from "../../redux/actions";



class LoginRegisterModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            ...props
        }
    }


    static  getDerivedStateFromProps(nextProps,prevState){

        return nextProps;
    }

    render() {
        let { showLoginRegisterModal,isLogin} = this.state;


        return (
                <Modal visible={(showLoginRegisterModal === 'login'  || showLoginRegisterModal === 'register')  && !isLogin} centered footer={null} bodyStyle={{
                    background: "rgba(0,0,0,0)",
                    padding: 0
                }} width={490} onCancel={() => {
                   this.props.handlerShowLoginRegisterModal('none');
                }} maskClosable={false} destroyOnClose={true}>
                    <LoginRegister action={showLoginRegisterModal} onSuccessLogin={
                        () => {
                            this.props.handlerShowLoginRegisterModal('none');
                        }
                    } />
                </Modal>

        )
    }
}

const mapStateToProps = (state, ownProps) => ({

    isLogin: state.account.isLogin,
    loginUserInfo: state.account.loginUserInfo,
    showLoginRegisterModal:state.account.showLoginRegisterModal

})
const mapDispatchToProps = {
    handlerShowLoginRegisterModal:actions.accountShowLoginRegisterModal
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegisterModal);