import AppRequest from './AppRequest'

const urlGetGroupAndCategory = "/api_v1/course/group/category/list";
const urlGetCourse = "/api_v1/course/list";
const urlGetAppCourse = "/api_v1/course/software/course";
const urlGetCourseDetailWithCourseId = '/api_v1/course/detail';
const urlGetLessonPlayAuth = '/api_v1/course/lesson/playAuth';
const urlGetLessonPlayUrl = '/api_v1/course/lesson/playUrls'
const urlPurchaseCourse = '/api_v1/course/purchase';
const urlCheckIfPurchaseCourse = '/api_v1/course/check/purchase';
const urlGetHomeBanners = '/api_v1/course/banner/list';
const urlGetRecentWatchedCourse = '/api_v1/course/recent/watched';
const urlGetMyCollectionCourses = '/api_v1/course/my/collect';
const urlGetSubscribedCourses = '/api_v1/course/follow/list';
const urlGetMyCourseOrders = '/api_v1/course/order';
const urlGetPurchasedCourses = '/api_v1/course/payed/course';
const urlGetAppList = '/api_v1/course/software/list';
const urlGetAllCourseForUser = '/api_v1/course/all/course';
const urlPostComment = '/api_v1/comment/post';
const urlGetComments = '/api_v1/comment/list'
const urlRecordLessonWatchProgress = '/api_v1/course/lesson/watch/progress';
const urlGetUserCourseWatchRecords = '/api_v1/course/watch/record';
const urlDeleteComment = '/api_v1/comment/delete';
const urlGetLessonSourceDownloadUrl = '/api_v1/course/lesson/source/downloadUrl';
const urlLikeComment = '/api_v1/comment/like';
const urlUnlikeComment = '/api_v1/comment/delete/like'
const urlCheckIfLikeComments = '/api_v1/comment/istrue'
const urlGetToolList = '/api_v1/tool/list';
const urlGetMyCourseList = '/api_v1/course/is/role/list';
const urlAddShotsToCourse = '/api_v1/shot/course/record';
const urlLinkShotToCourses = '/api_v1/shot/courses/record'
const urlRemoveCoursesLinkedShot = '/api_v1/shot/course/record/delete';
const urlGetCommentLikeCount = '/api_v1/comment/check/comment/like'
const urlGetCoursePlan = '/api_v1/course/api/class/list'
const urlGetSeriesDetail = "/api_v1/course/series/list"
class CourseRequest extends AppRequest {

    getSeriesDetail(seriesId){
        return this.get(urlGetSeriesDetail,{id:seriesId});
    }
    linkShotToCourses(shotId,courseIds){
        return this.post(urlLinkShotToCourses,{shotId,courseIds})
    }
    removeCoursesLinkedShot(shotId,courseIds){
        return this.post(urlRemoveCoursesLinkedShot,{shotId,courseIds})
    }
    addShotsToCourse(shotIds,courseId){
        return this.post(urlAddShotsToCourse,{courseId,shotIds})
    }
    getMyCourseList(keyword,perPage = 20,page){
        let query = {
            perPage,page
        }
        if(keyword){
            query.keyword = keyword;
        }
        return this.get(urlGetMyCourseList,{...query})
    }

    getCommentLikeCount(commentIds){
        return this.post(urlGetCommentLikeCount,{type:"course",commentIds})
    }

    likeComment(commentId){
        return this.post(urlLikeComment,{commentId,type:"course"});
    }
    unlikeComment(commentId){
        return this.delete(urlUnlikeComment,{commentId,type:"course"});
    }

    checkIfLikeComments(commentIds){
        return this.get(urlCheckIfLikeComments,{commentId:commentIds,type:'course'})
    }
    getLessonSourceDownloadUrl (lessonId){
        return this.get(urlGetLessonSourceDownloadUrl,{lessonId})
    }

    deleteComment(commentId){
        return this.delete(urlDeleteComment,{type:"course",commentId})
    }
    recordLessonWatchProgress(lessonId,lessonIndex,duration,isFinal){
        let body = {lessonId,watchedDuration:duration,final:isFinal,lessonIndex};
        if(isFinal){
            delete  body.duration;
        }
        return this.put(urlRecordLessonWatchProgress,body)
    }
    getComments(courseId,perPage = 15,page = 0){
        return this.get(urlGetComments,{courseId,type:"course",perPage,page})
    }
    postComment(info){
        return this.post(urlPostComment,{...info,type:'course'});
    }

    getAllCoursesForUser(userId,page = 0,perPage = 15){
        return this.get(urlGetAllCourseForUser,{userId,perPage,page});
    }

    getAppList(){
        return this.get(urlGetAppList);
    }
    getMyCourseOrders(page = 0 ,perPage = 15){
        return this.get(urlGetMyCourseOrders,{perPage,page})
    }
    getRecentWatchedCourse(page = 0 ,perPage = 15){
        return this.get(urlGetRecentWatchedCourse,{perPage,page})
    }

    getMyCollectionCourses(page = 0,perPage = 15){
        return this.get(urlGetMyCollectionCourses,{perPage,page})
    }

    getSubscribedCourses(page = 0,perPage = 15){
        return this.get(urlGetSubscribedCourses,{perPage,page})
    }

    getPurchasedCourses(page = 0,perPage = 15){
        return this.get(urlGetPurchasedCourses,{perPage,page})
    }
    getHomeBanners(){
        return this.get(urlGetHomeBanners,);
    }
    checkIfPurchaseCourse(courseId){
        return this.get(urlCheckIfPurchaseCourse,{courseId})
    }
    purchaseCourse(courseId){
        return this.post(urlPurchaseCourse,{courseId});
    }

    getCourseDetailWithCourseId(courseId){
        return this.get(urlGetCourseDetailWithCourseId,{courseId});
    }
    getGroupAndCategory(courseType){
        return this.get(urlGetGroupAndCategory,{courseType});
    }

    getCourseList(condition){
        return this.post(urlGetCourse,condition);
    }

    getAppCourseList(condition){
        return this.get(urlGetAppCourse,condition);
    }

    getLessonPlayAuth(lessonId){
        return this.get(urlGetLessonPlayAuth,{lessonId});
    }
    getLessonPlayUrl(lessonId){
        return this.get(urlGetLessonPlayUrl,{lessonId});
    }
    getUserCourseWatchRecords(courseId){
        return this.get(urlGetUserCourseWatchRecords,{courseId})
    }

    getToolList(){
        return this.get(urlGetToolList,{});
    }

    getCoursePlan(schoolId){
        return this.get(urlGetCoursePlan,{school_id:schoolId});
    }
}

const  courseReq = new CourseRequest();
export default courseReq
