import React from "react";

import "./shotListCard.scss";
import IconFont from "../iconfont/icon-font";
import { Avatar, Icon } from "antd";

class ShotListCard extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {
        hover: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            className: nextProps.className,
            style: nextProps.style,
            key: nextProps.key,
            data: nextProps.data,
            showFixed: nextProps.showFixed,
            showRecommend: nextProps.showRecommend,
            bgColor: nextProps.bgColor,
            rowIndex: nextProps.rowIndex

        };
    }

    onCancelFixed = e => {
        e.preventDefault();
        if (this.props.onCancelFixed) {
            this.props.onCancelFixed(this.state.data, this.state.rowIndex);
        }
    }
    handlerRecommend = e => {
        e.preventDefault();
        if (this.props.handlerRecommend) {
            this.props.handlerRecommend(this.state.data, this.state.rowIndex);
        }
    }

    render() {
        let { className, style, key, data, showFixed, showRecommend } = this.state;
        data = data || {};
        let fixedIcon = null;
        if (showFixed) {
            fixedIcon = <div className="icon-box">
                <Icon className={`fixed-icon`} type="pushpin" theme="filled" onClick={this.onCancelFixed} />
            </div>

        }
        let recommendIcon = null;
        if (showRecommend) {
            recommendIcon = <div className="icon-box">
                <IconFont className={`recommend-icon`} type={`icontuijian21`}
                    style={{ color: data && data.isRecommend ? "gold" : "gray" }}
                    onClick={this.handlerRecommend} />
            </div>
        }
        return (
            <div
                className={`bks-shot ${className || ""}`}
                style={style || {}}
                key={`bks-shot-${key}`}
            >
                <div className={`hover-panel`} hidden={!showRecommend}>
                    {fixedIcon}
                    {recommendIcon}
                </div>

                <div className={"card"}>
                    <div className={"bks-shot-flex0"}>
                        <div className={"bks-shot-flex1"}>
                            <div className={"bks-shot-flex2"}>
                                <img alt={data.title} src={data.preview + "?imageView2/1/w/536/h/410"} alt={""}
                                    style={{ backgroundColor: this.state.bgColor || "white" }} />
                            </div>
                        </div>
                    </div>
                    <div className="shot-title">{data.title}</div>
                    <div className={"control-bar"}>
                        <div className={"user"}>
                            <Avatar className={"avatar"} src={data.user ? data.user.avatar : ""} />
                            <label>{data.user ? data.user.nickname || "" : ""}</label>
                        </div>
                        <div className="icon-panel">
                            <label className={"control-item"}>
                                <Icon className={"item-icon"} type="eye" theme="filled" />
                                {data.viewNum || 0}
                            </label>
                            {/* <label className={"control-item"}>
              <Icon className={"item-icon"} type="message" theme="filled" />
                {data.commentNum || 0}
              </label> */}
                            <label className={"control-item"}>
                                <Icon className={"item-icon"} type="like" theme="filled" />
                                {data.likeNum || 0}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShotListCard;
