import React from "react";

import "./courseListCard.scss";
import { Avatar, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";

class CourseListCard extends React.Component {
  constructor(props) {
    super(props);
    Object.assign(this.state, props);
  }

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...nextProps
    };
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.state.id);
    }
  };

  render() {
    let { className, style, key, data } = this.state;
    data = data || {};
    return (
      <div
        className={`comp-course-list-card ${className || ""}`}
        style={style || {}}
        onClick={this.onClick}
      >
        <div className={"card"}>
          <div className={"bks-shot-flex0"}>
            <div className={"bks-shot-flex1"}>
              <div
                className={"bks-shot-flex2"}
                style={{ backgroundColor: this.state.bgColor || "" }}
              >
                <img src={data.preview} alt={""} />
              </div>
            </div>
          </div>

          <div className={"control-bar"}>
            <div className="course-card-top">
              <label className={"title"}>{data.title}</label>
              <div className={"flex-m"}>
                {/* <LevelTag text={"免费"} color={"#FFB15F"} hidden={false} /> */}
                <div className={"apps"}>
                  {/*遍历显示课程软件*/}
                  {(function() {
                    if (data.softWareInfo && data.softWareInfo.length) {
                      return data.softWareInfo.map((soft, index) => {
                        return (
                          <div className="app-icon-panel" key={index}>
                            <img
                              className={"app-icon"}
                              src={soft.iconUrl}
                              key={index}
                              alt={""}
                            />
                          </div>
                        );
                      });
                    } else {
                      return null;
                    }
                  })()}
                </div>
              </div>
            </div>
            <div className={"flex-b"}>
              {/*遍历课程老师*/}
              {(function() {
                if (data.teacherInfo && data.teacherInfo.length) {
                  if (data.teacherInfo.length > 1) {
                    let menu = data.teacherInfo.map((item, index) => {
                      return (
                        <Menu.Item key={index}>
                          <Link to={`/teacher/${item.username}`}>
                            <div className={"authors"}>
                              <Avatar className={"avatar"} src={item.avatar} />
                              <label>{item.nickname}</label>
                            </div>
                          </Link>
                        </Menu.Item>
                      );
                    });
                    // menu.splice(0,1);
                    const user = data.teacherInfo[0];
                    return (
                      <Dropdown
                        overlay={<Menu>{menu}</Menu>}
                        overlayClassName="dropdown"
                      >
                        <div className={"authors"}>
                          <Avatar className={"avatar"} src={user.avatar} />
                          <label>
                            <span className="xs-hidden">{user.nickname}等</span>
                            {data.teacherInfo.length}位老师
                          </label>
                        </div>
                      </Dropdown>
                    );
                  } else {
                    const user = data.teacherInfo[0];
                    return (
                      <Link to={`/teacher/${user.username}`}>
                        <div className={"authors"}>
                          <Avatar className={"avatar"} src={user.avatar} />
                          <label>{user.nickname}</label>
                        </div>
                      </Link>
                    );
                  }
                } else {
                  return <div className={"authors"} />;
                }
              })()}
              <div className={"leaner-number"}>
                {data.watchNum || 0}人在学
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const LevelTag = props => {
//   let backgroundColor = "rgba(255,255,255,0.1)";
//
//   if (props.color) {
//     backgroundColor =
//       "rgba(" +
//       parseInt("0x" + props.color.slice(1, 3)) +
//       "," +
//       parseInt("0x" + props.color.slice(3, 5)) +
//       "," +
//       parseInt("0x" + props.color.slice(5, 7)) +
//       "," +
//       0.1 +
//       ")";
//   }
//   return (
//     <div
//       hidden={props.hidden}
//       style={{
//         color: props.color,
//         backgroundColor,
//         fontSize: 12,
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//         padding: "2px 4px"
//       }}
//     >
//       <label className="level-tag">{props.text || ""}</label>
//     </div>
//   );
// };

export default CourseListCard;
