import BaseRequest from './BaseRequest';

class AppRequest extends BaseRequest {
    constructor(){
        super();
        this.defaultHeaders = {
            
        }
    }

    get(url,query){

        return this.baseGet(url,this.defaultHeaders,query).then(this.fetchResponseSuccessData)
        .catch(this.fetchResponseFailData);
    }

    delete(url,query){
        return this.baseDelete(url,this.defaultHeaders,query).then(this.fetchResponseSuccessData)
        .catch(this.fetchResponseFailData);
    }
    
    post(){
        if(arguments.length >= 3){
            return this.basePost(arguments[0],this.defaultHeaders,arguments[1],arguments[2])
            .then(this.fetchResponseSuccessData)
            .catch(this.fetchResponseFailData)
        }else {
            return this.basePost(arguments[0],this.defaultHeaders,null,arguments[1])
            .then(this.fetchResponseSuccessData)
            .catch(this.fetchResponseFailData);
        }
       
    }
    put(){
        if(!arguments.length){
            return Promise.reject('url is required')
        }
        if(arguments.length >= 3){
            return this.basePut(arguments[0],this.defaultHeaders,arguments[1],arguments[2]).then(this.fetchResponseSuccessData)
            .catch(this.fetchResponseFailData);
        }else {
            return this.basePut(arguments[0],this.defaultHeaders,null,arguments[1]).then(this.fetchResponseSuccessData)
            .catch(this.fetchResponseFailData);
        }
    }

    fetchResponseSuccessData(response){
        try{
            response.data = JSON.parse(response.data)
        }catch(error){
            return Promise.reject({
                code: 400,
                msg:"can not parse response.data"
            })
        }
        if(response.data){
            if(response.data.error){
                return Promise.reject(response.data.error); 
            }else {
                return Promise.resolve(response.data.data || {}); 
            }
        }else {
            return Promise.reject({
                code: 401,
                msg:"response.data is null"
            })
        }
    }
    fetchResponseFailData(error){
    
        if(error && error.code){
            return Promise.reject(error);
        }else {
            return Promise.reject({
                code: 400,
                msg:"unknown error"
            })
        }
    }

}

export default AppRequest;