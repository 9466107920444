/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import './login-register.scss'
import {Input, Button, Radio, message, Form,Checkbox} from 'antd'
import accountReq from '../../request/accountReq'
import {connect} from 'react-redux'
import actions from '../../redux/actions';
import emailReq from '../../request/emailReq'


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class LoginRegister extends React.Component {
    constructor(props) {
        super();
        this.state = {
            action: props.action || 'login',
            registerSubmitting:false
        }
    }

    UNSAFE_componentWillReceiveProps(nexProps,nextContext) {
        this.setState({
            action: nexProps.action
        })
    }

    handlerLogin = (formValues) => {
        this.setState({
            tryingLogin: true,
            errorTip: null
        })

        accountReq.login(formValues.account, formValues.password)
            .then((info) => {
                this.setState({
                    tryingLogin: false
                })
                this.props.flagLogin(true);
                this.props.addLoginUserInfo(info);
                if (this.props.onSuccessLogin) {
                    this.props.onSuccessLogin()
                }
            })
            .catch((error) => {

                this.setState({
                    tryingLogin: false
                })

                if (error.code == 4103) {
                    this.setState({
                        tryingLogin: false,
                        errorTip: '用户名/邮箱不存在或密码错误'
                    })

                } else if (error.code == 4108) {
                    this.setState({
                        tryingLogin: false,
                        errorTip: '多次尝试登录失败，请五分钟后再试'
                    })
                } else {
                    this.setState({
                        tryingLogin: false,
                        errorTip: '发生错误，请重试'
                    })
                }

            })
    }

    submitRegister = (values) => {
        this.setState({
            registerSubmitting: true
        });

        const obj = {
            email:values.email,
            password:values.rpassword,
            emailCode:values.emailCode,
            account:values.rusername
        };
        accountReq.register(obj)
            .then(res =>{
                this.props.flagLogin(true);
                this.props.addLoginUserInfo(res);
                if (this.props.onSuccessLogin) {
                    this.props.onSuccessLogin()
                }
            })
            .catch(error =>{

            })

    };

    render() {
        let {action, errorTip} = this.state;

        return (
            <div className='bks-login-register'>
                <div className='header'>
                    <label style={{marginLeft: 8, color: action === 'login' ? "" : "#222B45"}} onClick={() => {
                        this.setState({
                            action: "login",
                            registerShowNext: false
                        })
                    }}>登录</label>
                    <label style={{color: action === 'register' ? "" : "#222B45"}} onClick={() => {
                        this.setState({
                            action: "register",
                        })
                    }}>注册</label>
                    <div className='guide-line' style={{
                        transform: action === 'register' ? "translateX(48px)" : "translateX(0)"
                    }}/>
                </div>

                <div className='error-tip'>
                    <label style={{opacity: errorTip ? 1 : 0}}>{errorTip}</label>
                </div>
                <div className='body' hidden={action !== 'login'}>
                    <LoginForm onSubmit={this.handlerLogin} submitting={this.state.tryingLogin}/>
                    <a className='forget-pwd'>忘记密码</a>
                </div>

                <div className='body' hidden={action !== 'register'}>
                    <RegisterForm onSubmit={this.submitRegister}
    submitting={this.state.registerSubmitting}/>
                </div>


                <div className='third-auth'>

                    <a className='third-link-item'><img src='/icons/third-qq.png' alt=''/></a>
                    <a className='third-link-item'><img src='/icons/third-weibo.png' alt=''/></a>
                    <a className='third-link-item'><img src='/icons/third-wechat.png' alt=''/></a>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = {
    flagLogin: actions.accountFlagLoginStatus,
    addLoginUserInfo: actions.accountAddLoginUserInfo
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginRegister);


function validateToUsername(rule, value, callback) {
    // eslint-disable-next-line no-useless-escape
    let reg = /^[a-zA-Z0-9\_\-\@\.]{4,20}$/
    // if(validator.isEmail(value)){

    // }
    if (reg.test(value)) {
        callback()
    } else {
        callback('用户名只能包含是数字、字母或字符-_@.且长度为4-20位')
    }

}

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            ...newProps
        })
    }

    loginFormOnSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                this.props.onSubmit(values)
            }
        });
    }


    render() {

        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;
        const usernameError = isFieldTouched('account') && getFieldError('account');
        const passwordError = isFieldTouched('password') && getFieldError('password');

        return (<Form onSubmit={this.loginFormOnSubmit.bind(this)} className='form-body'>
                <Form.Item className='form-item' validateStatus={usernameError ? 'error' : ''}
                           help={usernameError || ''} hasFeedback={true}>
                    {getFieldDecorator('account', {
                        rules: [{required: true, type: 'string', message: '请输入用户名或邮箱'}, {
                            validator: validateToUsername,
                        },],
                        validateFirst: true
                    })(
                        <Input className='form-input ' type='username/email' placeholder={"用户名/邮箱"}></Input>
                    )}
                </Form.Item>
                <Form.Item className='form-item' validateStatus={passwordError ? 'error' : ''}
                           help={passwordError || ''}>
                    {getFieldDecorator('password', {
                        rules: [{required: true, message: '请输入密码'}],
                    })(
                        <Input className='form-input input-margin' type='password' placeholder={"请输入密码"}></Input>
                    )}
                </Form.Item>
                <Form.Item className='form-item'>

                    <Button type='primary' className='form-button input-margin' htmlType="submit" onClick={this.login}
                            disabled={hasErrors(getFieldsError())} loading={this.state.submitting}>登录</Button>
                </Form.Item>

            </Form>
        )

    }

}

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            registerShowNext: false,
            timeoutSeconds: 60,
            sendingCode: false,
            emailCheckResult: '',
            emailChecking: false,
            emailCodeInvalid: false,
            emailCodeChecking: false,
            usernameChecking: false,
            usernameInvalid: '',
            confirmDirty: false, agreeInValid: false
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            ...newProps
        })
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            // if (values.agree !== 'agree') {
            //     return this.setState({
            //         agreeInValid: '请阅读并同意《版权声明》和《隐私条款》'
            //     })
            // }
            if (!err && this.props.onSubmit) {
                this.props.onSubmit(values)
            }
        });
    }

    sendRegisterEmailCode = () => {
        let email = this.props.form.getFieldValue('email');
        if (!email) {
            return this.setState({
                emailInvalid: "请输入注册邮箱"
            })
        }
        this.setState({
            sendingCode: true
        })
        emailReq.sendRegisterCode(email)
            .then(() => {
                this.setState({
                    sendingCode: false
                })
                if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null
                }
                this.timer = setInterval(() => {
                    if (this.state.timeoutSeconds <= 0) {
                        clearInterval(this.timer);
                        this.timer = null
                        return this.setState({
                            timeoutSeconds: 60
                        })
                    } else {
                        return this.setState({
                            timeoutSeconds: this.state.timeoutSeconds - 1
                        })
                    }
                }, 1000);
                message.success('验证码已发送，请注意查收')
            })
            .catch(error => {
                if (error.code == 5101) {
                    this.setState({
                        emailInvalid: '邮箱已被注册',
                        sendingCode: false
                    })
                } else {
                    this.setState({
                        sendingCode: false
                    })
                    message.error('邮件发送失败')
                }
            })
    }
    validateToRegister = (rule, value, callback) => {

        this.setState({
            emailChecking: true
        })

        emailReq.checkIfEmailValid(value, 'register')
            .then(result => {
                this.setState({
                    emailChecking: false,
                    emailCheckResult: result.isValid
                })
                callback(result.isValid ? null : '该邮箱已被注册')
            })
            .catch(error => {
                this.setState({
                    emailChecking: false
                })
                callback()
            })

    }
    verifyEmailCode = () => {

        let email = this.props.form.getFieldValue('email');

        if (!email) {
            return this.setState({
                emailInvalid: "请输入注册邮箱"
            })
        }

        let emailCode = this.props.form.getFieldValue('emailCode');
        if (!emailCode) {
            return this.setState({
                emailCodeInvalid: "请输入验证码"
            })
        }

        this.setState({
            emailCodeChecking: true
        })
        emailReq.verifyEmailCode(email, 'register', emailCode)
            .then(() => {
                this.setState({
                    registerShowNext: true,
                    emailCodeChecking: false
                })
            })
            .catch(error => {
                if (error.code === 5103) {
                    this.setState({
                        emailCodeChecking: false,
                        emailCodeInvalid: '验证码不存在或已失效，请重新发送'
                    })
                } else if (error.code === 5107) {
                    this.setState({
                        emailCodeChecking: false,
                        emailCodeInvalid: '验证码错误'
                    })
                } else {
                    this.setState({
                        emailCodeChecking: false,
                    })
                    message.error('发生错误，请重试')
                }

            })
    }
    userNameCheckerDelayTimer = null
    usernameOnchange = () => {
        let reg = /^[a-zA-Z0-9\_\-\@\.]{4,20}$/
        let emailReg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
        let value = this.props.form.getFieldValue('rusername');
        if (emailReg.test(value)) {
            return this.setState({
                usernameInvalid: "用户名不能是邮箱"
            })
        }
        if (reg.test(value)) {
            if (this.userNameCheckerDelayTimer) {
                clearTimeout(this.userNameCheckerDelayTimer);
                this.userNameCheckerDelayTimer = null;
            }
            this.setState({
                usernameChecking: false
            })
            this.userNameCheckerDelayTimer = setTimeout(() => {
                this.setState({
                    usernameChecking: true
                })
                accountReq.checkIfUsernameValid(this.props.form.getFieldValue('rusername'))
                    .then(res => {
                        this.setState({
                            usernameChecking: false,
                            usernameInvalid: res.isValid ? null : res.reason
                        })

                    }).catch(() => {
                    this.setState({
                        usernameChecking: false,
                        usernameInvalid: null
                    })
                })
            }, 1000);


        } else {
            return this.setState({
                usernameChecking: false,
                usernameInvalid: "用户名只能包含是数字、字母或字符-_@.且长度为4-20位"
            })
        }

    }

    handleConfirmBlur = e => {
        const {value} = e.target;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };

    compareToFirstPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && value !== form.getFieldValue('rpassword')) {
            callback('两次输入的密码不一致');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['rconfirmPassword'], {force: true});
        }
        callback();
    };

    validateToAgree = (rule, value, callback) => {
        const {form} = this.props;
        if (value !== 'agree') {
            form.validateFields(['agree'], {force: true});
        }
        callback();
    };


    render() {
        let {registerShowNext, sendingCode, timeoutSeconds, emailCodeInvalid, emailInvalid, emailCodeChecking, usernameChecking, usernameInvalid, agreeInValid} = this.state;
        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;
        const emailError = isFieldTouched('email') && getFieldError('email');
        const emailCodeError = isFieldTouched('emailCode') && getFieldError('emailCode');
        const nextBtnError = emailError || emailCodeError

        return (<Form onSubmit={this.onSubmit.bind(this)} className='form-body'>
                <div hidden={registerShowNext}>
                    <Form.Item className='form-item' validateStatus={emailInvalid || emailError ? 'error' : ''}
                               hasFeedback help={emailInvalid || emailError || ''}>
                        {getFieldDecorator('email', {
                            rules: [{required: true, type: 'string', message: '请输入注册邮箱'}, {
                                required: true,
                                type: 'email',
                                message: "邮箱格式不正确"
                            }],
                            validateFirst: true
                        })(
                            <Input className='form-input' type='email' placeholder={"邮箱"} onChange={() => {
                                if (emailInvalid) {
                                    this.setState({
                                        emailInvalid: false
                                    })
                                }
                            }} disabled={emailCodeChecking}/>
                        )}
                    </Form.Item>
                    <Form.Item className='form-item' validateStatus={emailCodeInvalid || emailCodeError ? 'error' : ''}
                               help={emailCodeInvalid || emailCodeError || ''}>
                        {getFieldDecorator('emailCode', {
                            rules: [{required: true, type: 'string', message: '请输入验证码'}],
                        })(
                            <Input className='form-input input-margin' type='number' placeholder={"验证码"}
                                   suffix={<Button className='send-code-btn'
                                                   disabled={emailError || sendingCode || timeoutSeconds < 60}
                                                   onClick={this.sendRegisterEmailCode}
                                                   loading={sendingCode}>{timeoutSeconds < 60 ? `重发验证码(${timeoutSeconds})` : '获取验证码'}</Button>}
                                   disabled={emailCodeChecking}/>
                        )}
                    </Form.Item>
                    <Form.Item className='form-item'>
                        <Button type={'primary'} className='form-button input-margin' onClick={this.verifyEmailCode}
                                disabled={nextBtnError || emailCodeChecking} loading={emailCodeChecking}>下一步</Button>
                    </Form.Item>

                </div>
                <div hidden={!registerShowNext}>
                    <Form.Item className='form-item'
                               validateStatus={usernameChecking ? 'validating' : (usernameInvalid ? 'error' : '')}
                               help={usernameInvalid || ''} hasFeedback={true}>
                        {getFieldDecorator('rusername', {})(
                            <Input className='form-input ' type='username' placeholder={"请设置你的用户名"}
                                   onChange={this.usernameOnchange}/>
                        )}
                    </Form.Item>
                    <Form.Item className='form-item' hasFeedback>
                        {getFieldDecorator('rpassword', {
                            rules: [{
                                required: true,
                                message: '请设置您的密码',
                            },
                                {
                                    validator: this.validateToNextPassword,
                                },],
                        })(
                            <Input.Password className='form-input input-margin'
                                            placeholder={"请设置你的密码"}/>
                        )}
                    </Form.Item>

                    <Form.Item className='form-item' hasFeedback>
                        {getFieldDecorator('rconfirmPassword', {
                            rules: [{
                                required: true,
                                message: '请再次确认你的密码!',
                            },
                                {
                                    validator: this.compareToFirstPassword,
                                },],
                        })(
                            <Input.Password className='form-input input-margin'
                                            placeholder={"请再次确认你的密码"} onBlur={this.handleConfirmBlur} />
                        )}
                    </Form.Item>

                    <Form.Item className='form-item' validateStatus={agreeInValid ? 'error' : ''} help={agreeInValid}>
                        {getFieldDecorator('agree', {
                            valuePropName: 'checked',
                        })(

                            <Checkbox className='agrees-radio' >
                            <span>我已阅读并接受<a>《版权声明》</a>和<a>《隐私保护》</a></span>
                            </Checkbox>
                        )}
                    </Form.Item>

                    <Form.Item className='form-item'>

                        <Button type='primary' className='form-button input-margin' htmlType="submit"
                                onClick={this.login}
                                disabled={hasErrors(getFieldsError()) || usernameChecking || usernameInvalid || agreeInValid}
                                loading={this.state.submitting}>注册</Button>
                    </Form.Item>
                    <div className='go-login'>已有账号，<a onClick={() => {
                        this.setState({
                            action: "login",
                            registerShowNext: false
                        })
                    }}>马上登录</a></div>
                </div>
            </Form>
        )

    }


}

RegisterForm = Form.create({})(RegisterForm);

LoginForm = Form.create({})(LoginForm);
