import React from "react";
import './dotLoading.scss'
export default (props) => {

   return ( <div className="comp-dot-loading" hidden={!props.show} >
        <div className="box">
            <div className="loader10" />
        </div>
    </div>
   )

}
