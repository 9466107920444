import React from 'react';
import './littleBanner.scss'
import {Carousel} from 'antd'
import Slider from "react-slick"
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
class LittleBanner extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }
    state = {

    };
    static getDerivedStateFromProps(nextProps, prevState) {

        return nextProps;

    }


    render() {
        let {banners,colors} = this.state;
        banners = banners || [];
        return (
            <div className={`focused-banner ${this.state.className || ""}`} >
                <div className={'focused-banner-padding'}>
                    <div className={'focused-banner-container'}>
                        <Slider arrows={false} autoplaySpeed={10000} autoplay={true} dots={false} draggable={true} className={'carousel'}>
                            {
                                banners.map((item, index) => {
                                    return <a title={item.name} key={index} href={item.url} target={item.openMethod === 'self' ? '':'_blank'}> <div className={'background-box'} style={{
                                        backgroundColor: `#e8e8e8`
                                    }}>
                                        <img style={{backgroundColor:colors ? colors[item.preview]:""}} src={item.preview + "?imageView2/1/w/800/h/300"} alt={""} />
                                    </div></a>
                                })
                            }

                        </Slider>
                    </div>
                </div>
            </div>

        )
    }
}

export default LittleBanner;