import React from "react";
import ListEmpty from "../../components/listEmpty/ListEmpty";
import {Row, Col, Radio, Select, Pagination, message} from "antd";
import IconFont from "../../components/iconfont/icon-font";
import OvalLoading from "../../components/OvalLoading/OvalLoading";
import ShotList from "../../components/shotsList/ShotList";
import indexReq from "../../request/indexReq";
import "./shotsLayout.scss"
import shotReq from "../../request/shotReq";
import ListFooter from "../../pages/listFooter/ListFooter";
import courseReq from "../../request/courseReq";
import storage from "../../storage/storage";

const {Option} = Select;
const PerPage = 24;
const loadingStatus = {
    init: "init",
    firstLoading: "first-loading",
    loadingMore: "loading-more",
    firstError: "first-error",
    moreError: "more-error"
};

const STORAGE_CACHED_KEY = "cached-shots-page-state"

class ShotsLayout extends React.Component {
    constructor() {
        super();
        this.carouselRef = React.createRef();
        this.searchRef = React.createRef();
    }

    state = {
        selectCategoryIndexPath: [],
        categories: [],
        getGroupFail: false,
        loadingShotListStatus: loadingStatus.init,
        courseListNoMore: false,
        shots: [],
        courseTotalCount: 0,
        selectedCategoryId: null,
        searchKeyword: null,
        categoryId: 0,
        banners: [],
        littleBanners: [],
        currentBannerIndex: 0,
        searchInputOnFocus: false,
        apps: [],
        imageAveColor: {},
        isRecommend: true,
        hideCategory: false,
        disableLoadingMore: false,
        showPageLoading: false,
        searchInputClass: "",
        shotListPage: -1,
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            hideCategory: nextProps.hideCategory,
            disableLoadingMore: nextProps.disableLoadingMore,
            courseId: nextProps.courseId,
            listType: nextProps.listType,
            showShotFixed: nextProps.showShotFixed,
            showRecommend: nextProps.showRecommend
        };
    }

    componentDidMount() {

        let cachedState = storage.getCached(STORAGE_CACHED_KEY);
        if (this.props.useCache && cachedState) {
            this.setState({...cachedState})
        } else {
            this.getCategories()
            this.getShots();
        }
        document.onkeydown = e => {
            if (
                e &&
                e.keyCode === 13 &&
                this.state.searchInputOnFocus &&
                this.lastSearchValue !== this.state.searchKeyword
            ) {

                this.lastSearchValue = this.state.searchKeyword;

                this.setState({
                    shotListPage: -1,
                }, () => {
                    this.getShots();
                })


            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.listType === 'course-shot' && !prevState.courseId && this.state.courseId) {
            this.getShots()
        }
    }

    componentWillUnmount() {

        if (this.props.useCache) {
            storage.setCached(STORAGE_CACHED_KEY, this.state);
        }
    }


    getCategories() {
        shotReq
            .getShotCategories()
            .then(res => {
                this.setState({
                    categories: res
                });
            })
            .catch(error => {

            });
    }


    fastLoadingBan = false;
    getShots = () => {
        let that = this;
        if (this.state.listType === 'course-shot' && !this.state.courseId) {
            return;
        }
        if (this.fastLoadingBan) {
            return;
        }
        this.fastLoadingBan = true
        setTimeout(() => {
            this.fastLoadingBan = false
        }, 200)
        if (this.state.loadingCourseListStatus === loadingStatus.firstLoading || this.state.loadingCourseListStatus === loadingStatus.loadingMore) {
            return;
        }
        let condition = (function () {
            let body = {
                perPage: PerPage,
                page: that.state.shotListPage + 1
            };
            if (that.state.categoryId > 0) {
                body.categoryId = that.state.categoryId;
            }

            if (that.state.searchKeyword) {
                body.keywords = that.state.searchKeyword;
            }
            if (that.state.isRecommend) {
                body.isRecommend = true;
            }

            return body;
        })();
        if (condition.page < 1) {
            this.setState({
                loadingShotListStatus: loadingStatus.firstLoading,
                shots: []
            });
        } else {
            this.setState({
                loadingShotListStatus: loadingStatus.loadingMore
            });
        }
        if (this.state.listType === 'course-shot') {
            condition.courseId = this.state.courseId;
        }
        shotReq
            .getShotList(condition)
            .then(res => {
                let moreShots = res.rows;
                let count = res.count;
                let noMore = false;
                if (moreShots.length < condition.perPage) {
                    noMore = true;
                }
                let shots = this.state.shots;
                if (condition.page < 1) {
                    shots = [];
                }
                if (res && res.rows && res.rows.length) {
                    res.rows.forEach(item => {
                        indexReq.getQiniuImageAveColor(item.preview).then(res => {
                            let colors = this.state.imageAveColor;
                            colors[item.preview] = res;
                            this.setState({
                                imageAveColor: colors
                            });
                        });
                    });
                }
                this.setState({
                    loadingShotListStatus: loadingStatus.init,
                    shotListNoMore: noMore,
                    shots: shots.concat(moreShots),
                    shotTotalCount: count,
                    showPageLoading: false,
                    shotListPage: this.state.shotListPage + 1
                });
            })
            .catch(error => {
                if (condition.page < 1) {
                    this.setState({
                        loadingShotListStatus: loadingStatus.firstError,
                        showPageLoading: false
                    });
                } else {
                    this.setState({
                        loadingShotListStatus: loadingStatus.moreError,
                        showPageLoading: false
                    });
                }
            });
    }

    categoryOnSelected = value => {
        this.setState(
            {
                selectCategoryIndexPath: value
            },
            () => {
                this.setState({
                    shotListPage: -1,
                }, () => {
                    this.getShots();
                })

            }
        );
    };

    onChangeCategory = e => {

        this.setState({
                categoryId: e.target.value,
                shotListPage: -1,
            },
            () => {
                this.getShots();
            }
        );
    };

    onChangeRecommend = value => {
        this.setState({
            isRecommend: value !== 'all',
            shotListPage: -1
        }, () => {
            this.getShots();
        })
    }

    handlerShotRecommend = (shot, shotIndex) => {
        shotReq.handlerRecommendShot(shot.id, !shot.isRecommend)
            .then(res => {
                let shots = this.state.shots;
                shots[shotIndex].isRecommend = !shots[shotIndex].isRecommend;
                this.setState({
                    shots
                })

            })
            .catch(error => {
                message.error(`操作失败`)
            })
    }


    shotOnCancelFixed = (shot, shotIndex) => {

        courseReq.removeCoursesLinkedShot(shot.id, [this.state.courseId])
            .then(res => {
                let shots = this.state.shots;
                shots.splice(shotIndex, 1);
                this.setState({
                    shots
                })
            })
            .catch(error => {
                message.error(`操作失败`)
            })
    }

    render() {
        let {shots, loadingShotListStatus, shotListNoMore, categories, isRecommend, hideCategory, disableLoadingMore, shotTotalCount, showPageLoading, showShotFixed, showRecommend} = this.state;
        shots = shots || [];
        let that = this;
        return (
            <div className="comp-shots">
                {/* <div className="shot-post-panel">
          <button className={"shot-post"}>发布作品</button>
        </div> */}
                <div className={"list-header"}>
                    <div className={"category-search"}>
                        <Row
                            type="flex"
                            justify="space-between"
                            gutter={[16, 16]}
                            align="middle"
                        >
                            <Col xs={{span: 4, order: 1}} md={{span: 4, order: 1}} lg={{span: 4, order: 1}}
                                 xl={{span: 6, order: 1}}>
                                <Select defaultValue="recommend" value={isRecommend ? "recommend" : "all"}
                                        style={{width: 80}} onChange={this.onChangeRecommend}>
                                    <Option value="recommend">推荐</Option>
                                    <Option value="all">全部</Option>
                                </Select>
                            </Col>
                            <Col xs={{span: 24, order: 3}} md={{span: 12, order: 2}} lg={{span: 10, order: 2}}
                                 xl={{span: 12, order: 2}} className="shots-category" hidden={hideCategory}>
                                <Radio.Group defaultValue={0} value={this.state.categoryId}
                                             onChange={this.onChangeCategory}>
                                    <Radio.Button value={0}>全部</Radio.Button>
                                    {categories.map((item, index) => {
                                        return <Radio.Button value={item.id} key={item.id}>{item.name}</Radio.Button>
                                    })}
                                </Radio.Group>
                            </Col>
                            <Col xs={{span: 18, order: 2}} md={{span: 8, order: 3}} lg={{span: 10, order: 3}}
                                 xl={{span: 6, order: 3}} className="shots-search">
                                <div className={`search-bar`} onMouseOver={(e) => {
                                    this.searchRef.current.focus()
                                    this.setState({
                                        searchInputClass: "hover-search"
                                    });
                                }}>
                                    <input type={"search"} ref={this.searchRef} value={this.state.searchKeyword || ""}
                                           className={this.state.searchInputClass}
                                           placeholder={
                                               this.state.searchInputOnFocus
                                                   ? "搜索作品"
                                                   : ""
                                           }
                                           onFocus={() => {
                                               this.lastSearchValue = this.state.searchKeyword + "";
                                               this.setState({
                                                   searchInputOnFocus: true
                                               });
                                           }}

                                           onBlur={() => {
                                               if (!this.state.searchKeyword) {
                                                   this.searchRef.current.blur()
                                                   this.setState({
                                                       searchInputClass: "",
                                                       searchInputOnFocus: false
                                                   });
                                               }
                                           }}

                                           onChange={e => {
                                               this.setState({
                                                   searchKeyword: e.currentTarget.value
                                               });
                                           }}
                                    />
                                    <IconFont
                                        className={`search-icon ${
                                            this.state.searchKeyword ? "searching" : ""
                                        }`}
                                        type={"iconsearch-icon"}
                                        onClick={() => {
                                            if (this.lastSearchValue !== this.state.searchKeyword) {
                                                this.lastSearchValue = this.state.searchKeyword;
                                                this.setState({
                                                    shotListPage: -1,
                                                }, () => {
                                                    this.getShots();
                                                })
                                            }
                                        }}
                                    />
                                </div>

                                <button className="post-btn" onClick={this.props.onClickShotPost}>发布</button>
                            </Col>


                        </Row>
                    </div>
                </div>

                <div className={"list-body"}>
                    {/*<OvalLoading*/}
                    {/*    className={"loading"}*/}
                    {/*    show={(loadingShotListStatus == loadingStatus.firstLoading) || showPageLoading}*/}
                    {/*/>*/}
                    <ShotList showSkeleton={(loadingShotListStatus == loadingStatus.firstLoading) || showPageLoading} shots={shots} colors={this.state.imageAveColor}
                              showShotFixed={showShotFixed}
                              showRecommend={showRecommend} shotOnCancelFixed={this.shotOnCancelFixed}
                              handlerShotRecommend={this.handlerShotRecommend}/>
                    <ListEmpty
                        show={
                            loadingShotListStatus === loadingStatus.init &&
                            !shots.length
                        }
                    />
                </div>
                <ListFooter
                    show={!disableLoadingMore && ([loadingStatus.loadingMore, loadingStatus.moreError, loadingStatus.init].indexOf(loadingShotListStatus) >= 0 && shots.length)}
                    status={shotListNoMore ? 'no-more' : loadingShotListStatus === loadingStatus.loadingMore ? 'loading-more' : "loading-error"}
                    onLoadingMore={() => {
                        if (!disableLoadingMore) {
                            this.getShots()
                        }
                    }}/>

                {function () {
                    if (disableLoadingMore) {
                        return <Pagination hideOnSinglePage={true} hidden={!disableLoadingMore} pageSize={PerPage}
                                           total={shotTotalCount} onChange={page => {


                            that.setState({
                                shots: [],
                                showPageLoading: true,
                                shotListPage: page - 2
                            }, () => {
                                that.getShots()
                            })

                        }}/>
                    } else {
                        return null;
                    }

                }()}


            </div>
        );
    }
}

export default ShotsLayout;
