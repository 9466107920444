import React from 'react'
import {Avatar} from 'antd'
import IconFont from "../iconfont/icon-font";
import './messageListItem.scss'
import {parseTimelineDate} from "../../utils/dataformat";
import {Link} from 'react-router-dom'
import CommentTextarea from "../comment-textarea/CommentTextarea";
class MessageListItem extends React.Component {
    constructor(props) {
        super(props);

    }

    state = {
        className: null,
        style: null,
        key: null,
        data: null,
        showCommentTextarea:false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            className: nextProps.className,
            style: nextProps.style,
            data: nextProps.data
        };
    }

    showCommentTextarea = ()=>{
        if(this.props.onChangeShowCommentTextarea){
            this.props.onChangeShowCommentTextarea(!this.state.showCommentTextarea,this);
        }
        this.setState({
            showCommentTextarea:!this.state.showCommentTextarea
        })

    }
    hideCommentTextarea = ()=>{
        this.setState({
            showCommentTextarea:false
        })
    }



    handlerCommentLike = ()=>{
        if(this.props.handlerCommentMessageLike){
            this.props.handlerCommentMessageLike(this.state.data);
        }
    }


    composeMessageBody(message) {
        let messageTitle = null;
        let messageContent = null;
        let messageBottomInfo = null;
        let body = message.body || {};
        let fromUser = body.fromUser || {}
        let shotInfo = body.shotInfo || {};
        let courseInfo = body.courseInfo || {}
        let audioInfo = body.audioInfo || {}
        let commentInfo = body.commentInfo || {};
        let commentTextArea = null;
        switch (message.noticeType) {
            case 'audio-comment-like':
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>赞了你在播客<Link to={`/podcast/${audioInfo.id}`}
                                                                                                            className={`title`}>《{audioInfo.title || ""}》</Link>中的评论:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                </div>
                break;
            case 'audio-comment-reply':
                commentTextArea = true
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>在播客<Link  to={`/podcast/${audioInfo.id}`}
                                                                                                          className={`title`}>《{audioInfo.title || ""}》</Link>中回复了你:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                    <div className={`control-options`}>
                        <div className={`comment-likes`}>
                            <IconFont className={`like-icon`} type={`iconlike2`} style={{color:commentInfo.isLike ? "red":"#939CAE"}} onClick={this.handlerCommentLike}/>
                            <div className={`count`}>{commentInfo.likeNum || 0}</div>
                        </div>
                        <div className={`reply-btn`} onClick={this.showCommentTextarea}>回复</div>
                    </div>
                </div>
                break;
            case "audio-comment":
                commentTextArea = true
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>在你的播客<Link to={`/podcast/${audioInfo.id}`}
                                                                                                           className={`title`}>《{audioInfo.title || ""}》</Link>中发布了评论:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                    <div className={`control-options`}>
                        <div className={`comment-likes`}>
                            <IconFont className={`like-icon`} type={`iconlike2`} style={{color:commentInfo.isLike ? "red":"#939CAE"}} onClick={this.handlerCommentLike}/>
                            <div className={`count`}>{commentInfo.likeNum || 0}</div>
                        </div>
                        <div className={`reply-btn`} onClick={this.showCommentTextarea} >回复</div>
                    </div>
                </div>
                break;
            case 'shot-comment':
                commentTextArea = true
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>在你的作品<Link to={`/shot/${shotInfo.id}`}
                    className={`title`}>《{shotInfo.title || ""}》</Link>中发布了评论:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                    <div className={`control-options`}>
                        <div className={`comment-likes`}>
                            <IconFont className={`like-icon`} type={`iconlike2`} style={{color:commentInfo.isLike ? "red":"#939CAE"}} onClick={this.handlerCommentLike}/>
                            <div className={`count`}>{commentInfo.likeNum || 0}</div>
                        </div>
                        <div className={`reply-btn`} onClick={this.showCommentTextarea} >回复</div>
                    </div>
                </div>
                break;
            case 'shot-like':
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>赞了你的作品<Link to={`/shot/${shotInfo.id}`}
                    className={`title`}>《{shotInfo.title || ""}》</Link></div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                </div>
                break;
            case 'shot-collection':
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>收藏了你的作品<Link  to={`/shot/${shotInfo.id}`}
                    className={`title`}>《{shotInfo.title || ""}》</Link></div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                </div>
                break;
            case 'shot-comment-reply':
                commentTextArea = true
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>在作品<Link  to={`/shot/${shotInfo.id}`}
                    className={`title`}>《{shotInfo.title || ""}》</Link>中回复了你:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                    <div className={`control-options`}>
                        <div className={`comment-likes`}>
                            <IconFont className={`like-icon`} type={`iconlike2`} style={{color:commentInfo.isLike ? "red":"#939CAE"}} onClick={this.handlerCommentLike}/>
                            <div className={`count`}>{commentInfo.likeNum || 0}</div>
                        </div>
                        <div className={`reply-btn`} onClick={this.showCommentTextarea}>回复</div>
                    </div>
                </div>
                break;
            case 'shot-comment-like':
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>赞了你在作品<Link to={`/shot/${shotInfo.id}`}
                    className={`title`}>《{shotInfo.title || ""}》</Link>中的评论:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                </div>
                break;
            case 'course-comment':
                commentTextArea = true
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>在你的课程<Link  to={`/course/${courseInfo.id}`}
                    className={`title`}>《{courseInfo.title || ""}》</Link>中发布了评论:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                    <div className={`control-options`}>
                        <div className={`comment-likes`}>
                            <IconFont className={`like-icon`} type={`iconlike2`} style={{color:commentInfo.isLike ? "red":"#939CAE"}} onClick={this.handlerCommentLike}/>
                            <div className={`count`}>{commentInfo.likeNum || 0}</div>
                        </div>
                        <div className={`reply-btn`} onClick={this.showCommentTextarea}>回复</div>
                    </div>
                </div>
                break;
            case 'course-comment-reply':
                commentTextArea = true
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>在课程<Link to={`/course/${courseInfo.id}`}
                    className={`title`}>《{courseInfo.title || ""}》</Link>中回复了你:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                    <div className={`control-options`}>
                        <div className={`comment-likes`}>
                            <IconFont className={`like-icon`} type={`iconlike2`} style={{color:commentInfo.isLike ? "red":"#939CAE"}} onClick={this.handlerCommentLike}/>
                            <div className={`count`}>{commentInfo.likeNum || 0}</div>
                        </div>
                        <div className={`reply-btn`} onClick={this.showCommentTextarea}>回复</div>
                    </div>
                </div>
                break;
            case 'course-comment-like':
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>赞了你在课程<Link to={`/course/${courseInfo.id}`}
                    className={`title`}>《{courseInfo.title || ""}》</Link>中的评论:</div>
                messageContent = <div className={`message-content`}>{commentInfo.content || ""}</div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                </div>
                break;

            case 'course-collection':
                messageTitle = <div className={`message-title`}><span
                    className={`nickname`}>{fromUser.nickname || fromUser.username || ""}</span>收藏了你的课程<Link to={`/course/${courseInfo.id}`}
                    className={`title`}>《{courseInfo.title || ""}》</Link></div>
                messageBottomInfo =   <div className={`flex-b`}>
                    <div className={`date`}>{parseTimelineDate(message.createdAt )}</div>
                </div>
                break;
            default:
                break;
        }

        if(commentTextArea){
            commentTextArea = <CommentTextarea  show={this.state.showCommentTextarea} placeholder={`@${fromUser.nickname || fromUser.username || ""}`} okText={`回复`} onSubmit={this.submitCommentReply} />;

        }



        return <div className={`message-body`}>
            {messageTitle}
            {messageContent}
            {messageBottomInfo}
            {commentTextArea}
        </div>


    }

    submitCommentReply = (content,textAreaTarget)=>{
        if(this.props.onSubmitCommentReply){
            this.props.onSubmitCommentReply(content,this.state.data,textAreaTarget,this)
        }
    }

    render() {
        let {data} = this.state;
        data = data || {}
        let messageBody = this.composeMessageBody(data);
        return <div className={`comp-message-list-item ${this.state.className || ""}`} style={this.state.style || {}}>
            <Avatar className={`avatar`} src={data.body && data.body.fromUser ? data.body.fromUser.avatar : ""}/>
            {messageBody}
        </div>
    }

}

export default MessageListItem;