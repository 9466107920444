import accountTypes from '../actionTypes/accountTypes'
import {combineReducers} from "redux";


const {
    ACCOUNT_ADD_LOGIN_USER_INFO, ACCOUNT_REMOVE_LOGIN_USER_INFO, ACCOUNT_FLAG_LOGIN_STATUS, ACCOUNT_SHOW_LOGIN_REGISTER_MODAL,
    ACCOUNT_UNREAD_MESSAGES,
    ACCOUNT_CAN_HANDLER_TOP_COMMENT
} = accountTypes;

function loginUserInfoReducer(state = {}, action) {
    switch (action.type) {
        case ACCOUNT_REMOVE_LOGIN_USER_INFO:
            return {};
        case ACCOUNT_ADD_LOGIN_USER_INFO:
            return {
                ...action.payload
            }
        default:
            return state;
    }
}

function accountSetUnreadMessagesData(state = {
    rows: [],
    perPage: 30,
    page: -1,
    noMore: false,
    total: 0,
    latestRefreshDate: null
}, action) {
    switch (action.type) {
        case ACCOUNT_UNREAD_MESSAGES:
            return {...action.payload}
        default:
            return state;
    }
}

function loginStatusReducer(state = false, action) {
    switch (action.type) {
        case ACCOUNT_FLAG_LOGIN_STATUS:
            return action.payload;
        default:
            return state;
    }
}

function showLoginRegisterModalReducers(state = 'none', action) {
    switch (action.type) {
        case ACCOUNT_SHOW_LOGIN_REGISTER_MODAL:
            return action.payload;
        default:
            return state;
    }
}

function flagHandlerTopComment(state = false, action) {
    switch (action.type) {
        case ACCOUNT_CAN_HANDLER_TOP_COMMENT:
            return action.payload;
        default:
            return state;
    }
}


const indexReducer = combineReducers({
    loginUserInfo: loginUserInfoReducer,
    isLogin: loginStatusReducer,
    showLoginRegisterModal: showLoginRegisterModalReducers,
    unreadMessagesData: accountSetUnreadMessagesData,
    canTopComment:flagHandlerTopComment
})

export default indexReducer