import React from 'react'
import Layout from "../../layouts/layout/Layout";
import "./promise.scss"
import {Input, Button, message, Radio, Checkbox, DatePicker} from 'antd'
import {connect} from "react-redux";
import actions from "../../redux/actions";
import api from '../../request/indexReq'

class Promise extends React.Component {

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            ...nextProps
        };
    }

    componentDidMount() {
        setTimeout(() => {
            let canvas = this.canvasRef.current
            if (!canvas) {
                return
            }
            let ctx = canvas.getContext("2d");
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            canvas.addEventListener("touchstart", function (event) {
                event.preventDefault()
            })
            canvas.addEventListener("touchmove", function (event) {
                event.preventDefault()
            })
            canvas.addEventListener("touchend", function (event) {
                event.preventDefault()
            })
            canvas.addEventListener("touchcancel", function (event) {
                event.preventDefault()
            })
        }, 2000)
    }

    pro = e => {
        e.preventDefault()
    }

    submit = () => {
        let {name, tel, address, sign, inBeijing, vehicle, vehicleNum, backDate, goDiseaseArea, touch} = this.state;
        if (!name) {
            return message.warn(`请填写承诺人姓名`)
        }
        if (!tel || tel.length !== 11) {
            return message.warn(`请输入真实11位手机号`)
        }

        if (!address) {
            return message.warn(`请输入真实详细地址`)
        }


        if (!inBeijing) {
            return message.warn(`你是否曾离京过？`)
        }
        if (inBeijing == 1 && (!backDate || !backDate.length)) {
            return message.warn(`请选择你的返京日期`)
        }
        if (inBeijing == 1 && (!vehicle || !vehicle.length)) {
            return message.warn(`请选择你的返京方式`)
        }
        if( vehicle && vehicle.length){
            if ((vehicle.indexOf(1) >= 0 || vehicle.indexOf(2) >= 0 || vehicle.indexOf(3) >= 0 || vehicle.indexOf(6) >= 0) && (!vehicleNum || !vehicleNum.length)) {
                return message.warn(`请填写你的返京车次/航班信息`)
            }
        }

        if (!goDiseaseArea) {
            return message.warn(`自1月20日以来可曾途径、停留疫情重点地区？`)

        }

        if (!touch) {
            return message.warn(`自1月20日以来是否接触过新型冠状肺炎疑似或确诊人群/是否接触过来自湖北的人群？`)
        }


        if (!sign) {
            return message.warn(`请承诺人签字`)
        }


        this.setState({
            submitting: true
        })
        api.submitDiseasePromise({
            name, tel, address, sign, vehicleNum, vehicle: vehicle && vehicle.length ?  vehicle.toString():null, inBeijing, goDiseaseArea, touch
            , backDate
        })
            .then(res => {
                message.success(`已提交成功`)
                this.setState({
                    submitting: false,
                    showEnd: true
                })

            })
            .catch(res => {
                this.setState({
                    submitting: false
                })
                message.error(`发生错误，请重试`)
            })

    }

    render() {
        let {windowSize, showEnd, inBeijing, vehicle, vehicleNum} = this.state;

        if (showEnd) {
            return <div className={'promise-page'}>
                <div className={`modal modal-center`} style={{width: windowSize.width, height: windowSize.height}}>
                    <div className={`form-title`}>已提交成功</div>

                </div>

            </div>
        }


        return <div className={'promise-page'}>
            <Layout rootClassName={"course"} hiddenNav={true}>
                <div className={`pro`}>
                    <div className={`title`}>像素范儿健康承诺书</div>
                    <div className={'content'}>
                        <p>自武汉新型冠状病毒肺炎疫情发生以来，中央、北京市、东城区及时采取了一系列防控措施，有效遏制了疫情的蔓延。作为社区居民，为了我和公众健康，在疫情防控期间，坚决服从各项政策要求，并作以下承诺：</p>
                        <p>一、配合做好登记、排查工作，确保在所属街道、社区、单位或入住的酒店等做好登记。</p>
                        <p>二、不参加聚会和集体活动，避免外出；如因特殊情况必需外出，自觉配戴口罩。</p>
                        <p>三、每日观察自己及相关家人的健康状况，至少持续2周，如有发热、干咳等症状，第一时间到医院发热门诊就医，并主动告知医生旅行史，及周围人员中是否有类似病例，听从医务人员指引，并配合医务人员进行流行病学调查，提供详尽信息。前往医院不乘坐公共交通工具。</p>
                        <p>四、注意环境和个人卫生，保持住所的通风良好，勤洗手，不随地吐痰。打喷嚏或咳嗽时用纸巾或袖肘遮住口、鼻。</p>
                    </div>

                </div>

                <div className={`form`}>
                    <div className={`form-item`}>
                        <div className={`form-title`}>承诺人</div>
                        <Input size={`large`} className={`input`} key={`name`} onChange={(e) => {
                            this.setState({
                                name: e.target.value
                            })
                        }}/>
                    </div>

                    <div className={`form-item`}>
                        <div className={`form-title`}>手机号</div>
                        <Input size={`large`} className={`input`} key={`name`} onChange={(e) => {
                            this.setState({
                                tel: e.target.value
                            })
                        }}/>
                    </div>
                    <div className={`form-item`}>
                        <div className={`form-title`}>在京住址</div>
                        <Input size={`large`} className={`input`} key={`name`} onChange={(e) => {
                            this.setState({
                                address: e.target.value
                            })
                        }}/>
                    </div>
                    <div className={`form-item`}>
                        <div className={`form-title`}>自1月20日以来之后可曾离京</div>

                        <Radio.Group className={`radio-group`} onChange={e => {
                            this.setState({
                                inBeijing: e.target.value
                            })
                        }} value={inBeijing}>
                            <Radio className={`radio`} value={1}>我已返回北京</Radio>
                            <Radio className={`radio`} value={2}>我仍在外地</Radio>
                            <Radio className={`radio`} value={3}>未曾离开过北京</Radio>
                        </Radio.Group>
                    </div>

                    <div className={`form-item`} hidden={!inBeijing || inBeijing == 2 || inBeijing == 3}>
                        <div className={`form-title`}>返京日期</div>
                        <DatePicker size={'large'} onChange={e => {
                            this.setState({
                                backDate: e ? e.format(`YYYY/MM/DD`) : null
                            })
                        }} placeholder={"请选择返京日期"} style={{marginTop: 15, width: "100%"}}/>
                    </div>
                    <div className={`form-item`} hidden={!inBeijing || inBeijing == 2 || inBeijing == 3}>
                        <div className={`form-title`}>请填写你的返程车次/航班信息</div>

                        <Checkbox.Group
                            onChange={e => {
                                this.setState({
                                    vehicle: e
                                })
                            }}

                            className={"checkbox-group"}
                        >
                            <Checkbox className={`checkbox`} value={1}>火车</Checkbox>
                            <Checkbox className={`checkbox`} value={2}>飞机</Checkbox>
                            <Checkbox className={`checkbox`} value={3}>大巴</Checkbox>
                            <Checkbox className={`checkbox`} value={4}>拼车</Checkbox>
                            <Checkbox className={`checkbox`} value={5}>自驾</Checkbox>
                            <Checkbox className={`checkbox`} value={6}>船舶</Checkbox>
                        </Checkbox.Group>


                        <Input
                            hidden={!vehicle || !vehicle.length || (vehicle.indexOf(1) < 0 && vehicle.indexOf(2) < 0 && vehicle.indexOf(3) < 0) && vehicle.indexOf(6) < 0}
                            size={`large`} className={`input`} placeholder={`请填写的车次/航班号。多车次/航班以逗号或分号分隔填写`} key={`name`}
                            onChange={(e) => {
                                this.setState({
                                    vehicleNum: e.target.value
                                })
                            }}/>
                    </div>


                    <div className={`form-item`}>
                        <div className={`form-title`}>自1月20日以来可曾途径、停留疫情重点地区</div>

                        <Radio.Group className={`radio-group`} onChange={e => {
                            this.setState({
                                goDiseaseArea: e.target.value
                            })
                        }}>
                            <Radio className={`radio`} value={1}>武汉市</Radio>
                            <Radio className={`radio`} value={2}>湖北省其他地区</Radio>
                            <Radio className={`radio`} value={3}>否</Radio>
                        </Radio.Group>
                    </div>

                    <div className={`form-item`}>
                        <div className={`form-title`}>自1月20日以来是否接触过新型冠状肺炎疑似或确诊人群/是否接触过来自湖北的人群</div>

                        <Radio.Group className={`radio-group`} onChange={e => {
                            this.setState({
                                touch: e.target.value
                            })
                        }}>
                            <Radio className={`radio`} value={1}>接触过新型冠状肺炎疑似或确诊人群</Radio>
                            <Radio className={`radio`} value={2}>接触过来自湖北的人群</Radio>
                            <Radio className={`radio`} value={3}>否</Radio>
                        </Radio.Group>
                    </div>

                    <div className={`form-item`}>
                        <div className={`form-title`}>承诺人签字</div>

                        <div className={`image-box`} onClick={() => {
                            this.setState({
                                showModal: true
                            })
                            let canvas = this.canvasRef.current
                            let ctx = canvas.getContext("2d");
                            ctx.fillStyle = '#fff';
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            this.pointCount = 0
                        }}>
                            <img hidden={!this.state.sign} src={this.state.sign}/>
                        </div>

                        <div className={`modal`} hidden={!this.state.showModal}
                             style={{width: windowSize.width, height: windowSize.height}}>
                            <canvas ref={this.canvasRef} width={windowSize.width} height={windowSize.height - 70}
                                    className={`canvas`}
                                    onMouseDown={event => {
                                        // this.enableDraw = true
                                        // console.log(`donw`,event)
                                        // this.x = event.clientX - event.offsetLeft;
                                        // this.y = event.clientY - event.offsetTop;
                                        // document.addEventListener('touchmove', ()=>{
                                        //     event.preventDefault()
                                        // }, {passive: false});


                                    }}

                                    onMouseMove={(event) => {
                                        // console.log(`onmousemove`,event.currentTarget.offsetLeft)
                                        // let canvas = this.canvasRef.current
                                        // let ctx = canvas.getContext("2d");
                                        // let that = this;
                                        // let x1 = event.clientX - event.currentTarget.offsetLeft;
                                        // let y1 = event.clientY - event.currentTarget.offsetTop;
                                        // huabi(that.x, that.y, x1, y1, ctx);
                                        // that.x = x1;
                                        // that.y = y1;

                                    }}

                                    onTouchStart={(event) => {
                                        this.enableDraw = true

                                        this.x = event.touches[0].clientX;
                                        this.y = event.touches[0].clientY;
                                        document.addEventListener('touchmove', this.pro, {passive: false});
                                        // console.log(`   this.x`,this.x,this.y)

                                    }}

                                    onTouchEnd={(event) => {
                                        this.enableDraw = false

                                        document.removeEventListener('touchmove', this.pro, {passive: false});
                                    }}

                                    onTouchMove={(event) => {

                                        // console.log(`onmousemove`, event.touches[0].clientX,event.currentTarget.offsetLeft)
                                        let canvas = this.canvasRef.current
                                        let ctx = canvas.getContext("2d");
                                        let that = this;
                                        let x1 = event.touches[0].clientX;
                                        let y1 = event.touches[0].clientY;
                                        console.log('hdhdh', event.touches[0].clientX, canvas.scrollTop)
                                        this.pointCount += Math.abs(x1 - that.x)
                                        huabi(that.x, that.y, x1, y1, ctx);
                                        that.x = x1;
                                        that.y = y1;
                                        event.preventDefault()
                                    }}

                                    onMouseUp={(event) => {
                                        // this.enableDraw = false

                                        // document.removeEventListener('touchmove', ()=>{
                                        //     event.preventDefault()
                                        // }, {passive: false});
                                    }}


                            />

                            <div className={`bottom`}>
                                <Button size={'small'} onClick={() => {
                                    let canvas = this.canvasRef.current
                                    let ctx = canvas.getContext("2d");
                                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                                    ctx.fillStyle = '#fff';
                                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                                }}>清空手写板</Button>

                                <div className={'bottom-bar'}>
                                    <div className={`item`} onClick={() => {
                                        let canvas = this.canvasRef.current
                                        let ctx = canvas.getContext("2d");
                                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                                        ctx.fillStyle = '#fff';
                                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                                        this.setState({
                                            showModal: false
                                        })
                                    }}>取消
                                    </div>
                                    <div className={`item primary`} onClick={() => {
                                        if (this.pointCount < 200) {
                                            return message.warn(`签字质量低，请重新签字`)
                                        }
                                        let canvas = this.canvasRef.current;
                                        let tempSrc = canvas.toDataURL("image/jpeg");

                                        // console.log(tempSrc)
                                        this.setState({
                                            showModal: false,
                                            sign: tempSrc
                                        })

                                        let ctx = canvas.getContext("2d");
                                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                                        ctx.fillStyle = '#fff';
                                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                                    }}>保存
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <Button loading={this.state.submitting} className={`submit-button`} size={`large`} type={'primary'}
                            onClick={this.submit}>提交承诺书</Button>
                </div>


            </Layout>
        </div>
    }
}

function huabi(startX, startY, endX, endY, ctx) {

    // console.log("AAAAAA",startX, startY, endX, endY, ctx)
    ctx.beginPath();
    ctx.globalAlpha = 1;
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#000";
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.closePath();
    ctx.stroke();
}

const mapStateToProps = (state, ownProps) => ({

    windowSize: state.system.windowSize
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Promise);
