import React from "react";

import "./allCourseListCard.scss";
import {Avatar, Dropdown, Menu, Divider} from "antd";
import {Link} from "react-router-dom";
import dateformat from 'dateformat'

const CourseLevels = {
    primary: "初级",
    middle: "中级",
    high: "高级"
}
const CourseLevelColor = {
    primary: "#5FC4FF",
    middle: "#FFB15F",
    high: "#FF5F5F"
}

class AllCourseListCard extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
    }

    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...nextProps
        };
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.state.id);
        }
    };

    render() {
        let {className, style, key, data} = this.state;
        data = data || {};

        let courseStatus;
        let {startAt} = data;

        if (data.courseType === 'live-course' && (data.liveStatus === 'applying' || data.liveStatus == "open")) {
            let now = new Date();
            let nowST = now.getTime();
            if (!data.lessonTotalNum)  {
                courseStatus = "预售中"
            }
            if (startAt) {
                let startDate = new Date(startAt);
                let sST = startDate.getTime();
                if (sST > nowST) {
                    courseStatus = "预售中"
                }else {
                    courseStatus = null;
                }
            }


        }


        return (
            <div
                className={`comp-all-course-list-card ${className || ""}`}
                style={style || {}}
                key={`comp-course-list-card-${key}`}
                onClick={this.onClick}
            >
                <div className={"card"}>
                    <div className={"bks-shot-flex0"}>
                        {courseStatus &&  <div className="course-status" >{courseStatus}</div>}
                        {data && data.courseType === 'live-course' && <div className="course-time">直播</div>}
                        <div className={"bks-shot-flex1"}>
                            <div
                                className={"bks-shot-flex2"}
                                style={{backgroundColor: this.state.bgColor || ""}}
                            >
                                <img src={data.preview + "?imageView2/2/w/600"} alt={""}/>
                                <div className="cover-shadow"></div>
                            </div>
                        </div>
                    </div>

                    <div className={"control-bar"}>
                        <div className="course-card-top">
                            <label className={"title"}>{data.title}</label>
                            {/* <div className="course-time">1日6日开课</div> */}
                            <div className={"flex-m"}>
                                <LevelTag text={CourseLevels[data.level || "primary"]}
                                          color={CourseLevelColor[data.level || "primary"]} hidden={false}/>
                                <Divider type="vertical"/>
                                <div className={"apps"}>
                                    {/*遍历显示课程软件*/}
                                    {(function () {
                                        if (data.softWareInfo && data.softWareInfo.length) {
                                            return data.softWareInfo.map((soft, index) => {
                                                return (
                                                    <div className="app-icon-panel" key={index}>
                                                        <img
                                                            className={"app-icon"}
                                                            src={soft.iconUrl}
                                                            key={index}
                                                            alt={""}
                                                        />
                                                    </div>
                                                );
                                            });
                                        } else {
                                            return null;
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className="flex-m-b">
                                {(function () {
                                    if (data.teacherInfo && data.teacherInfo.length) {
                                        if (data.teacherInfo.length > 1) {
                                            let menu = data.teacherInfo.map((item, index) => {
                                                return (
                                                    <Menu.Item key={index}>
                                                        <Link to={`/teacher/${item.username}`}>
                                                            <div className={"authors"}>
                                                                <Avatar className={"avatar"} src={item.avatar}/>
                                                                <label>{item.nickname}</label>
                                                            </div>
                                                        </Link>
                                                    </Menu.Item>
                                                );
                                            });
                                            // menu.splice(0,1);
                                            const user = data.teacherInfo[0];
                                            return (
                                                <Dropdown
                                                    overlay={<Menu>{menu}</Menu>}
                                                    overlayClassName="dropdown"
                                                >
                                                    <div className={"authors"}>
                                                        <Avatar className={"avatar"} src={user.avatar}/>
                                                        <label>
                                                            <span className="xs-hidden">{user.nickname}等</span>
                                                            {data.teacherInfo.length}位老师
                                                        </label>
                                                    </div>
                                                </Dropdown>
                                            );
                                        } else {
                                            const user = data.teacherInfo[0];
                                            return (
                                                <Link to={`/teacher/${user.username}`}>
                                                    <div className={"authors"}>
                                                        <Avatar className={"avatar"} src={user.avatar}/>
                                                        <label>{user.nickname}</label>
                                                    </div>
                                                </Link>
                                            );
                                        }
                                    } else {
                                        return <div className={"authors"}/>;
                                    }
                                })()}<Divider type="vertical" className="xs-hidden"
                                              hidden={Math.max(data.lessonTotalNum || 0, data.lessonNum || 0) < 1}/>
                                <div className="course-duration xs-hidden"
                                     hidden={Math.max(data.lessonTotalNum || 0, data.lessonNum || 0) < 1}>{Math.max(data.lessonTotalNum || 0, data.lessonNum || 0)}课时
                                </div>

                            </div>
                        </div>
                        <div className={"flex-b"}>
                            <div className="price"
                                 hidden={!data || !data.hasOwnProperty(`free` || !data.hasOwnProperty(`price`))}>{data.free ? "免费" : `￥${parseFloat(data.price / 100).toFixed(data.price % 100 > 0 ? data.price % 10 > 0 ? 2 : 1 : 0)}`}</div>
                            <div className="count"
                                 hidden={!(data.purchaseNum || data.watchNum)}>{data && data.courseType == 'live-course' ? data.purchaseNum >= data.watchNum ? `${data.purchaseNum}人已报名` : `${data.watchNum}次学习` : `${data.watchNum || 0}人在学`}</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const LevelTag = props => {
    let backgroundColor = "rgba(255,255,255,0.1)";

    if (props.color) {
        backgroundColor =
            "rgba(" +
            parseInt("0x" + props.color.slice(1, 3)) +
            "," +
            parseInt("0x" + props.color.slice(3, 5)) +
            "," +
            parseInt("0x" + props.color.slice(5, 7)) +
            "," +
            0.1 +
            ")";
    }
    return (
        <div
            hidden={props.hidden}
            style={{
                color: props.color,
                backgroundColor,
                fontSize: 12,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "2px 4px",
                flex: "none"
            }}
        >
            <label className="level-tag">{props.text || ""}</label>
        </div>
    );
};

export default AllCourseListCard;
