import React from 'react';
import {
    Row,
    Col,
    Input,
    Icon,
    Checkbox,
    Button,
    Upload,
    Select,
    Cascader,
    Switch,
    Modal, Spin, message,
    Progress,
} from 'antd'
import {parseTimelineDate} from '../../utils/dataformat'
import shotReq from "../../request/shotReq";
import {Link} from 'react-router-dom'
import './shotPost.scss'
import {connect} from 'react-redux'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/table.css'
import 'braft-extensions/dist/code-highlighter.css'
import 'braft-extensions/dist/color-picker.css'
import CodeHighlighter from 'braft-extensions/dist/code-highlighter'
import Markdown from 'braft-extensions/dist/markdown'
import Table from 'braft-extensions/dist/table'
import ColorPicker from 'braft-extensions/dist/color-picker'
import HeaderId from 'braft-extensions/dist/header-id'
import indexReq from '../../request/indexReq'
import * as qiniu from 'qiniu-js'
import storage from "../../storage/storage";
import IconFont from "../../components/iconfont/icon-font";
import Nav from "../../components/nav/Nav";
import URLParse from 'url-parse'
import courseReq from "../../request/courseReq";

const {Search} = Input;
const uuidv4 = require('uuid/v4');
const SHA1 = require("crypto-js/sha1");
// const CryptoJS = require('crypto-js');

const editorOptions = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
};


const CPOptions = {
    theme: 'light', // 指定取色器样式主题，支持dark和light两种样式
}

const editorControls = [
    'undo', 'redo', 'separator', 'line-height', 'letter-spacing', 'separator',
    'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator', 'remove-styles', 'separator', 'text-indent', 'text-align', 'separator',
    'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
    'link', 'separator', 'hr', 'separator',
    {
        key: 'media', // 使用key来指定控件类型
        title: '图片上传', // 自定义控件title
        text: <span><Icon type="picture" /><sapn style={{paddingLeft:6,fontSize:13}}>图片上传</sapn></span>, // 使用自定义文案来代替默认图标(B)，此处也可传入jsx
    }, 'separator', "fullscreen"
];

BraftEditor.use(ColorPicker(CPOptions));

BraftEditor.use(Table(editorOptions));
const MKOptions = {};
const codeHOptions = {};
BraftEditor.use(Markdown(MKOptions));
BraftEditor.use(CodeHighlighter(codeHOptions));
BraftEditor.use(HeaderId({}));


const {Option} = Select;

// 富文本编辑器媒体上传函数，接入七牛上传
const myUploadFn = (param) => {

    const progressFn = (event) => {
        param.progress(event.total.percent)
    };

    const errorFn = (response) => {
        param.error({
            msg: '上传失败'
        })
    };

    indexReq.getFileUploadToken()
        .then(info => {
            const successFn = (response) => {

                param.success({
                    url: `${info.host}${response.key}`
                })
            };
            let paths = param.file.name.split('.');
            let key = null
            if (paths.length > 1) {
                key = uuidv4() + "." + paths[paths.length - 1];
            }
            let observable = qiniu.upload(param.file, key, info.uploadToken, null, null);
            observable.subscribe(progressFn, errorFn, successFn);
        })
        .catch(error => {
            return errorFn(error);
        })
};

const {Dragger} = Upload;
const PerPage = 30;

class ShotPost extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props)
    }

    state = {
        uploadingPreviewStatus: 'init', // ing ,error,
        previewProgress: 0,
        categoryCascaderValues: [], // 分类选择器当前值
        categoryCascaderOptions: [],// 类级联选择器的数据
        loadingGroupsData: false, // 是否在加载作品分类数据
        shotId: 0,
        noFound: false,
        pageLoading: true,
        editorState: BraftEditor.createEditorState(null),
        shotInfo: null,
        changedInfo: {},
        editType: 'create', // 'create','update'
        loadingShotStatus: false,
        saving: false,
        waitingPassPower: true,
        linkCourseId:null,
        linkCourse:null

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let toStates = {
            ...nextProps,
            waitingPassPower: !(nextProps.loginUserInfo && nextProps.loginUserInfo.userPower && nextProps.loginUserInfo.userPower.canPostShot),

        }

        // if(nextProps.location.pathname === '/post/shot/create' && prevState.editType === 'update'){
        //
        //         Object.assign(toStates,{editType: 'create',
        //             shotInfo: null,
        //             changedInfo: {},
        //             editorState: BraftEditor.createEditorState( null)
        //         })
        // }
        return toStates
    }


    /* life circle method */
    componentDidMount() {

        this.parsePathName();
        window.onbeforeunload = this.routerWillLeave;

        this.props.history.block((location, action) => {

            return this.routerWillLeave(location)
        });
    }


    componentWillUnmount() {

    }


    routerWillLeave = (nextLocation) => {

        if (this.state.changedInfo && Object.getOwnPropertyNames(this.state.changedInfo).length) {
            return '您有修改未保存，您要放弃修改吗？'
        } else {
            return null;
        }
    };
    parsePathName = () => {
        let pathname = this.props.location.pathname;
        if (pathname === '/post/shot/create') {
            let url = new URLParse(window.location.href,true);
            let linkCourseId = null;
            if(url && url.query && url.query.linkCourse){
                linkCourseId = url.query.linkCourse
            }
            this.setState({
                editType: "create",
                pageLoading: false,
                linkCourseId
            })
            this.getAllGroups();
            if(linkCourseId){
                this.getCourseDetail(linkCourseId);
            }

        } else {
            let shotId = this.props.match.params.shotId;
            if (shotId && parseInt(shotId)) {
                this.setState({
                    editType: "update",
                    shotId: shotId
                }, () => {
                    this.getShotDetail()
                })

            } else {
                this.setState({
                    pageLoading: false,
                    noFound: true
                })
            }
        }
    }

    getCourseDetail(courseId) {
        courseReq
            .getCourseDetailWithCourseId(courseId || this.state.linkCourseId)
            .then(res => {
                this.setState({
                    linkCourse:res
                })
            })
            .catch(error => {
                if (error && error.code === 7001) {
                    return this.setState({
                        noFound: true
                    })
                }
                // message.error(`获取课程详情时发生错误`);
            });
    }

    previewUploaderOnChange = () => {

    };
    previewUploaderBeforeUpload = (fileInfo) => {
        this.setState({
            uploadingPreviewStatus: "ing", previewProgress: 0
        })
        indexReq.uploadFileToQiniu(fileInfo, fileInfo.name, {
            success: (url) => {
                let changedInfo = this.state.changedInfo || {};
                changedInfo.preview = url;
                this.setState({
                    changedInfo,
                    uploadingPreviewStatus: "init", previewProgress: 0
                })

            },
            progress: (e) => {

                this.setState({
                    uploadingPreviewStatus: "ing", previewProgress: parseInt(e.total.percent)
                })
            },
            error: (error) => {
                message.error('上传发生错误，请重试');
                this.setState({
                    uploadingPreviewStatus: "error"
                })
            },
        })
    };

    categorySelectorOnChange = (value) => {
        let changedInfo = this.state.changedInfo;
        if (value && value.length === 1) {
            changedInfo.shotCategoryId = value[0];
        }else {
            changedInfo.shotCategoryId = null
        }
        if((!value || !value.length) && !this.state.shotInfo){
           delete changedInfo.shotCategoryId
        }

        this.setState({
            categoryCascaderValues: value,
            changedInfo
        })
    }
    handleEditorChange = (editorState) => {
        let changedInfo = this.state.changedInfo;
        let html = editorState.toHTML();

        if((!this.state.shotInfo && html != '<p></p>') || (this.state.shotInfo && this.state.shotInfo.content != html) ){

            changedInfo.content = html;
        }

        this.setState({
            changedInfo,
            editorState
        })


    }

    submitContent = () => {

    }

    tagsHandleChange = (e) => {
        let {changedInfo} = this.state;
        changedInfo = changedInfo || {};
        changedInfo.tags = e;
        if(!e.length && !this.state.shotInfo){
           delete changedInfo.tags
        }
        this.setState({
            changedInfo
        })
    }

    getAllGroups = () => {
        this.setState({
            loadingGroupsData: true
        })
        shotReq.getAllShotCategories()
            .then(resGroups => {
                let cascaderOptions = [];
                let categoryCascaderValues = this.state.categoryCascaderValues || [];
                let shotInfo = this.state.shotInfo;
                for (let j = 0; j < resGroups.length; j++) {
                    let cate = resGroups[j];
                    if (shotInfo && shotInfo.shotCategoryId && shotInfo.shotCategoryId == cate.id) {
                        categoryCascaderValues = [cate.id];
                    }
                    cascaderOptions.push({
                        value: cate.id,
                        label: cate.name,
                    })
                }
                this.setState({
                    groups: resGroups,
                    categoryCascaderOptions: cascaderOptions,
                    loadingGroupsData: false,
                    categoryCascaderValues
                })
            })
            .catch(() => {
                this.setState({
                    loadingGroupsData: false
                })
                message.error(`获取分组信息，发生错误`)
            })

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.editType === 'update' && this.state.editType === 'create'){
            this.setState({
                editType: 'create',
                shotInfo: null,
                changedInfo: {},
                editorState: BraftEditor.createEditorState( null)
            })
        }
    }


    getShotDetail = () => {
        shotReq.getMyShotDetail(this.state.shotId)
            .then(res => {
                res = this.fetchShotDetailInfo(res);
                this.setState({
                    shotInfo: res,
                    pageLoading: false,
                    editorState: BraftEditor.createEditorState(res.content || null)
                })
                this.getAllGroups();

            })
            .catch(error => {
                message.error(`获取作品详情发生错误，请刷新页面重试`)
            })
    };

    fetchShotDetailInfo(info) {
        if (info && info.tags && typeof info.tags === 'string') {
            info.tags = info.tags.split(',')
        }

        return info;
    }

    saveShot = () => {
        let {changedInfo, editType, shotInfo} = this.state;
        let info = {...changedInfo};
        if (info.tags) {
            info.tags = info.tags.toString();
        }
        if (editType === 'create') {
            if (!info.title) {
                return message.warning(`作品的标题不能为空`)
            }
            if(!info.preview){
                return  message.warning(`请上传作品的封面图`)
            }
            if(!info.content ){
                return  message.warning(`请编辑你的作品内容`)
            }
            this.setState({
                saving: true
            })
            if(this.state.linkCourse){
                info.courseIds = [this.state.linkCourse.id]
            }
            shotReq.createShot(info)
                .then(res => {
                    let shotInfo = this.fetchShotDetailInfo(res);

                    this.setState({
                        shotId:shotInfo.id,
                        changedInfo: {},
                        shotInfo: shotInfo,
                        editorState: BraftEditor.createEditorState(shotInfo.content || null),
                        editType: 'update',
                        saving: false
                    })
                    this.props.history.replace(`/post/shot/${shotInfo.id}`)

                    message.success(`作品已创建`)

                })
                .catch(error => {
                    this.setState({
                        saving: false
                    })
                    message.error(`作品创建失败，请重试`)
                })
        }

        if (editType === 'update') {

            if (info.hasOwnProperty(`title`) && !info.title) {
                return message.warning(`作品的标题不能为空`)
            }
            if(info.hasOwnProperty(`preview`) && !info.preview){
                return  message.warning(`请上传作品的封面图`)
            }
            if(info.hasOwnProperty(`content`) &&  !info.content ){
                return  message.warning(`请编辑你的作品内容`)
            }
            info.id = shotInfo.id;
            this.setState({
                saving: false
            })
            shotReq.updateShot(info)
                .then(res => {
                    let newInfo = {...this.state.shotInfo,...info}
                    let shotInfo = this.fetchShotDetailInfo(newInfo);

                    this.setState({
                        shotInfo,
                        editorState: BraftEditor.createEditorState(shotInfo.content || null),
                        saving: false
                    }, () => {
                        this.setState({
                            changedInfo: {},
                        })
                    })
                    message.success(`修改已保存`)
                })
                .catch(error => {
                    this.setState({
                        saving: false
                    })
                    message.error(`保存失败，请重试`)
                })
        }
    };

    changePublish = value => {
        this.setState({
            loadingShotStatus: true,
        })
        shotReq.changePublish(this.state.shotInfo.id,value)
            .then(() => {
                let shotInfo = this.state.shotInfo;
                shotInfo.status = value ? 'published' : "unpublished"
                this.setState({
                    loadingShotStatus: false,
                })
                message.success(`操作成功`)
            }).catch(error => {
            this.setState({
                loadingShotStatus: false,
            })
            if(error && error.code === 7701){
                return message.warn('当前作品已被管理员禁用')
            }
            message.error(`操作失败，请重试`)
        });
    }

    deleteShot = () => {
        shotReq.deleteShot(this.state.shotInfo.id)
            .then(()=>{
                this.setState({
                    editType: 'create',
                    shotInfo: null,
                    changedInfo: {},
                    editorState: BraftEditor.createEditorState( null)
                },()=>{
                    this.props.history.replace('/post/shot/create')
                })
                message.success(`已删除`)
            })
            .catch(()=>{
                message.error(`发生错误，删除失败`)
            })
    }

    render() {
        let {editorState, noFound, pageLoading, changedInfo, shotInfo, editType, categoryCascaderOptions, categoryCascaderValues, previewProgress, uploadingPreviewStatus, loadingShotStatus, waitingPassPower,linkCourse} = this.state;
        shotInfo = shotInfo || {}
        if (pageLoading || waitingPassPower) {
            return <div className={'page-shot-post'}>
                <Nav/>
                <img style={{margin: 70}} src={'/icons/svg/oval.svg'} alt={''}/>
            </div>
        }

        if (noFound) {
            return <div className={'page-shot-post'}>
                <Nav/>
                <div className={'no-found'}>
                    <p>作品不存在或已被删除</p>
                    <Button type={'primary'}>查看所有作品</Button>
                </div>
            </div>
        }
        changedInfo = changedInfo || {};
        shotInfo = shotInfo || {};


        return (
            <div className='page-shot-post'>
                <Nav/>

                <div className={'control-bar'}>
                    <div className={`control-bar-content`}>
                        <div className={`flex-l`} hidden={!linkCourse}>
                            <div className={`title`}>将发布至 <Link className={'course-link'} to={`/course/${linkCourse ? linkCourse.id:""}`}>{linkCourse ? linkCourse.title :""}</Link></div>
                        </div>
                        <div className={`flex-r`}>

                            <Button type={'danger'} style={{marginRight: 15}}
                                    hidden={editType !== 'update' || !shotInfo || !shotInfo.id} onClick={() => {
                                Modal.confirm({
                                    title: "你确定要删除该作品吗？",
                                    okText: "删除",
                                    onOk: this.deleteShot
                                })
                            }}>删除</Button>
                            <Switch className={'publish-switch'} style={{marginRight: 15}} checkedChildren="发布" unCheckedChildren="未发布"
                                    checked={shotInfo && shotInfo.status === 'published'}
                                    disabled={!shotInfo || editType === 'create'}
                                    size={'large'}
                                    hidden={editType === 'create'}
                                    loading={loadingShotStatus}
                                    onChange={this.changePublish}
                            />
                            <Button type={'danger'} style={{marginRight: 15}}
                                    hidden={!Object.getOwnPropertyNames(changedInfo).length} onClick={() => {
                                Modal.confirm({
                                    title: "你确定要放弃所有修改记录吗？",
                                    okText: "确定放弃",
                                    onOk: () => {
                                        this.setState({
                                            changedInfo: {}
                                        })
                                    }
                                })
                            }}>放弃所有修改</Button>
                            <Button type={'primary'}
                                    disabled={!changedInfo || !Object.getOwnPropertyNames(changedInfo).length}
                                    onClick={this.saveShot} loading={this.state.saving}>保存</Button>
                        </div>


                    </div>

                </div>
                <div className={`post-content`}>
                    <Row gutter={16}>
                        <Col span={16}>


                            <div className={'form-item'}>
                                <h3>作品内容</h3>
                                <BraftEditor className={'editor'}
                                             controls={editorControls}
                                             value={editorState}
                                             onChange={this.handleEditorChange}
                                             onSave={this.submitContent}
                                             placeholder={''}

                                             contentClassName={"editor-content"}
                                             componentBelowControlBar={<div ref={(ele) => {
                                                 this.editBelowRef = ele;
                                             }}/>}
                                             media={{uploadFn: myUploadFn,accepts:{ image: 'image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg',audio:false,video:false}}}
                                />
                            </div>

                        </Col>
                        <Col span={8}>

                            <div className={'form-item'}>
                                <h3>作品标题</h3>
                                <Input
                                    value={changedInfo.hasOwnProperty(`title`) ? changedInfo.title : shotInfo.title || ""}
                                    className={'input'} onChange={e => {
                                    let changedInfo = this.state.changedInfo;
                                    changedInfo.title = e.currentTarget.value;
                                    if(!changedInfo.title && !this.state.shotInfo){
                                        delete  changedInfo.title;
                                    }
                                    this.setState({
                                        changedInfo
                                    })
                                }}/>
                            </div>


                            <div className={'form-item'}>
                                <h3>分类</h3>
                                <Cascader options={categoryCascaderOptions} value={categoryCascaderValues}
                                          onChange={this.categorySelectorOnChange} placeholder="请指定作品分类"
                                          className={'category-selector'} popupClassName={'shot-post-casader-item'}/>
                            </div>
                            <div className={'form-item'}>
                                <h3>标签</h3>
                                <Select
                                    mode="tags"
                                    size={'large'}
                                    placeholder="作品标签，以逗号或回车分隔"
                                    value={changedInfo.tags || shotInfo.tags || []}
                                    onChange={this.tagsHandleChange}
                                    style={{width: '100%'}}
                                    allowClear={true}
                                    tokenSeparators={['\n', ',', '，']}
                                >

                                </Select>
                            </div>
                            <div className={'form-item'}>
                                <h3>封面图</h3>
                                <Dragger className={'uploader'}
                                         accept={'image/jpeg,image/png,image/jpg,image/gif'}
                                         showUploadList={false}
                                         beforeUpload={this.previewUploaderBeforeUpload}
                                         disabled={this.state.uploading}>
                                    <div className={'uploader-container'}
                                    >
                                        <div className={'tip'} hidden={changedInfo.preview || shotInfo.preview}>

                                            <p className="ant-upload-drag-icon">
                                                <Icon type="inbox"/>
                                            </p>
                                            <p className="ant-upload-text">点击或拖拽到此处上传</p>
                                            <p className="ant-upload-hint">
                                                仅支持单张图片上传
                                            </p>
                                        </div>

                                        <div className={'shot-preview'}
                                             hidden={!changedInfo.preview && !shotInfo.preview}>
                                            <img src={changedInfo.preview || shotInfo.preview || ""} alt={''}/>
                                        </div>
                                        <Progress className={'progress'} hidden={uploadingPreviewStatus === 'init'}
                                                  strokeColor='#3340ff' type="circle"
                                                  percent={previewProgress} width={80}
                                                  status={uploadingPreviewStatus === 'error' ? "exception" : "normal"}/>
                                    </div>


                                </Dragger>
                            </div>
                            <div className={'form-item'}>
                                <Checkbox className={`ban-comment`} checked={changedInfo.hasOwnProperty(`enableComment`) ? !changedInfo.enableComment : (shotInfo && shotInfo.hasOwnProperty(`enableComment`) ? !shotInfo.enableComment:false)} onChange={e=>{
                                    let changedInfo = this.state.changedInfo;
                                    changedInfo.enableComment = !e.target.checked;
                                    if(!changedInfo.enableComment && !this.state.shotInfo){
                                        delete  changedInfo.enableComment;
                                    }
                                    this.setState({
                                        changedInfo
                                    })
                                }}>禁止评论</Checkbox>
                            </div>


                        </Col>

                    </Row>

                </div>


            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        loginUserInfo: state.account.loginUserInfo,
        isLogin: state.account.isLogin
    }
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ShotPost);
