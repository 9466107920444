import React from "react";
import ListEmpty from "../../components/listEmpty/ListEmpty";
import Layout from "../../layouts/layout/Layout";
import { Radio, Icon, Row, Col, Menu, Select, Dropdown, Button, Drawer } from "antd";
import IconFont from "../../components/iconfont/icon-font";
import OvalLoading from "../../components/OvalLoading/OvalLoading";
import QCourseList from "../../components/allCourseList/AllCourseList";
import courseReq from "../../request/courseReq";
import indexReq from "../../request/indexReq";
import "./allCourseList.scss";
import ListFooter from "../listFooter/ListFooter";
import storage from "../../storage/storage";
import utilFunc from "../../utils/util";
import accountReq from "../../request/accountReq";
import dateformat from 'dateformat'
import { connect } from 'react-redux'
const { SubMenu } = Menu;

const PerPage = 30;
const loadingStatus = {
    init: "init",
    firstLoading: "first-loading",
    loadingMore: "loading-more",
    firstError: "first-error",
    moreError: "more-error"
};

const PriceRanges = [{
    range: [null, null],
    name: "不限",
}, {
    range: [100, 9999],
    name: "￥1-99",
}, {
    range: [10000, 49999],
    name: "￥100-499",
}, {
    range: [50000, 99999],
    name: "￥500-999",
}, {
    range: [100000, null],
    name: "￥1000以上",
}]

const STORAGE_CACHED_KEY_AllCourseList_PAGE_STATE = "STORAGE_CACHED_KEY_AllCourseList_PAGE_STATE"

class AllCourseList extends React.Component {
    constructor() {
        super();
        this.carouselRef = React.createRef();
    }

    state = {
        selectCategoryIndexPath: [],
        groups: [],
        getGroupFail: false,
        loadingCourseListStatus: loadingStatus.init,
        courseListNoMore: false,
        courses: [],
        courseTotalCount: 0,
        selectedCategoryId: 0,

        searchKeyword: null,
        littleBanners: [],
        currentBannerIndex: 0,
        searchInputOnFocus: false,
        apps: [],
        imageAveColor: {},
        teachers: [],
        selectCourseType: "all",
        selectTeacher: null,
        selectApp: null,
        selectPriceIndex: 0,
        freeMode: "all",
        selectGroup: 0,
        sortTime: "",
        sortPrice: "",
        selectLevel: "all",
        selectStartAt: 0,
        drawerVisible: false,
        folder: false,
        openKeys: [],

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            windowSize: nextProps.windowSize
        };
    }
    rootSubmenuKeys = ['11', '12', '13','14','17'];

    showDrawer = () => {
        if (window.innerWidth <= 414) {
            this.setState({
                drawerVisible: true,
            });
        } else {
            this.setState({
                folder: !this.state.folder
            });
        }
    };

    onClose = () => {
        this.setState({
            drawerVisible: false,
        });
    };

    componentDidMount() {


        utilFunc.setDocumentInfo({
            title: "课程 | 优阁网",
            description: "课程",
            keywords: `优阁课程`
        })
        let cachedState = storage.getCached(STORAGE_CACHED_KEY_AllCourseList_PAGE_STATE);
        if (cachedState) {
            this.setState({
                ...cachedState
            })
        } else {
            this.getGroups();
            this.getCourses();
            this.getApps()
            this.getTeachers()
        }
        document.onkeydown = e => {
            if (
                e &&
                e.keyCode === 13 &&
                this.state.searchInputOnFocus &&
                this.lastSearchValue !== this.state.searchKeyword
            ) {
                this.lastSearchValue = this.state.searchKeyword;
                this.courseListPage = -1;
                this.getCourses();
            }
        };
    }

    componentWillUnmount() {
        storage.setCached(STORAGE_CACHED_KEY_AllCourseList_PAGE_STATE, this.state)
    }

    getTeachers() {
        accountReq.getTeacherList()
            .then(res => {
                this.setState({
                    teachers: res
                })
            })
            .catch(error => {

            })
    }

    getGroups() {
        courseReq
            .getGroupAndCategory()
            .then(res => {
                this.setState({
                    groups: res,
                    getGroupFail: false
                });
            })
            .catch(error => {
                this.setState({
                    getGroupFail: true
                });
            });
    }

    courseListPage = -1;

    getCourses = () => {
        let that = this;
        if (this.state.loadingCourseListStatus === loadingStatus.firstLoading || this.state.loadingCourseListStatus === loadingStatus.loadingMore) {
            return;
        }
        let condition = (function () {
            let body = {
                perPage: PerPage,
                page: that.courseListPage + 1
            };
            if (that.state.selectCourseType !== 'all') {
                body.courseType = that.state.selectCourseType
            }
            if (that.state.selectApp > 0) {
                body.softWareId = that.state.selectApp
            }
            if (that.state.selectTeacher > 0) {
                body.teacherId = that.state.selectTeacher
            }
            if (that.state.freeMode && that.state.freeMode !== "all") {
                body.freeMode = that.state.freeMode
            }
            if (that.state.selectPriceIndex) {
                let priceRange = PriceRanges[that.state.selectPriceIndex || 0].range
                if (priceRange && priceRange.length === 2) {
                    body.startPrice = priceRange[0] || 1;
                    body.endPrice = priceRange[1] || 99999999999
                    delete body.freeMode
                }

            }

            if(that.state.selectedCategoryId){
                body.courseCategoryId = that.state.selectedCategoryId;
            }else if (that.state.selectGroup) {
                body.groupId = that.state.selectGroup
            }

            if (that.state.searchKeyword) {
                body.keyword = that.state.searchKeyword
            }

            if (that.state.sortPrice) {
                body.sort = {
                    price: that.state.sortPrice
                }
            } else if (that.state.sortTime) {
                body.sort = {
                    createdTime: that.state.sortTime
                }
            }

            if (that.state.selectLevel && that.state.selectLevel !== 'all') {
                body.level = that.state.selectLevel;
            }

            if (that.state.selectCourseType !== 'online-course' && that.state.selectStartAt) {
                let now = new Date();
                switch (that.state.selectStartAt) {
                    case 1:
                        body.startAt = dateformat(now, 'yyyy-mm-dd 00:00:00');
                        now.setDate(now.getDate() + 7);
                        body.endAt = dateformat(now, 'yyyy-mm-dd 23:59:59');
                        break;
                    case 2:
                        body.startAt = dateformat(now, 'yyyy-mm-dd 00:00:00');
                        now.setDate(now.getDate() + 30);
                        body.endAt = dateformat(now, 'yyyy-mm-dd 23:59:59');
                        break;
                    case 3:
                        now.setDate(now.getDate() + 31);
                        body.startAt = dateformat(now, 'yyyy-mm-dd 00:00:00');
                        now.setDate(now.getDate() + 9999);
                        body.endAt = dateformat(now, 'yyyy-mm-dd 23:59:59');
                        break;
                    default:
                        break;

                }
            }


            return body;
        })();
        let dalayLoading = null
        if (condition.page < 1) {
            dalayLoading = setTimeout(() => {
                this.setState({
                    loadingCourseListStatus: loadingStatus.firstLoading,
                    courses: []
                });
            }, 100);

        } else {
            this.setState({
                loadingCourseListStatus: loadingStatus.loadingMore
            });
        }
        // condition.courseType = "live-course";
        courseReq
            .getCourseList(condition)
            .then(res => {
                if (dalayLoading) {
                    clearTimeout(dalayLoading)
                }
                let moreCourses = res.rows;
                let count = res.count;
                let noMore = false;
                if (moreCourses.length < condition.perPage) {
                    noMore = true;
                }
                let courses = this.state.courses;
                if (condition.page < 1) {
                    courses = [];
                }
                this.courseListPage += 1;
                if (res && res.rows && res.rows.length) {
                    res.rows.forEach(item => {
                        if (item.preview) {
                            indexReq.getQiniuImageAveColor(item.preview).then(res => {
                                let colors = this.state.imageAveColor;
                                colors[item.preview] = res;
                                this.setState({
                                    imageAveColor: colors
                                });
                            });
                        }

                    });
                }
                this.setState({
                    loadingCourseListStatus: loadingStatus.init,
                    courseListNoMore: noMore,
                    courses: courses.concat(moreCourses),
                    courseTotalCount: count
                });
            })
            .catch(error => {
                if (condition.page < 1) {
                    this.setState({
                        loadingCourseListStatus: loadingStatus.firstError
                    });
                } else {
                    this.setState({
                        loadingCourseListStatus: loadingStatus.moreError
                    });
                }
            });
    }

    categoryOnSelected = value => {
        this.setState(
            {
                selectCategoryIndexPath: value
            },
            () => {
                this.courseListPage = -1;
                this.getCourses();
            }
        );
    };

    onChangeCategory = categoryId => {
        this.setState(
            {
                categoryId
            },
            () => {
                this.courseListPage = -1;
                this.getCourses();
            }
        );
    };

    switchSort = e => {

    }

    getApps = () => {
        courseReq.getAppList()
            .then((res) => {
                this.setState({
                    apps: res || []
                })
            })
    }

    onCourseTypeOnChange = e => {
        this.courseListPage = -1
        let { selectStartAt } = this.state;
        if (e.target.value === 'online-course') {
            selectStartAt = ""
        }
        this.setState({
            selectCourseType: e.target.value,
            selectStartAt
        }, () => {
            this.getCourses()
        })
    }

    onChangeApp = e => {
        this.courseListPage = -1
        this.setState({
            selectApp: e.target.value,
        }, () => {
            this.getCourses()
        })
    }

    onChangeTeacher = e => {
        this.courseListPage = -1
        this.setState({
            selectTeacher: e.target.value,

        }, () => {
            this.getCourses()
        })
    }

    onChangePriceSelect = e => {
        this.courseListPage = -1

        this.setState({
            selectPriceIndex: e.target.value,
            freeMode: "charge"
        }, () => {
            this.getCourses()
        })
    }

    onChangeFreeMode = e => {
        this.courseListPage = -1
        this.setState({
            freeMode: e.target.value,
            selectPriceIndex: 0
        }, () => {
            this.getCourses()
        })
    }

    onChangeGroup = e => {
        this.courseListPage = -1
        this.setState({
            selectGroup: e.target.value,
            selectedCategoryId:null,
        }, () => {
            this.getCourses()
        })
    }

    onChangeTimeSort = e => {
        this.courseListPage = -1
        this.setState({
            sortTime: this.state.sortTime === "ASC" ? "DESC" : "ASC",
            sortPrice: ""
        }, () => {
            this.getCourses()
        })
    }

    onChangePriceSort = e => {
        this.courseListPage = -1
        this.setState({
            sortPrice: this.state.sortPrice === "ASC" ? "DESC" : "ASC",
            sortTime: ""
        }, () => {
            this.getCourses()
        })
    }

    onChangeLevel = e => {
        this.courseListPage = -1
        this.setState({
            selectLevel: e.target.value
        }, () => {
            this.getCourses()
        })
    }
    onChangeStartAt = e => {
        this.courseListPage = -1
        this.setState({
            selectStartAt: e.target.value
        }, () => {
            this.getCourses()
        })
    }
    onClickMenu = (e) => {
       let key = e.key;
       console.log(`e`,e)
        if(key){
            key = parseInt( key.replace("sub-",""))
        }

        this.courseListPage = -1
        this.setState({
            selectedCategoryId: key,
            selectGroup:null,
        }, () => {
            this.getCourses()
        })
    }

    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.setState({ openKeys });
        } else {
          this.setState({
            openKeys: latestOpenKey ? [latestOpenKey] : [],
          });
        }
      };

    render() {
        let {
            courses,
            loadingCourseListStatus,
            courseListNoMore,
            apps,
            teachers,
            selectCourseType,
            selectTeacher,
            selectApp,
            selectPriceIndex,
            freeMode,
            selectGroup,
            sortPrice,
            sortTime,
            selectLevel,
            selectStartAt,
            windowSize
        } = this.state;

        let filterForm = <div className="list-filter">
            {/*<div className="filter-row">*/}
            {/*    <div className="filter-label">*/}
            {/*        按分类*/}
            {/*        </div>*/}
            {/*    <Radio.Group className="filter-option" value={selectGroup} onChange={this.onChangeGroup}>*/}
            {/*        <Radio.Button value={0} className="filter-item">全部</Radio.Button>*/}
            {/*        {*/}
            {/*            this.state.groups.map((item, index) => {*/}
            {/*                return <Radio.Button key={index} value={item.id}*/}
            {/*                    className="filter-item">{item.name}</Radio.Button>*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </Radio.Group>*/}
            {/*</div>*/}
            <div className="filter-row">
                <div className="filter-label">
                    按类型
                    </div>
                <Radio.Group className="filter-option" value={selectCourseType} onChange={this.onCourseTypeOnChange}>
                    <Radio.Button value={"all"} className="filter-item">全部</Radio.Button>
                    <Radio.Button value={"online-course"} className="filter-item">录播</Radio.Button>
                    <Radio.Button value={"live-course"} className="filter-item">直播</Radio.Button>
                </Radio.Group>
            </div>
            <div className="filter-row">
                <div className="filter-label">
                    是否免费
                    </div>
                <Radio.Group className="filter-option" value={freeMode} onChange={this.onChangeFreeMode}>
                    <Radio.Button value={"all"} className="filter-item">全部</Radio.Button>
                    <Radio.Button value={"free"} className="filter-item">免费</Radio.Button>
                    <Radio.Button value={"charge"} className="filter-item">收费</Radio.Button>
                </Radio.Group>
            </div>
            <div className="filter-row">
                <div className="filter-label">
                    按价格
                    </div>
                <Radio.Group className="filter-option" value={selectPriceIndex ? selectPriceIndex + "" : "0"}
                    onChange={this.onChangePriceSelect}>
                    {
                        PriceRanges.map((item, index) => {
                            return <Radio.Button key={index} value={index + ""}
                                className="filter-item">{item.name}</Radio.Button>
                        })
                    }
                </Radio.Group>
            </div>
            <div className="filter-row" hidden={selectCourseType === 'online-course'}>
                <div className="filter-label">
                    开课时间
                    </div>
                <Radio.Group className="filter-option" value={selectStartAt || 0} onChange={this.onChangeStartAt}>
                    <Radio.Button value={0} className="filter-item">全部</Radio.Button>
                    <Radio.Button value={1} className="filter-item">一周内</Radio.Button>
                    <Radio.Button value={2} className="filter-item">一个月内</Radio.Button>
                    <Radio.Button value={3} className="filter-item">一个月以上</Radio.Button>
                </Radio.Group>
            </div>
            <div className="filter-row">
                <div className="filter-label">
                    按老师
                    </div>
                <Radio.Group className="filter-option teacher" value={selectTeacher || 0}
                    onChange={this.onChangeTeacher}>
                    <Radio.Button value={0} className="filter-item">全部</Radio.Button>
                    {
                        teachers.map((item, index) => {
                            return <Radio.Button value={item.id} key={item.id}
                                className="filter-item">{item.nickname || item.username || ""}</Radio.Button>
                        })
                    }
                </Radio.Group>
            </div>
            <div className="filter-row">
                <div className="filter-label">
                    按软件
                    </div>
                <Radio.Group className="filter-option software" value={selectApp || 0} onChange={this.onChangeApp}>
                    <Radio.Button value={0} className="filter-item">全部</Radio.Button>
                    {
                        apps.map((item, index) => {
                            return <Radio.Button value={item.id} className="filter-item" key={item.id}>
                                <img src={item.iconUrl}
                                    alt="" />
                            </Radio.Button>
                        })
                    }
                </Radio.Group>
            </div>
            <div className="filter-row">
                <div className="filter-label">
                    按等级
                    </div>
                <Radio.Group className="filter-option" value={selectLevel || "all"} onChange={this.onChangeLevel}>
                    <Radio.Button value={"all"} className="filter-item">全部</Radio.Button>
                    <Radio.Button value={"primary"} className="filter-item">初级</Radio.Button>
                    <Radio.Button value={"middle"} className="filter-item">中级</Radio.Button>
                    <Radio.Button value={"high"} className="filter-item">高级</Radio.Button>
                </Radio.Group>
            </div>
        </div>
            ;

        if (windowSize.width <= 414) {
            filterForm = <Drawer placement="left"
                closable={false}
                onClose={this.onClose}
                visible={this.state.drawerVisible}
                height={"auto"}>
                {filterForm}
            </Drawer>
        }


        return (
            <Layout rootClassName="all-course">
                {/*课程列表分类和搜索*/}

                <div className={"list-body"}>
                    <Row gutter={[{ xs: 8, sm: 16 }, { xs: 8, sm: 16 }]}>
                        <Col xs={0} md={5}>
                            {/* {filterForm} */}
                            <Menu onSelect={this.onClickMenu} mode="inline" selectedKeys={["sub-" + this.state.selectedCategoryId]}>
                                <Menu.Item key="sub-0">全部</Menu.Item>
                                {
                                    this.state.groups.map((item, index) => {
                                        return <SubMenu className={
                                            this.state.selectGroup === item.id ?   `group-selected ant-menu-submenu-selected`:""
                                        } onTitleClick={()=>{
                                            this.onChangeGroup({
                                                target:{
                                                    value:item.id
                                                }
                                            })
                                        }} key={item.id} title={item.name} >
                                            {
                                                item.categories.map((subitem, subindex) => {
                                                    return <Menu.Item key={"sub-" + subitem.id}>{subitem.name}</Menu.Item>
                                                })
                                            }
                                        </SubMenu>
                                    })
                                }
                            </Menu>
                        </Col>
                        <Col xs={24} md={19}>
                            <div className={"category-search"}>
                                <Row type="flex" justify="space-between" gutter={16} align="middle">
                                    <Col xs={24} md={12} lg={10} xl={10} className="sort-panel">
                                        <div className="filter-btn" onClick={this.showDrawer}>
                                            筛选
                                            <Icon className="filter-icon" type="filter" />
                                        </div>

                                        <Button className={`sort-btn ${sortTime ? "sort-btn-active" : ""}`}
                                            onClick={this.onChangeTimeSort}>
                                            按时间
                                            <div className="sort-group" style={{ fontSize: "11px" }}>
                                                <Icon type={`caret-up`}
                                                    className={`${sortTime === 'ASC' ? "icon-active" : "arrow-icon"}`} />
                                                <Icon type={`caret-down`}
                                                    className={`${sortTime === 'DESC' ? "icon-active" : "arrow-icon"}`} />
                                            </div>
                                        </Button>
                                        <Button className={`sort-btn ${sortPrice ? "sort-btn-active" : ""}`}
                                            onClick={this.onChangePriceSort}>
                                            按价格
                                            <div className="sort-group" style={{ fontSize: "11px" }}>
                                                <Icon type={`caret-up`}
                                                    className={`${sortPrice === 'ASC' ? "icon-active" : "arrow-icon"}`} />
                                                <Icon type={`caret-down`}
                                                    className={`${sortPrice === 'DESC' ? "icon-active" : "arrow-icon"}`} />
                                            </div>
                                        </Button>

                                    </Col>

                                    <Col xs={24} md={4} lg={4} xl={8} className={`free-option`}>

                                        <Radio.Group className="filter-option" value={freeMode}
                                            onChange={this.onChangeFreeMode}>
                                            <Radio.Button value={"all"} className="filter-item">全部</Radio.Button>
                                            <Radio.Button value={"free"} className="filter-item">免费</Radio.Button>
                                            <Radio.Button value={"charge"} className="filter-item">收费</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                    <Col className={`search-col`} xs={24} md={8} lg={10} xl={6}>
                                        <div className={`search-bar`}>
                                            <input
                                                type={"search"}
                                                placeholder={
                                                    this.state.searchInputOnFocus
                                                        ? "输入课程名称或老师昵称"
                                                        : "搜索"
                                                }
                                                onFocus={() => {
                                                    this.lastSearchValue = this.state.searchKeyword + "";
                                                    this.setState({
                                                        searchInputOnFocus: true
                                                    });
                                                }}
                                                onChange={e => {
                                                    this.setState({
                                                        searchKeyword: e.currentTarget.value
                                                    });
                                                }}
                                                value={this.state.searchKeyword || ""}
                                            />
                                            <IconFont
                                                className={`search-icon ${this.state.searchKeyword ? "searching" : ""
                                                    }`}
                                                type={"iconsearch-icon"}
                                                onClick={() => {
                                                    if (this.lastSearchValue !== this.state.searchKeyword) {
                                                        this.lastSearchValue = this.state.searchKeyword;
                                                        this.courseListPage = -1;
                                                        this.getCourses();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            {/*<OvalLoading*/}
                            {/*    className={"loading"}*/}
                            {/*    show={loadingCourseListStatus === loadingStatus.firstLoading}*/}
                            {/*/>*/}

                            {(this.state.drawerVisible || this.state.folder) && filterForm}
                            <QCourseList showSkeleton={loadingCourseListStatus === loadingStatus.firstLoading}
                                courses={courses} colors={this.state.imageAveColor} />
                            <ListEmpty
                                show={
                                    loadingCourseListStatus === loadingStatus.init &&
                                    !courses.length
                                }
                            />
                            <ListFooter
                                show={[loadingStatus.loadingMore, loadingStatus.moreError, loadingStatus.init].indexOf(loadingCourseListStatus) >= 0 && courses.length}
                                status={courseListNoMore ? 'no-more' : loadingCourseListStatus === loadingStatus.loadingMore ? 'loading-more' : "loading-error"}
                                onLoadingMore={this.getCourses} />
                        </Col>
                    </Row>

                </div>

            </Layout>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    windowSize: state.system.windowSize
});


export default connect(mapStateToProps, {})(AllCourseList);
