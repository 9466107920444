import React from 'react';

import './workComment.scss'

import IconFont from "../iconfont/icon-font";

import { Avatar, Divider } from "antd";

import CommentTextarea from "../comment-textarea/CommentTextarea"
import WorkCommentChild from "./work-comment-child/WorkCommentChild";
import { parseTimelineDate } from '../../utils/dataformat'
import { connect } from 'react-redux'


class WorkComment extends React.Component {
    constructor(props) {
        super(props);
        Object.assign(this.state, props);
        this.commentTextAreaRef = React.createRef()
    }

    state = {
        isOpen: false,
        data: {},
        isLike: false,
        likeNum: 0
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            isChild: nextProps.isChild,
            compKey: nextProps.compKey,
            className: nextProps.className,
            style: nextProps.style,
            data: nextProps.data,
            isLike: nextProps.data ? !!nextProps.data.isLike : false,
            likeNum: nextProps.data ? nextProps.data.likeNum : 0,
            childComments: nextProps.childComments,
            rowIndex: nextProps.rowIndex,
            showDelete: false,
            loginUserId: nextProps.loginUserId,
            isLogin: nextProps.isLogin

        };
    }

    openReplay = () => {
        if (!this.state.isLogin) {
            return window.appLogin()
        }
        let isOpen = this.state.isOpen;
        if (!isOpen) {
            if (this.props.openReplayInput) {
                this.props.openReplayInput(this);
            }
        }
        this.setState({
            isOpen: !isOpen
        })



    }
    childReplayInputOpen = (target) => {
        if (this.props.openReplayInput) {
            this.props.openReplayInput(target);
        }
    }

    onSelfPostReplay = (value) => {

        if (this.props.onSelfPostReplay) {
            this.props.onSelfPostReplay(value, this.state.data, this.state.rowIndex, this);
        }
    }

    onPostReplay = (value, comment, target) => {

        if (this.props.onPostReplay) {
            this.props.onPostReplay(value, comment, this.state.rowIndex, target);
        }
    }

    clearContent() {
        if (this.commentTextAreaRef && this.commentTextAreaRef.current) {
            this.commentTextAreaRef.current.clearContent();
        }


    }

    showCommentTextarea(isShow) {
        this.setState({
            isOpen: isShow
        })
    }

    handlerDelete = () => {
        if (this.props.handlerDelete) {
            this.props.handlerDelete(this.state.data.id, this.state.rowIndex);
        }
    }
    handlerChildDelete = (commentId, rowIndex) => {
        if (this.props.handlerChildDelete) {
            this.props.handlerChildDelete(commentId, rowIndex, this.state.rowIndex);
        }
    }

    handlerLike = () => {
        if (!this.state.isLogin) {
            return window.appLogin()
        }
        if (this.props.handlerLike) {
            this.props.handlerLike(this.state.data.id, this.state.rowIndex);
        }
    }
    handlerChildLike = (commentId, rowIndex) => {
        if (this.props.handlerChildLike) {
            this.props.handlerChildLike(commentId, rowIndex, this.state.rowIndex);
        }
    }

    handlerTopComment = () => {
        if (this.props.handlerTopComment) {
            this.props.handlerTopComment(this.state.data, this.state.rowIndex);
        }
    }

    render() {

        let { className, style, compKey, data, childList, showDelete, loginUserId, isLike, likeNum } = this.state;

        let createTime = data && data.createdAt ? parseTimelineDate(data.createdAt) : "";
        if (this.props.publishTime) {
            if (typeof this.props.publishTime === "function") {
                createTime = this.props.publishTime(createTime)
            } else {
                createTime = this.props.publishTime;
            }
        }

        let childComments = [];
        if (data.replayTargetInfo && data.replayTargetInfo.length) {
            // 二级评论
            childComments = data.replayTargetInfo.map((child, j) => {
                return <WorkCommentChild data={child} key={j} rowIndex={j} onPostReplay={this.onPostReplay} openReplayInput={this.childReplayInputOpen} loginUserId={loginUserId} handlerDelete={this.handlerChildDelete} handlerLike={this.handlerChildLike} />
            })
        }
        let commentArea = null;
        if (this.state.isOpen) {
            commentArea = <CommentTextarea ref={this.commentTextAreaRef} show={this.state.isOpen} okText={'回复'} onSubmit={this.onSelfPostReplay} placeholder={`回复 ${data && data.userInfo && data.userInfo.nickname ? data.userInfo.nickname : ""}`} />
        }

        return (
            <div className={`bks-work-comment ${className || ""}`} style={style || {}} key={compKey}>
                <Avatar className={'avatar'} src={data && data.userInfo ? data.userInfo.avatar : ""} />
                <div className={'flex-r'}>
                    <label className={'nickname'}>
                        {data && data.userInfo ? data.userInfo.nickname : ""}
                    </label>
                    <div className={'com-cnt'}>
                        {/* {data && data.isTop ? "取消置顶":"置顶"} */}
                        <div className="top" hidden={data && data.isTop ? false : true}>
                            置顶
                        </div>
                        <div>
                            {data ? data.content : ""}
                        </div>
                    </div>
                    <div className={'bottom-panel'}>
                        <label className={'publish-time'}>{createTime}</label>
                        <label className={'like'}>
                            <IconFont type="iconlike2" className="iconlike2" style={{ color: this.props.data ? this.props.data.isLike ? '#ff1000' : "" : "" }} onClick={this.handlerLike} />
                            <span>{this.props.data ? this.props.data.likeNum || 0 : 0}</span>
                        </label>
                        <label className={'reply'} onClick={this.openReplay}>回复</label>

                        <label className={'reply'} style={{ marginLeft: 15 }} hidden={!data || !data.userInfo || !data.userInfo.id || !loginUserId || loginUserId != data.userInfo.id} onClick={this.handlerDelete}>删除</label>
                        <Divider type="vertical" hidden={!this.state.canTopComment} />
                        <label className={'reply'} style={{ marginLeft: 15 }} hidden={!this.state.showTopComment} onClick={this.handlerTopComment} >{data && data.isTop ? "取消置顶" : "置顶"}</label>
                    </div>
                    {commentArea}
                    <div className={`child-list`}>
                        {childComments}
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    isLogin: state.account.isLogin,
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(WorkComment);